import gql from "graphql-tag";

export const GET_MY_DOCUMENTS_LIST = gql`
  query listMyDocuments($user: String!) {
    listMyDocuments(user: $user) {
      _id
      filename
      size
      slug
      description
      user
      userView
      createdAt
      createdBy
    }
  }
`;
