import gql from "graphql-tag";

export const GET_OPEN_REQUEST_LIST_WITHOUT_EXAMINER = gql`
  query getOpenRequestsListWithoutExaminer($startDate: Date!, $endDate: Date!) {
    getOpenRequestsListWithoutExaminer(startDate: $startDate, endDate: $endDate) {
      employeeName
      contractCode
      orgUnit
      recordCount
      startTime
      endTime
    }
  }
`;
