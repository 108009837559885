import React, { useLayoutEffect, createRef, useEffect, useState } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import useContract from "hooks/use-myhr-contract";
import useEmployee from "hooks/use-myhr-employee";
import StickyPersonalDataHeader from "my-hr/components/my-hr-sticky-personal-data-header";
import useClientState from "hooks/use-oc-client-state";
import UserContractsCard from "my-hr/components/my-hr-user-contract-card";
import OcCard from "oc/components/oc-card";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useUserLeaveRequest from "hooks/use-user-my-leave-requests";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { userLeaveRequests } from "my-leave/configs/my-leave-table-defs";
import Table from "oc/components/oc-data-table";
import getSortedList from "oc/utils/get-sorted-list";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { useHistory, useParams } from "react-router-dom";
import AllLeavesWithCoverage from "my-leave/components/my-leave-all-leaves-with-coverage-card";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentDateState, roleFiltersState } from "states";
import { scrollTriggerState } from "states";
import moment from "moment";
import useLeaveEvents from "hooks/use-my-leave-events";
import useStyles from "my-leave/components/my-leave-filter-bar/my-leave-filter-bar.style";
import { InlineIcon } from "@iconify/react";
import checkAll from "@iconify/icons-mdi/check-all";
import useOcSnackbar from "hooks/use-oc-snackbar";
import { useMutation } from "@apollo/react-hooks";
import { APPROVE_ALL_MY_LEAVE_REQUESTS } from "graphql/mutations";
import useProperty from "hooks/use-oc-property";

export default function UserRequests() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  let { contract, product, role } = useParams();
  const { data: contractData } = useContract({ contract });
  let employeeId = contractData?.employee?.code;
  const classes = useStyles();

  const [loadingState, setLoadingState] = useState(false);

  const { setOpenMessage } = useOcSnackbar();

  const { data: employeeData } = useEmployee({ employeeId });

  const roleFilters = useRecoilValue(
    roleFiltersState(contract + product + role)
  );

  const [currentDate, setCurrentDate] = useRecoilState(currentDateState);

  const pageRef = createRef();
  const [scrollTrigger, setScrollTrigger] = useRecoilState(scrollTriggerState);

  function handleScroll() {
    let scrollTriggerPosition = 100;
    let scrollTop = document?.scrollingElement?.scrollTop;
    if (scrollTop >= scrollTriggerPosition) {
      setScrollTrigger("show");
    } else if (scrollTop < scrollTriggerPosition) {
      setScrollTrigger("hide");
    }
  }

  const { data: approveAllLeaveRequestsEnabledData } = useProperty({
    resource: "myLeave",
    key: "approveAllLeaveRequestsEnabled",
  });

  const requestsEnabled = approveAllLeaveRequestsEnabledData === "false" ? false : true;

  const [pageWidth, setPageWidth] = useClientState("pageWidth", 0);

  useLayoutEffect(() => {
    let width = pageRef?.current?.clientWidth;
    if (width && width !== pageWidth) {
      setPageWidth(width);
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line
  }, []);

  let tableId = "userLeavesTableData";
  initialTableData.sortField = "period";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const { data, loading } = useUserLeaveRequest({
    contract,
  });

  let result = _.cloneDeep(data);
  let newRequestedLeaveDays = 0;

  result.map((item) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    let startDate = new Date(item.startDate).toLocaleDateString(
      i18n.language,
      options
    );
    let endDate = new Date(item.endDate).toLocaleDateString(
      i18n.language,
      options
    );

    if (item.type === "leave" && item.state === "requested") {
      newRequestedLeaveDays = newRequestedLeaveDays + item.workDays;
    }
    let createdAt = new Date(item.createdAt).toLocaleString(i18n.language);
    item.createdAt = createdAt;
    if (item._id.startsWith("jd-")) {
      item.createdAt = t(`RECORDED_IN_JDOLBER`);
    }
    item.period = `${startDate} - ${endDate}`;
    item.type = t(item.type);
    return item;
  });

  result.sort(
    getSortedList(
      tableData?.sortDirection === "asc"
        ? tableData?.sortField
        : `-${tableData?.sortField}`
    )
  );

  function handleSelectRow(o) {
    history.push(
      `/my-leave/user-request/${product}/${role}/${o.contract}/${o._id}`
    );
  }

  const [onlyRequested, setOnlyRequested] = useClientState(
    "onlyRequestedLeavesByUser",
    true
  );

  if (onlyRequested) {
    const endDate = moment().subtract(1, "month").startOf("month").toDate();
    result = result.filter(function (item) {
      if (
        item.state === "requested" &&
        new Date(item.endDate) > new Date(endDate)
      ) {
        return true;
      }
      return false;
    });
  }

  let list = {
    rows: result,
    total: result.length,
    page: tableData.page,
    pageSize: tableData.pageSize,
  };

  function handleCurrentDateChange(date) {
    setCurrentDate(date);
  }

  function handleEventSelected(event) {
    let { _id, contract } = event;
    let url = `/my-leave/user-request/${product}/${role}/${contract}/${_id}`;
    history.push(url);
  }

  const [approveAllRequests] = useMutation(APPROVE_ALL_MY_LEAVE_REQUESTS, {
    onCompleted: (data) => {
      const results = data?.approveAllMyLeaveRequests;
      let isOK = false;
      results.forEach((result) => {
        if (result.result) {
          isOK = true;
        } else {
          setOpenMessage({
            type: "error",
            message: t("MY_TIME_ALL_APPROVE_FAILED"),
          });
        }
      });
      if (isOK) {
        setOpenMessage({
          type: "success",
          message: t("MY_TIME_ALL_APPROVE_SUCEEDED"),
        });
      }
      setLoadingState(false);
    },
    onError: () => {
      setOpenMessage({
        type: "error",
        message: t("MY_TIME_APPROVE_FAILED"),
      });
    },
  });

  const handleApproveAllRequests = () => {
    const params = { contractCode: contract };
    setLoadingState(true);
    approveAllRequests({ variables: params });
  };

  const viewedByAdmin = role === "viewer" ? true : false;

  const {
    loadEvents,
    data: leavesData,
    loading: leaveEventsLoading,
    eventsByDays,
  } = useLeaveEvents({
    roleFilters: roleFilters,
    currentDate: moment(currentDate).toDate(),
    viewedByAdmin,
    contract: contract,
  });

  useEffect(() => {
    setCurrentDate(new Date());
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  return (
    <OcPageTransition>
      <Box ref={pageRef}>
        <StickyPersonalDataHeader
          pageWidth={pageWidth}
          employee={employeeData}
          contract={contractData}
          trigger={scrollTrigger === "show" ? true : false}
        />
        <UserContractsCard employee={employeeData} contract={contractData} />
        <Box padding="16px">
          <OcCard label={t("LEAVE_AND_ABSENCE_REQUESTS")} loading={loadingState}>
            <Box>
              <Box className={classes.root}>
                <Box
                  className={
                    viewedByAdmin && !requestsEnabled
                      ? classes.leftContainerExaminerView
                      : classes.leftContainer
                  }
                />
                <ButtonGroup>
                  <Button
                    id="my-leave-filter-user-requests-page-to-be-assessed-button"
                    variant={onlyRequested ? "contained" : "outlined"}
                    color={onlyRequested ? "primary" : ""}
                    disableElevation
                    onClick={() => setOnlyRequested(true)}
                  >
                    {t("TO_BE_ASSESSED")}
                  </Button>
                  <Button
                    id="my-leave-filter-user-requests-page-all-button"
                    variant={!onlyRequested ? "contained" : "outlined"}
                    color={!onlyRequested ? "primary" : ""}
                    disableElevation
                    onClick={() => setOnlyRequested(false)}
                  >
                    {t("ALL")}
                  </Button>
                </ButtonGroup>
                <Box className={classes.rightContainer}>
                  {!viewedByAdmin && requestsEnabled ? (
                    <Button
                      id="all-approve"
                      variant="contained"
                      color="default"
                      startIcon={<InlineIcon icon={checkAll} />}
                      onClick={handleApproveAllRequests}
                      className={classes.allApprove}
                      disableElevation
                    // disabled={loading || requestCounter?.length === 0}
                    >
                      {t("ALL_APPROVE")}
                    </Button>
                  ) : (
                    <Box>{(viewedByAdmin || !requestsEnabled) && <Box width="180px" />}</Box>
                  )}
                </Box>
              </Box>
              <Table
                data={list}
                loading={loading && list?.rows?.length === 0 ? true : false}
                tableDef={userLeaveRequests}
                tableData={tableData}
                setTableData={setTableData}
                pagination={true}
                onSelectRow={handleSelectRow}
                localPaging={true}
              />
            </Box>
          </OcCard>
          <Box height="16px" />
          <AllLeavesWithCoverage
            cardTitle={t("LEAVES_AND_REMOTE_WORKS")}
            currentDate={currentDate}
            onCurrentDateChange={handleCurrentDateChange}
            monthFiltering={false}
            roleFilters={roleFilters}
            onEventSelected={handleEventSelected}
            viewedByAdmin={viewedByAdmin}
            leavesData={leavesData}
            loading={leaveEventsLoading}
            eventsByDays={eventsByDays}
          />
        </Box>
      </Box>
    </OcPageTransition>
  );
}
