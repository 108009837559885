import React, { useState } from "react";
import OcCard from "oc/components/oc-card";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import { useTranslation } from "react-i18next";
import LeaveFilterCalendar from "my-leave/components/my-leave-calendar";
import useCalendar from "hooks/use-my-leave-calendar";
import moment from "moment";
import _ from "lodash";
import getSortedList from "oc/utils/get-sorted-list";
import getFilterValues from "oc/utils/get-filter-values";
import { useHistory } from "react-router-dom";
import MyLeavesOverlapTables from "my-leave/components/my-leave-overlaps-tables/my-leave-overlaps-tables";
import parsePipedName from "oc/utils/get-parse-pipedName";

export default function AllLeavesWithCoverage({
  currentDate,
  onCurrentDateChange,
  monthFiltering,
  cardTitle,
  viewedByAdmin,
  showRemoteWorks = true,
  leavesData,
  loading,
  eventsByDays,
}) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  let startDate = moment(currentDate).startOf("year").toDate();
  let endDate = moment(currentDate).endOf("year").toDate();

  let role = "";

  if (viewedByAdmin) {
    role = "viewer";
  } else if (!viewedByAdmin) {
    role = "examiner";
  }

  const { data: Calendar } = useCalendar({ startDate, endDate });
  const [showCalendar, setShowCalendar] = useClientState(
    "showAllLeavesCalendar",
    true
  );

  const [selectedDate, setSelectedDate] = useState(false);

  const allLeavesId = "allLeaveRequestTable";
  const allLeavesByDayId = "allLeaveRequestByDayTable";

  //initialTableData.searchField = "title";
  initialTableData.pageSize = 5;

  const [tableAllData, setTableAllData] = useClientState(
    allLeavesId,
    initialTableData
  );
  const [tableAllByDayData, setTableAllByDayData] = useClientState(
    allLeavesByDayId,
    initialTableData
  );
  const [toolbarButton, setToolbarButton] = useState("basicTable");

  const tableId =
    toolbarButton === "basicTable" ? allLeavesId : allLeavesByDayId;
  const tableData =
    toolbarButton === "basicTable" ? tableAllData : tableAllByDayData;
  const setTableData =
    toolbarButton === "basicTable" ? setTableAllData : setTableAllByDayData;

  const eventsData =
    toolbarButton === "basicTable" || showCalendar ? leavesData : eventsByDays;


  let counter = 0;
  let parsedLeaveData =
    eventsData?.map((item) => {
      let newItem = _.cloneDeep(item);
      if (newItem.title.includes("|")) {
        newItem.title = parsePipedName(newItem.title, i18n);
      }
      newItem.typeName = t(`${item.type}`);
      newItem.type = t(item.leaveType);
      newItem.period =
        new Date(item.start).toLocaleDateString() +
        " - " +
        new Date(item.end).toLocaleDateString();
      newItem.counter = counter++;
      return newItem;
    }) || [];

  parsedLeaveData.forEach((item) => {
    if (item.date) {
      item.sortableDate = new Date(item.date).getTime();
    }
  });

  parsedLeaveData.sort((a, b) => {
    if (tableData.sortDirection === "asc") {
      return a.sortableDate - b.sortableDate;
    } else {
      return b.sortableDate - a.sortableDate;
    }
  });

  parsedLeaveData.sort(
    getSortedList(
      tableData?.sortDirection === "asc"
        ? tableData?.sortField
        : `-${tableData?.sortField}`
    )
  );

  if (selectedDate) {
    parsedLeaveData = parsedLeaveData.filter(
      (item) => new Date(item?.date) >= new Date(currentDate)
    );
  }

  if (tableData?.filterField) {
    let filterValues = getFilterValues(tableData?.filterField, parsedLeaveData);
    if (!_.isEqual(filterValues && tableData?.filterValues)) {
      setTableData({
        filterValues,
      });
    }
  }

  //Removed because it caused an error in the MyLeave calendar view. (Leaves did not appear)
  //Kikerült, mert hibát okozott a MyLeave naptár nézetben. (Nem jelentek meg a szabadságok)

  /*if (tableData?.filterSelected.length > 0) {
    parsedLeaveData = parsedLeaveData.filter(function (item) {
      let key = item[tableData?.filterField];
      return tableData?.filterSelected.includes(key);
    });
  }*/

  let filteredLeavesData = [];
  let filteredParsedLeavesData = [];

  if (!showRemoteWorks) {
    filteredLeavesData = eventsByDays?.filter(
      (item) =>
        item.leaveType === "leave" ||
        (item.type !== "O" && item.type !== "J" && item.type !== "I")
    );
    filteredParsedLeavesData = parsedLeaveData.filter(
      (item) => item.leaveType === "leave"
    );
  }

  let events = showRemoteWorks ? parsedLeaveData : filteredLeavesData;

  const searchText = tableData?.searchText;
  const searchField = tableData?.searchField;

  let rows;

  if (showRemoteWorks) {
    const filteredResult = events.filter(
      (item) =>
        searchText && new RegExp(searchText, "i").test(item?.[searchField])
    );

    rows = searchText.length === 0 ? events : filteredResult;
  } else {
    rows = filteredParsedLeavesData;
  }

  let total = rows.length;

  if (searchField) {
    rows = rows.filter((item) =>
      item?.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  const startOfMonth = moment(currentDate).startOf("month").toDate();
  const endOfMonth = moment(currentDate).endOf("month").toDate();
  const tableLeavesData = rows?.filter(
    (item) =>
      (moment(item.start).format("YYYY-MM-DD") >=
        moment(startOfMonth).format("YYYY-MM-DD") &&
        moment(item.start).format("YYYY-MM-DD") <=
        moment(endOfMonth).format("YYYY-MM-DD")) ||
      (moment(item.end).format("YYYY-MM-DD") >=
        moment(startOfMonth).format("YYYY-MM-DD") &&
        moment(item.end).format("YYYY-MM-DD") <=
        moment(endOfMonth).format("YYYY-MM-DD"))
  );

  let data = {
    rows: tableLeavesData,
    total: total,
    page: tableData?.page,
    pageSize: tableData?.pageSize,
    searchField: tableData?.searchField,
    searchText: tableData?.searchText,
  };

  function onSelectRow(event) {
    let { _id, contract } = event;
    let url = `/my-leave/user-request/MyLeave/${role}/${contract}/${_id}`;
    history.push(url);
  }

  const calendar = [
    {
      year: moment(currentDate).format("YYYY"),
      days: Calendar,
    },
  ];

  const handleTable = () => {
    setToolbarButton("basicTable");
  };

  const handleViewDayClick = () => {
    setToolbarButton("dailyTable");
  };

  const handleAgendaViewClick = () => {
    setToolbarButton("dailyTable");
    setShowCalendar(false);
    setSelectedDate(true);
  };

  return (
    <OcCard label={cardTitle} loading={loading}>
      <Box id="my-leave-all-leaves-with-coverage-card">
        <Box
          id="button-group"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          padding="18px 0px"
        >
          <ButtonGroup>
            <Button
              id="my-leave-all-leaves-with-coverage-card-calendar-button"
              onClick={() => {
                setShowCalendar(true);
                setSelectedDate(false);
              }}
              variant={!showCalendar ? "outlined" : "contained"}
              color={!showCalendar ? "default" : "primary"}
              disableElevation
            >
              {t("CALENDAR")}
            </Button>
            <Button
              id="my-leave-all-leaves-with-coverage-card-table-button"
              onClick={() => setShowCalendar(false)}
              variant={showCalendar ? "outlined" : "contained"}
              color={showCalendar ? "default" : "primary"}
              disableElevation
            >
              {t("TABLE")}
            </Button>
          </ButtonGroup>
        </Box>
        <Box paddingTop="20px">
          {showCalendar ? (
            <LeaveFilterCalendar
              events={parsedLeaveData}
              height={900}
              calendar={calendar}
              currentDate={currentDate}
              onCurrentDateChange={onCurrentDateChange}
              monthFiltering={monthFiltering}
              onEventSelected={onSelectRow}
              setToolbarButton={setToolbarButton}
              handleTable={handleTable}
              handleViewDayClick={handleViewDayClick}
              setShowCalendar={setShowCalendar}
              setSelectedDate={setSelectedDate}
              loading={loading}
              handleAgendaViewClick={handleAgendaViewClick}
            />
          ) : (
            <MyLeavesOverlapTables
              currentDate={currentDate}
              onCurrentDateChange={onCurrentDateChange}
              loading={loading}
              data={data}
              tableId={tableId}
              onSelectRow={onSelectRow}
              tableData={tableData}
              setTableData={setTableData}
              handleTable={handleTable}
              handleViewDayClick={handleViewDayClick}
              toolbarButton={toolbarButton}
            />
          )}
        </Box>
      </Box>
    </OcCard>
  );
}
