import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import MyHrUserDataRequestSubCollapseHeader from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-user-data-request-sub-collapse-header";
import { useTranslation } from "react-i18next";
import useStyles from "./oc-requests-messages-card.style";
import useCurrentUserState from "hooks/use-current-user-state";
import Avatar from "oc/components/oc-avatar";
import moment from "moment";
import Collapse from "@material-ui/core/Collapse";
import MyHrNewMessage from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-new-message/my-hr-new-message";
import MyLeaveNewMessage from "my-leave/components/my-leave-new-message";
import { Typography } from "@material-ui/core";
import MyTimeNewMessage from "my-time/components/my-time-new-message";

export default function OcRequestsMessagesCard({
  messages,
  data,
  module,
  loading,
  typeGroup,
  type,
  selectedId,
  typeGroupId,
  disabled
}) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  let { employeeCode } = useCurrentUserState();

  const [messageExpanded, setMessageExpanded] = useState(module !== "MyHr");

  const handleMessageExpandClick = () => {
    setMessageExpanded(!messageExpanded);
  };

  let locale = i18n.language.substr(0, 2).toLowerCase();

  return (
    <Box>
      <MyHrUserDataRequestSubCollapseHeader
        expanded={messageExpanded}
        handleExpandClick={handleMessageExpandClick}
        label={t("MESSAGES")}
        badge={messages.length + " " + t("MYHR_MESSAGE")}
        className={classes.messageBackgroundColor}
        classNameCollapse={classes.messageBackgroundColorOnCollapse}
      />
      <Collapse in={messageExpanded} timeout="auto">
        <Box className={classes.messageCard}>
          <Box className={classes.separatorBorder} />
          <Box height="24px" />
          {!loading &&
            messages?.map((messageItem, index) => {
              let createdAt = moment(messageItem?.createdAt)
                .locale(locale)
                .format("MMM DD. HH:mm");

              return (
                <Box key={`${index}_${messageItem?._id}`}>
                  {messageItem?.createdBy === employeeCode ? (
                    <Box className={classes.messageItemContainer}>
                      <Box className={classes.messageOrganizer}>
                        <Box className={classes.myMessageItemInnerContainer}>
                          <Typography variant="caption" className={classes.smallText}>{createdAt}</Typography>
                        </Box>
                        <Box className={classes.myMessageItem}>{messageItem?.message}</Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box className={classes.messageItemContainer}>
                      <Avatar employee={messageItem?.createdBy} className={classes.avatar} />
                      <Box className={classes.messageOrganizer}>
                        <Box className={classes.messageItemInnerContainer}>
                          <Typography variant="caption" className={classes.smallText}>{messageItem?.employeeName}</Typography>
                          <Typography variant="caption" className={classes.smallText}>{createdAt}</Typography>
                        </Box>
                        <Box className={classes.messageItem}>{messageItem?.message}</Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              );
            })}
          <Box height="8px" />
          {module === "MyHr" &&
            <MyHrNewMessage
              selectedRequest={selectedId}
              typeGroupId={typeGroupId}
              typeGroup={typeGroup}
              type={type}
            />
          }
          {module === "MyLeave" &&
            <MyLeaveNewMessage
              loading={loading}
              selectedRequest={data?._id}
              selectedEmployeeId={data?.createdBy}
              disabled={disabled}
            />
          }
          {module === "MyTime" &&
            <MyTimeNewMessage
              loading={loading}
              selectedRequest={data?._id}
              employee={data?.createdBy}
            />
          }
        </Box>
      </Collapse>
    </Box>
  );
}
