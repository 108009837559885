import React from "react";
import { Box } from "@material-ui/core";
import OcPageTransition from "oc/components/oc-page-transition";
import UserContractsCard from "my-hr/components/my-hr-user-contract-card";
import useContract from "hooks/use-myhr-contract";
import useEmployee from "hooks/use-myhr-employee";
import { useParams } from "react-router-dom";
import MyHrDocumentsDataPanel from "my-hr/components/my-hr-documents-data-panel/my-hr-documents-data-panel";
import useCurrentUserState from "hooks/use-current-user-state";
import getFullName from "oc/utils/get-fullname";
import useExaminers from "my-documents/hooks/use-my-documents-uploaders";
import { useTranslation } from "react-i18next";

const MyDocumentsUserFiles = () => {
  let { contract, employee } = useParams();
  const { i18n } = useTranslation();

  const { data: contractData } = useContract({
    contract: contract,
  });

  const { data: employeeData } = useEmployee({
    employeeId: employee,
  });

  const { data } = useExaminers({
    contractCode: contract,
    locale: i18n.language,
  });

  let { employeeCode } = useCurrentUserState();

  const { data: examinerData } = useEmployee({ employeeId: employeeCode });

  const fullName = getFullName(examinerData) || "";

  const isNameInArray = (arr, name) => {
    return arr.some(item => item === name);
  };

  const isExaminer = isNameInArray(data, fullName);

  return (
    <OcPageTransition>
      <Box padding="16px">
        <UserContractsCard employee={employeeData} contract={contractData} />
        <Box height="16px" />
        <MyHrDocumentsDataPanel isExaminer={isExaminer} contractCode={contractData?.code} />
        <Box height="16px" />
      </Box>
    </OcPageTransition>
  );
};

export default MyDocumentsUserFiles;
