import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import chevronLeft from "@iconify/icons-mdi/chevron-left";
import chevronRight from "@iconify/icons-mdi/chevron-right";
import { InlineIcon } from "@iconify/react";
import moment from "moment";
import useRecordingPeriod from "my-time/hooks/use-recording-period";

const MyTimePageHeader = ({
  date,
  onDateChange,
  formattedDate,
  examinerPage,
  closingperiodPage,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const changeDate = (action) => {
    if (action === "add") {
      onDateChange(moment(date).add(1, "month").toDate());
      return;
    }
    onDateChange(moment(date).subtract(1, "month").toDate());
  };

  const hideArrows = examinerPage || closingperiodPage;

  const { data } = useRecordingPeriod();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      style={{
        backgroundColor: theme.palette.primary.main,
        height: "64px",
        padding: "0px 16px",
        borderRadius: "8px",
      }}
    >
      <Box width="290px">
        <Typography
          variant="h5"
          style={{
            color: theme.palette.white.main,
          }}
        >
          {t("MY_TIME_PERIOD_TEXT")}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        flexGrow={1}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box paddingTop="8px">
            {!hideArrows && (
              <InlineIcon
                id="chevronLeft"
                icon={chevronLeft}
                width="36px"
                height="36px"
                color="#fff"
                style={{ cursor: "pointer" }}
                onClick={() => changeDate("subtract")}
              />
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="290px"
          >
            <Typography
              variant="h4"
              style={{
                color: theme.palette.white.main,
              }}
            >
              {closingperiodPage ? data : formattedDate}
            </Typography>
          </Box>
          <Box paddingTop="8px">
            {!hideArrows && (
              <InlineIcon
                id="chevronRight"
                icon={chevronRight}
                width="36px"
                height="36px"
                color="#fff"
                style={{ cursor: "pointer" }}
                onClick={() => changeDate("add")}
              />
            )}
          </Box>
        </Box>
      </Box>
      {!closingperiodPage ? (
        <Box
          width="290px"
          height="36px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          padding="0px 16px"
          justifyContent="center"
          style={{
            border: "1px solid #fff",
            borderRadius: "8px",
            color: theme.palette.white.main,
          }}
        >
          <Typography>{t("MY_TIME_RECORDING_PERIOD")}:</Typography>
          <Box width="4px" />
          <Typography>{data}</Typography>
        </Box>
      ) : (
        <Box width="290px" />
      )}
    </Box>
  );
};

MyTimePageHeader.defaultProps = {
  period: "not set",
};

export default MyTimePageHeader;
