import React, { useState, useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";

export default function OcPageTransition({ children }) {
  const location = useLocation() || "/";
  const [showFade, setShowFade] = useState(false);

  useEffect(() => {
    setShowFade(true);
    return () => {
      setShowFade(false);
    };
  }, [location]);

  return (
    <Slide
      id="oc-page-transition-profile-menu"
      in={showFade}
      direction="left"
    >
      <Box>
        {children}
      </Box>
    </Slide>
  );
}
