import gql from "graphql-tag";

export const GET_MONTHLY_WORK_TIME_BALANCE = gql`
  query getMonthlyWorkTimeBalance(
    $contractCode: String!
    $year: String!
    $month: String!
  ) {
    getMonthlyWorkTimeBalance(contractCode: $contractCode, year: $year, month: $month) {
      startBalance
      endBalance
      actualBalance
    }
  }
`;
