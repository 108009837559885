import React from "react";
import { Box, Card } from "@material-ui/core";
import CalendarDay from "my-leave/components/my-leave-calendar-day";
import useStyles from "./my-time-show-request-card.style";
import { useTranslation } from "react-i18next";
import useEmployeeDI from "hooks/use-myhr-employee";
import getFullName from "oc/utils/get-fullname";
import State from "my-leave/components/my-leave-state";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import MyTimeRequestTypeInterface from "my-time/components/my-time-request-type-interface";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_MY_TIME_REQUEST } from "my-time/graphql/mutations/delete-my-time-request";
import useOcSnackbar from "hooks/use-oc-snackbar";
import OcRequestsMessagesCard from "oc/components/oc-requests-messages-card/oc-requests-messages-card";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";

const MyTimeShowRequestCard = ({
  data,
  messages,
  loading,
  error,
  employeeCode,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles(data);
  const history = useHistory();

  const { data: approvedCode } = useEmployeeDI({ employeeId: data?.approvedBy });
  const { data: rejectedCode } = useEmployeeDI({ employeeId: data?.rejectedBy });


  const { data: employeeData } = useEmployeeDI({ employeeId: employeeCode });

  let locale = i18n.language.substr(0, 2).toLowerCase();

  function formatDateTime(dateTime, locale) {
    return moment(dateTime).locale(locale).format("YYYY. MM. DD. HH:mm");
  }

  let createdAt = formatDateTime(data?.createdAt, locale);
  let startTime = formatDateTime(data?.startTime, locale);
  let endTime = formatDateTime(data?.endTime, locale);

  let initialMessage =
    messages?.length > 0 && messages[0].createdBy === employeeCode
      ? messages[0]?.message
      : "";

  if (initialMessage) {
    const newMessages = messages.slice(1);
    messages = newMessages;
  }

  const handleClose = () => {
    history.goBack();
  };

  const { setOpenMessage } = useOcSnackbar();

  const [deleteRequest] = useMutation(DELETE_MY_TIME_REQUEST, {
    onCompleted: (data) => {
      setOpenMessage({
        type: "success",
        message: t("MY_TIME_REQUEST_DELETE_SUCCEEDED"),
      });

      handleClose();
    },
    onError: () => {
      setOpenMessage({
        type: "error",
        message: t("MY_TIME_DELETE_FAILED"),
      });
    },
  });

  const handleDelete = () => {
    deleteRequest({ variables: { _id: data?._id } });
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: "4px",
        borderColor: error ? "#cc0033" : "white",
        overflow: "visible",
      }}
    >
      <Box className={classes.box}>
        <Box className={classes.title}>{t("REQUEST")}</Box>
        <Box height="24px" />
        <Box className={classes.divider} />
        <Box height="24px" />
        <Box className={classes.organizer}>
          <Typography className={classes.fullnameText}>
            {getFullName(employeeData)}
          </Typography>
          <Box className={classes.createdAtOrganizer}>
            <Box fontSize="9px" paddingTop="1px" style={{ color: "#919699" }}>
              {createdAt}
            </Box>
            <Box height="2px" />
            {data?.state === "approved" &&
              <Box fontSize="9px" paddingTop="1px" style={{ color: "#919699" }}>
                {getFullName(approvedCode)}
              </Box>
            }
            {data?.state === "rejected" &&
              <Box fontSize="9px" paddingTop="1px" style={{ color: "#919699" }}>
                {getFullName(rejectedCode)}
              </Box>
            }
            <Box height="2px" />
            <State
              label={t(`Leave.${data?.state}`)}
              state={data?.state}
              loading={loading}
            />
          </Box>
        </Box>
        <Box height="24px" />
        <Box display="flex">
          <CalendarDay date={data?.hours} loading={loading} hours="true" />
          <Box width="16px" />
          <Box className={classes.periodContainer}>
            <Typography>{t("PERIOD")}</Typography>
            <Box>
              {startTime} - {endTime}
            </Box>
          </Box>
        </Box>
        {initialMessage?.length > 0 && (
          <Box
            marginTop="16px"
            className={
              loading ? classes.initialMessageLoading : classes.initialMessage
            }
          >
            {initialMessage}
          </Box>
        )}
        <Box height="24px" />
        <MyTimeRequestTypeInterface data={data} />
        <Box height="24px" />
        {!loading && (
          <OcRequestsMessagesCard
            messages={messages}
            module="MyTime"
            loading={loading}
            data={data}
          />
        )}
        <OcCardButtonBar
          leftButtonText={t("Leave.removeRequest")}
          leftButtonColor={data?.state === "requested" && "primary"}
          leftButtonDisabled={loading}
          handleLeftButton={handleDelete}
          leftButtonId="request-page-delete-button"
          rightButtonText={t("CANCEL")}
          rightButtonDisabled={loading}
          handleRightButton={handleClose}
          rightButtonId="request-page-cancel-button"
        />
      </Box>
    </Card>
  );
};

export default MyTimeShowRequestCard;
