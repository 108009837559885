import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import OcInfoCardItem from "oc/components/oc-info-card-item";
import React from "react";
import getCountryName from "oc/utils/get-country-name";
import getNationality from "oc/utils/get-nationality";
import OcInfoCardAddress from "oc/components/oc-info-card-address";
import Dividier from "oc/components/oc-info-card-divider";
import Period from "oc/components/oc-info-card-period";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import pencilIcon from "@iconify/icons-mdi/pencil";
import { useTheme } from "@material-ui/styles";
import { CustomTooltip } from "my-hr/components/my-hr-applications-data-panel/components/dependents-collapse/dependents-collapse.style";
import useChangeRequestsExaminersName from "hooks/use-change-requests-examiners-name";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import useCurrentUserState from "hooks/use-current-user-state";

const Label = ({
  label,
  editable,
  type,
  onEdit = () => { },
  editButtonDisabled,
}) => {
  const theme = useTheme();
  const handleEditButton = () => {
    if (editButtonDisabled) return null;
    onEdit(type);
  };

  const { t, i18n } = useTranslation();

  let { contractCode } = useCurrentUserState();

  const { data: examinersData } = useChangeRequestsExaminersName({
    locale: i18n.language,
    contract: contractCode,
  });

  const { data: rolesData } = useUserRolesDataByContract({
    contract: contractCode,
  });

  const isExaminer = rolesData?.find(
    (item) => item.product === "MyHR" && item.role === "examiner"
  )
    ? true
    : false;

  const hasExaminer = examinersData?.length > 0;

  let isDisabled = !hasExaminer;
  if (isExaminer && !hasExaminer) {
    isDisabled = false;
  }

  if (isDisabled) {
    editable = false;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>{label}</Box>
      {editable && (
        <Box
          style={
            editButtonDisabled ? { cursor: "auto" } : { cursor: "pointer" }
          }
          onClick={handleEditButton}
        >
          <InlineIcon
            id={`pencilIcon-oc-card-label${label}`}
            width="18px"
            height="18px"
            icon={pencilIcon}
            color={
              editButtonDisabled
                ? theme.palette.gray40.main
                : theme.palette.gray80.main
            }
          />
        </Box>
      )}
      {isDisabled && (
        <CustomTooltip title={t("NOT_SET_EXAMINER_INFO")} placement="top">
          <Box>
            <InlineIcon
              id={`pencilIcon-oc-card-label${label}`}
              width="18px"
              height="18px"
              icon={pencilIcon}
              color={theme.palette.gray40.main}
            />
          </Box>
        </CustomTooltip>
      )}
    </Box>
  );
};

export default function OcInfoCard({
  def,
  data,
  editable = false,
  onEdit = () => { },
  hasChangeRequest = () => { },
  defaultLabel = "",
}) {
  const { t, i18n } = useTranslation();
  let label = t(def.translation) || def.label || "Default card label";

  if (def.type === "contract") {
    label =
      data?.jobClass?.name.charAt(0).toUpperCase() +
      data?.jobClass?.name.slice(1) || "undefined";
    if (
      data?.employmentType?.code === "5" ||
      data?.employmentType?.code === "6"
    ) {
      const rawLabel = t("CONTRACT_OF_SERVICES");
      label = rawLabel.charAt(0).toUpperCase() + rawLabel.slice(1);
    }
  }
  if (def.type === "address") {
    let isEditButtonDisabled = hasChangeRequest({
      typeGroup: def?.address,
    });

    return (
      <OcInfoCardAddress
        label={def.label || ""}
        translation={def.translation}
        address={data && data[def.address]}
        def={def}
        onEdit={onEdit}
        isEditButtonDisabled={isEditButtonDisabled}
        editable={editable}
        defaultLabel={defaultLabel}
      />
    );
  }

  if (def.type === "email") {
    const handleEmail = (type) => {
      onEdit("emails", type);
    };

    return (
      <OcCard label={label || ""}>
        <>
          {data?.emails?.map((item, index) => {
            let isEditButtonDisabled = hasChangeRequest({
              type: `${item.type}Email`,
            });
            return (
              <OcInfoCardItem
                key={`${item.type}-${index}`}
                label={t(`EMAIL_${item?.type?.toUpperCase()}`)}
                value={item?.address}
                type={item?.type}
                name={item.__typename}
                editable={editable}
                onEdit={handleEmail}
                isEditButtonDisabled={isEditButtonDisabled}
              />
            );
          })}
        </>
      </OcCard>
    );
  }

  if (def.type === "phone") {
    const handlePhone = (type) => {
      onEdit("phones", type);
    };
    return (
      <OcCard label={label || ""}>
        <>
          {data?.phones?.map((item, index) => {
            let isEditButtonDisabled = hasChangeRequest({
              type: `${item.type}Phone`,
            });
            return (
              <OcInfoCardItem
                key={`${item.type}-${index}`}
                label={t(`PHONE_${item?.type?.toUpperCase()}`)}
                value={item?.number}
                type={item?.type}
                editable={editable}
                onEdit={handlePhone}
                isEditButtonDisabled={isEditButtonDisabled}
              />
            );
          })}
        </>
      </OcCard>
    );
  }

  if (def.type === "name") {
    const editButtonDisabled = hasChangeRequest({ typeGroup: "name" });
    return (
      <OcCard
        label={
          <Label
            label={label}
            editable={editable}
            onEdit={onEdit}
            type={def?.type}
            editButtonDisabled={editButtonDisabled}
          />
        }
        editable={editable}
        translation={def.translation}
        data={data}
      >
        <>
          {def?.children?.map((item, index) => {
            let label =
              t(item.translation) || item.label || "Default item label";
            let value = data && data[item.value];
            return (
              <OcInfoCardItem
                key={`${index}-${label}`}
                label={label}
                value={value}
                translation={item?.translation}
                type={item?.type}
                data={data}
                editable={false}
              />
            );
          })}
        </>
      </OcCard>
    );
  }

  return (
    <OcCard
      id="oc-info-card-data"
      label={label || ""}
      translation={def.translation}
      data={data}
    >
      <>
        {def?.children?.map((item, index) => {
          let isEditButtonDisabled = hasChangeRequest({ type: item.type });

          let label = t(item.translation) || item.label || "Default item label";
          let value = data && data[item.value];

          const lastDividerIndex = def?.children
            .map((child, i) => (child.type === "divider" ? i : null))
            .filter((i) => i !== null)
            .pop();

          if (item.type === "date") {
            value = value ? new Date(value).toLocaleDateString() : "";
          }
          if (item.type === "country") {
            value = getCountryName(value, i18n?.language);
          }

          if ((item?.value === "shiftType" || item?.value === "maxOvertimeInHour" || item?.value === "jobClass") && data?.employmentType?.code === "5") {
            return null;
          }

          if (item.type === "dateOfBirth") {
            value = new Date(value).toLocaleDateString();
          }
          if (item.type === "countryOfBirth") {
            value = getCountryName(value, i18n?.language);
          }
          if (
            item.type === "subName" ||
            item.type === "gender" ||
            item.type === "maritalStatus"
          ) {
            value = value?.name;
          }
          if (item.type === "nationality") {
            value = getNationality(value, i18n?.language);
          }
          if (item.type === "divider") {
            if (index !== lastDividerIndex || data?.employmentType?.code !== "5") {
              return <Dividier key={`${index}-${label}`} />;
            } else {
              return null;
            }
          }
          if (item.type === "period") {
            return (
              <Period
                key={`${index}-${label}`}
                validFrom={data?.validFrom}
                validTo={data?.validTo}
              />
            );
          }
          if (item.type === "mothersName") {
            value = data?.mothersLastName
              ? `${data?.mothersNamePrefix} ${data?.mothersLastName} ${data?.mothersFirstName} ${data?.mothersFirstName2} ${data?.mothersNameSuffix}`
              : "";
          }

          let isEditable = editable;
          if (
            item.type === "dateOfBirth" ||
            item.type === "taxNumber" ||
            item.type === "socialSecurityNumber"
          ) {
            isEditable = false;
          }

          return (
            <OcInfoCardItem
              key={`${index}-${label}`}
              label={label}
              value={value}
              translation={item?.translation}
              type={item?.type}
              data={data}
              onEdit={onEdit}
              editable={isEditable}
              isEditButtonDisabled={isEditButtonDisabled}
            />
          );
        })}
      </>
    </OcCard>
  );
}
