import React from "react";
import Box from "@material-ui/core/Box";
import OcInfoCard from "oc/components/oc-info-card";
import {
  permanentAddress,
  temporaryAddress,
  serviceAddress,
  email,
  phone,
} from "my-hr/configs/my-hr-info-card-defs";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import useCurrentUserState from "hooks/use-current-user-state";
import useChangeRequests from "hooks/use-change-requests";
import useChangeRequestsExaminersName from "hooks/use-change-requests-examiners-name";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import { CustomTooltip } from "my-hr/components/my-hr-applications-data-panel/components/dependents-collapse/dependents-collapse.style";

export default function MyHrContactsDataPanel({
  employee,
  onEdit,
  hasChangeRequest,
  editable = false,
}) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  let { employeeCode } = useCurrentUserState();

  const { data } = useChangeRequests(employeeCode);

  const isExistPermanentAddressRequest = data?.find(
    (item) =>
      item?.typeGroup === "permanentAddress" &&
      item?.approvedAt === null &&
      item?.rejectedAt === null
  );

  const isExistTemporaryAddressRequest = data?.find(
    (item) =>
      item?.typeGroup === "temporaryAddress" &&
      item?.approvedAt === null &&
      item?.rejectedAt === null
  );

  const isExistServiceAddressRequest = data?.find(
    (item) =>
      item?.typeGroup === "serviceAddress" &&
      item?.approvedAt === null &&
      item?.rejectedAt === null
  );

  let { contractCode } = useCurrentUserState();

  const { data: examinersData } = useChangeRequestsExaminersName({
    locale: i18n.language,
    contract: contractCode,
  });

  const { data: rolesData } = useUserRolesDataByContract({
    contract: contractCode,
  });

  const isExaminer = rolesData?.find(
    (item) => item.product === "MyHR" && item.role === "examiner"
  )
    ? true
    : false;

  const hasExaminer = examinersData?.length > 0;

  let isDisabled = !hasExaminer;
  if (isExaminer && !hasExaminer) {
    isDisabled = false;
  }

  if (isDisabled) {
    editable = false;
  }

  return (
    <Box id="tab-panel-container">
      <Box paddingBottom="16px">
        <OcInfoCard
          def={phone}
          data={employee}
          onEdit={onEdit}
          hasChangeRequest={hasChangeRequest}
          editable={editable}
        />
      </Box>
      <Box paddingBottom="16px">
        <OcInfoCard
          def={email}
          data={employee}
          onEdit={onEdit}
          hasChangeRequest={hasChangeRequest}
          editable={editable}
        />
      </Box>
      <Box paddingBottom="16px">
        <OcInfoCard
          def={permanentAddress}
          data={employee}
          onEdit={onEdit}
          hasChangeRequest={hasChangeRequest}
          editable={editable}
        />
      </Box>
      <Box paddingBottom="16px">
        <OcInfoCard
          def={temporaryAddress}
          data={employee}
          onEdit={onEdit}
          hasChangeRequest={hasChangeRequest}
          editable={editable}
        />
      </Box>
      <Box paddingBottom="16px">
        <OcInfoCard
          def={serviceAddress}
          data={employee}
          onEdit={onEdit}
          hasChangeRequest={hasChangeRequest}
          editable={editable}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {!isDisabled && (
          <Button
            id="add-new-address-button"
            onClick={() => onEdit("newAddress")}
            disabled={
              (employee?.permanentAddress?.city?.length === 0 &&
                isExistPermanentAddressRequest === undefined) ||
              (employee?.serviceAddress?.city?.length === 0 &&
                isExistServiceAddressRequest === undefined) ||
              (employee?.temporaryAddress?.city?.length === 0 &&
                isExistTemporaryAddressRequest === undefined)
                ? false
                : true
            }
            style={{
              backgroundColor: theme.palette.gray30.main,
            }}
          >
            {t("ADD_NEW_ADDRESS")}
          </Button>
        )}
        {isDisabled && (
          <CustomTooltip title={t("NOT_SET_EXAMINER_INFO")} placement="top">
            <Button
              id="add-new-address-button"
              style={{
                backgroundColor: theme.palette.gray30.main,
                color: theme.palette.gray40.main,
              }}
            >
              {t("ADD_NEW_ADDRESS")}
            </Button>
          </CustomTooltip>
        )}
      </Box>
      <Box height="25px" />
    </Box>
  );
}
