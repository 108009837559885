import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./my-hr-user-data-edit-collapse.style";
import MyHrUserDataEditCollapseHeader from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-user-data-edit-collapse-header";
import Collapse from "@material-ui/core/Collapse";
import MyHrUserDataCardInfoItem from "../my-hr-user-data-edit-card-item";
import MyHrUserDataChangeBox from "../my-hr-user-data-change-box";
import { useMutation } from "@apollo/react-hooks";
import {
  DELETE_CHANGE_REQUEST,
  APPROVE_CHANGE_REQUEST,
  REJECT_CHANGE_REQUEST,
} from "graphql/mutations";
import useChangeRequests from "hooks/use-change-requests";
import { useHistory } from "react-router-dom";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import useCurrentUserState from "hooks/use-current-user-state";
import getCountries from "oc/utils/get-countries";
import getNationalities from "oc/utils/get-nationalities";
import MyHrUserDataRequesAttachment from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-user-data-request-attachment";
import useClientState from "hooks/use-oc-client-state";
import OcInfoCard from "oc/components/oc-info-card";
import {
  permanentAddress,
  temporaryAddress,
  serviceAddress,
} from "my-hr/configs/my-hr-info-card-defs";
import useEmployee from "hooks/use-myhr-employee";
import useOcSnackbar from "hooks/use-oc-snackbar";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";
import OcRequestsMessagesCard from "oc/components/oc-requests-messages-card/oc-requests-messages-card";

export default function MyHrUserDataEditCollapse({
  oldValue,
  newValue,
  type,
  createdDateAt,
  validDateFrom,
  messages,
  approvedAt,
  rejectedAt,
  selectedId,
  typeGroup,
  employeeCode,
  isAdmin,
  typeGroupId,
  disabled,
}) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  let { contractCode } = useCurrentUserState();
  const countries = getCountries(i18n.language);
  const nationalities = getNationalities(i18n.language);
  const [message] = useClientState("newMessageText", "");

  const { data: rolesData } = useUserRolesDataByContract({
    contract: contractCode,
  });

  const isMyHrExaminer = rolesData.find(
    (item) => item.product === "MyHR" && item.role === "examiner"
  );

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: allDataTypeGroup, refetch } = useChangeRequests(employeeCode);

  const { data: employeeData } = useEmployee({ employeeId: employeeCode });

  const { setOpenMessage } = useOcSnackbar();

  const myRequests = history?.location?.pathname === "/my-hr/my-requests";

  const [deleteChangeRequest] = useMutation(DELETE_CHANGE_REQUEST, {
    onCompleted: (data) => {
      refetch();
      history.push(
        isMyHrExaminer && !myRequests ? `/my-hr/view-requests` : `/my-hr/my-requests`
      );
      setOpenMessage({
        type: "success",
        message: t("DELETE_REQUEST"),
      });
      setLoading(false);
    },
    onError: (error) => {
      if (error.message === "Import process cancelled") {
        console.log(error);
      }
      setOpenMessage({
        type: "error",
        message: t("DELETE_REQUEST_ERROR"),
      });
    },
  });

  const [approveChangeRequest] = useMutation(APPROVE_CHANGE_REQUEST, {
    onCompleted: (data) => {
      if (data?.approveChangeRequest?.result) {
        refetch();
        setOpenMessage({
          type: "success",
          message: t("CHANGE_REQUEST_SUCCEEDED"),
        });
        setLoading(false);
        //history.push(`/my-hr/view-requests`);
      } else {
        setOpenMessage({
          type: "error",
          message: data?.approveChangeRequest?.messages?.[0] || t("ERROR"),
        });
      }
    },
    onError: (error) => {
      console.log("send error message", error);
    },
  });

  const [rejectChangeRequest] = useMutation(REJECT_CHANGE_REQUEST, {
    onCompleted: (data) => {
      refetch();
      setOpenMessage({
        type: "success",
        message: t("CHANGE_REQUEST_REJECTED"),
      });
      setLoading(false);
      //history.push(`/my-hr/view-requests`);
    },
    onError: (error) => {
      console.log("send error message", error);
    },
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function handleDelete() {
    //console.log({ selectedId });
    deleteChangeRequest({
      variables: { _id: selectedId, locale: i18n.language },
    });
    setLoading(true);
  }

  const handleReject = () => {
    rejectChangeRequest({
      variables: { _id: selectedId, locale: i18n.language, message },
    });
    setLoading(true);
  };

  const filterByTypeGroup = (typeGroup, typeGroupId) =>
    allDataTypeGroup?.filter(
      (item) =>
        item?.typeGroup === typeGroup && item?.typeGroupId === typeGroupId
    );

  const isExistNameFilter = filterByTypeGroup("name", typeGroupId);
  const isExistMotherNameFilter = filterByTypeGroup("mothersName", typeGroupId);
  const isExistPermanentAddressFilter = filterByTypeGroup(
    "permanentAddress",
    typeGroupId
  );
  const isExistTemporaryAddressFilter = filterByTypeGroup(
    "temporaryAddress",
    typeGroupId
  );
  const isExistServiceAddressFilter = filterByTypeGroup(
    "serviceAddress",
    typeGroupId
  );

  const selectedItem = (code, array) =>
    array.find((item) => item.code === code);

  const selectedCounty = (code) => selectedItem(code, countries);
  const oldSelectedCounty = selectedCounty(oldValue);
  const newSelectedCounty = selectedCounty(newValue);

  const selectedNationality = (code) => selectedItem(code, nationalities);
  const oldNationality = selectedNationality(oldValue);
  const newNationality = selectedNationality(newValue);

  function handleApprove() {
    const params = {
      _id: selectedId,
      locale: i18n.language,
      message,
    };
    approveChangeRequest({ variables: params });
    setLoading(true);
  }

  const getExistFilter = (typeGroup) => {
    switch (typeGroup) {
      case "name":
        return isExistNameFilter;
      case "permanentAddress":
        return isExistPermanentAddressFilter;
      case "serviceAddress":
        return isExistServiceAddressFilter;
      case "temporaryAddress":
        return isExistTemporaryAddressFilter;
      case "mothersName":
        return isExistMotherNameFilter;
      default:
        return [];
    }
  };

  const getInvalidAddress = (type) => {
    switch (type) {
      case "permanentAddress":
        return permanentAddress;
      case "temporaryAddress":
        return temporaryAddress;
      case "serviceAddress":
        return serviceAddress;
      default:
        return [];
    }
  };

  const renderUserDataChangeBox = (item) => {
    if (Array.isArray(item)) {
      if (typeGroup === "invalidateAddress") {
        return (
          <Box width="640px">
            <OcInfoCard
              defaultLabel={t(type) + " " + t("INVALIDITON")}
              def={getInvalidAddress(type)}
              data={employeeData}
              editable={false}
            />
          </Box>
        );
      }
      return item.map((subItem, subIndex) => {
        const { type, oldValue, newValue } = subItem;
        return (
          <Box key={`${subItem}-${subIndex}`}>
            <MyHrUserDataChangeBox
              type={type}
              oldValue={
                type === "country" ? selectedCounty(oldValue)?.name : oldValue
              }
              newValue={
                type === "country" ? selectedCounty(newValue)?.name : newValue
              }
            />
            <Box style={{ margin: "16px 0" }} />
          </Box>
        );
      });
    }
  };

  if (type === "nationality") {
    oldValue = oldNationality?.name;
    newValue = newNationality?.name;
  }

  if (type === "dateOfBirth") {
    oldValue = new Date(oldValue).toLocaleDateString();
    newValue = new Date(newValue).toLocaleDateString();
  }

  if (type === "countryOfBirth") {
    oldValue = oldSelectedCounty?.name;
    newValue = newSelectedCounty?.name;
  }

  if (oldValue === "not_defined") {
    oldValue = "";
  }

  return (
    <>
      <MyHrUserDataEditCollapseHeader
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        type={type}
        createdDateAt={createdDateAt}
        approvedAt={approvedAt}
        rejectedAt={rejectedAt}
        typeGroup={typeGroup}
      />
      <Collapse in={expanded} timeout="auto">
        <Box className={classes.backgroundColor} padding="0px 16px">
          <Box className={classes.topBorder} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            padding="16px 0px 6px 0px"
          >
            <MyHrUserDataCardInfoItem
              label={t("REQUEST_CREATED_AT")}
              value={createdDateAt}
            />
            <MyHrUserDataCardInfoItem
              label={t("DATE_OF_VALIDITY")}
              value={validDateFrom}
            />
          </Box>
          <Box className={classes.separatorBorder} />
          <Box padding="16px 16px 0px 16px">
            {renderUserDataChangeBox(getExistFilter(typeGroup))}
            {(!typeGroup ||
              typeGroup === "phones" ||
              typeGroup === "emails") && (
                <>
                  <MyHrUserDataChangeBox
                    type={type}
                    oldValue={oldValue}
                    newValue={newValue}
                  />
                  <Box height="16px" />
                </>
              )}
          </Box>
          <Box height="16px" />
          <Box maxWidth="640px" marginLeft="16px">
            {approvedAt === null && rejectedAt === null && (
              <>
                <MyHrUserDataRequesAttachment
                  employeeCode={employeeCode}
                  createdDateAt={createdDateAt}
                  approvedAt={approvedAt}
                  rejectedAt={rejectedAt}
                  typeGroup={typeGroup}
                  type={type}
                />
                <Box height="16px" />
              </>
            )}
            <OcRequestsMessagesCard
              messages={messages}
              module="MyHr"
              createdDateAt={createdDateAt}
              typeGroup={typeGroup}
              type={type}
              selectedId={selectedId}
              typeGroupId={typeGroupId}
              disabled={disabled}
            />
          </Box>
          <Collapse
            in={rejectedAt === null && approvedAt === null && !isAdmin}
            timeout="auto"
          >
            <Box padding="0px 16px">
              <OcCardButtonBar
                handleLeftButton={handleDelete}
                leftButtonColor="primary"
                leftButtonText={t("DELETE")}
                leftButtonDisabled={loading}
                leftButtonId={
                  typeGroup === "name" || typeGroup === "mothersName"
                    ? `delete-button-${typeGroup}`
                    : `delete-button-${type}`
                }

                handleRightButton={handleExpandClick}
                rightButtonText={t("Leave.close")}
                rightButtonDisabled={loading}
                rightButtonId={`close-button-${type}`}
              />
            </Box>
          </Collapse>
          <Collapse
            in={rejectedAt === null && approvedAt === null && isAdmin}
            timeout="auto"
          >
            <Box padding="0px 16px">
              <OcCardButtonBar
                handleLeftButton={handleReject}
                leftButtonColor="primary"
                leftButtonText={t("REJECT")}
                leftButtonDisabled={loading || disabled}
                leftButtonId="reject-button"
                handleRightButton={handleExpandClick}
                rightButtonText={t("CANCEL")}
                rightButtonDisabled={loading}
                rightButtonId="cancel-button"
                handlePrimaryButton={handleApprove}
                primaryButtonText={t("APPROVE")}
                primaryButtonDisabled={loading || disabled}
                primaryButtonId="approve-button"
              />
            </Box>
          </Collapse>
        </Box>
      </Collapse>
    </>
  );
}
