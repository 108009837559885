import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import getFilterIcon from "oc/utils/get-filter-icon";
import { InlineIcon } from "@iconify/react";
import useWidth from "hooks/use-oc-component-width";
import StyledBadge from "../styled-badge";

export default function OcFilterItemHeader({
  filterType,
  expanded,
  selectedItems,
  onExpandClick,
  disabled,
}) {
  const { t } = useTranslation();
  let icon = getFilterIcon({ filterType });
  let items = selectedItems?.map((item) =>
    item?.name === "empty" ? t(item?.name?.toUpperCase()) : item?.name
  );
  let subtitle = items?.join(", ");

  let backgroundColor = subtitle?.length > 0 ? "white" : "#E3E5E5";

  let localizedFilterType = t(filterType?.toUpperCase());
  if (filterType && filterType === "contract") {
    localizedFilterType = t("GET_EMPLOYEE");
  }

  let hasSelectedValues = selectedItems?.length > 0 ? true : false;
  let expandedButtonDisabled = disabled && !hasSelectedValues;

  let containerRef = React.createRef();

  const [width] = useWidth(containerRef);

  return (
    <OcErrorBoundary>
      <Box
        ref={containerRef}
        id={`${filterType}-collapse-header`}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height="60px"
        padding="0px 12px"
        borderRadius="4px"
        style={{
          backgroundColor: hasSelectedValues ? "white" : backgroundColor,
          border: `1px solid ${backgroundColor}`,
        }}
      >
        {hasSelectedValues ? (
          <Box
            id="item-header"
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="inherit"
            flexGrow={1}
          >
            <StyledBadge badgeContent={selectedItems.length} color="primary">
              <InlineIcon
                icon={icon}
                height="24px"
                style={{ color: "#4D4D4D", overflow: "hidden" }}
              />
            </StyledBadge>
            <Box
              display="flex"
              flexDirection="column"
              style={{
                marginLeft: 20,
                width: width - 96,
              }}
            >
              <Box
                style={{
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                  color: "#5E6366",
                  paddingBottom: 6,
                }}
              >
                {localizedFilterType}
              </Box>
              <Box
                style={{
                  fontSize: "16px",
                  letterSpacing: "0.44px",
                  color: "#212121",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {subtitle}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <InlineIcon
              icon={icon}
              height="24px"
              style={{
                color: "#4D4D4D",
              }}
            />
            <Box
              style={{
                marginLeft: 20,
                fontSize: "16px",
                letterSpacing: "0.44px",
              }}
            >
              {localizedFilterType}
            </Box>
          </Box>
        )}
        <IconButton
          id={`${filterType}-collapse-header-button`}
          onClick={onExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          disabled={expandedButtonDisabled}
          style={
            expanded
              ? {
                padding: 0,
                transform: "rotate(180deg)",
              }
              : {
                padding: 0,
              }
          }
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
    </OcErrorBoundary>
  );
}
