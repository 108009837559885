import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import useStyles from "./oc-editor-menu.style";
import { InlineIcon } from "@iconify/react";
import pencilIcon from "@iconify/icons-mdi/pencil";
import deleteIcon from "@iconify/icons-mdi/delete";

const OcEditorMenu = ({
    handleClose,
    handleModified,
    handleDelete,
    anchorEl,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 30,
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            style={{
                borderRadius: "4px",
            }}
        >
            <Box>
                <MenuItem id="modified-application" onClick={handleModified}>
                    <InlineIcon icon={pencilIcon} className={classes.icon} />
                    <span className={classes.text}>{t("Common.modify")}</span>
                </MenuItem>
                <MenuItem id="delete-application" onClick={handleDelete}>
                    <InlineIcon icon={deleteIcon} className={classes.icon} />
                    <span className={classes.text}>{t("Common.delete")}</span>
                </MenuItem>
            </Box>
        </Menu>
    );
};

export default OcEditorMenu;
