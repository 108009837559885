import React, { useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import useStyles from "oc/components/oc-info-card-item/oc-info-card-item.style";
import { useTheme } from "@material-ui/core/styles";
import { InlineIcon } from "@iconify/react";
import fileDocument from "@iconify/icons-mdi/file-document";
import LinearProgress from "@material-ui/core/LinearProgress";
import deleteIcon from "@iconify/icons-mdi/delete";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import paperclipIcon from "@iconify/icons-mdi/paperclip";
import Typography from "@material-ui/core/Typography";
import useCurrentUserState from "hooks/use-current-user-state";
import axios from "axios";
import useAssets from "hooks/use-assets";
import useOcSnackbar from "hooks/use-oc-snackbar";

export default function MyHrDropzoneEditor({ type, filename }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const { data, refetch, deleteAsset } = useAssets({ type });

  const { setOpenMessage } = useOcSnackbar();

  const [newChangeFileDocument, setNewChangeFileDocument] = useState(false);
  const [file, setFile] = useState(null);
  const [, /*isUpload,*/ setIsUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [axiosError, setAxiosError] = useState(false);

  let { employeeCode } = useCurrentUserState();

  async function handleUpload(file) {
    let apiUrl = "http://localhost:9000/api/upload";

    if (process.env.NODE_ENV === "production") {
      apiUrl = "/api/upload";
    }

    setIsUpload(true);
    const formData = new FormData();
    formData.append("employeeCode", "");
    formData.append("type", type);
    formData.append("owner", employeeCode);
    formData.append("file", file);
    try {
      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (file) {
        await axios.post(apiUrl, formData, config);
        setIsUpload(false);
        setUploadProgress(100);
        refetch();
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.errors !== "undefined") {
        setAxiosError(true);
      }
    }
  }

  function removeUpload(item) {
    const { _id } = item;
    deleteAsset({ variables: { _id } });
    //setFile(false);
  }

  function handleDropzoneChange(file) {
    if (file?.size > 8000000) {
      setOpenMessage({
        type: "error",
        message: t("FILE_UPLOAD_SIZE_FAILED"),
      });
      return;
    }
    setFile(file);
    handleUpload(file);
  }

  function removeAssetTag(filename) {
    let filenameArray = filename.split("/");
    return filenameArray[filenameArray.length - 1];
  }

  return (
    <>
      {newChangeFileDocument?.button === 0 ? null : (
        <Button
          id="myhr-dropzone-upload-document"
          variant="outlined"
          fullWidth
          startIcon={<InlineIcon icon={paperclipIcon} />}
          style={{
            background: theme.palette.gray30.main,
            border: "none",
          }}
          onClick={setNewChangeFileDocument}
        >
          <Box flexGrow={1}>{t("UPLOAD_FILE")}</Box>
        </Button>
      )}

      <Collapse
        in={newChangeFileDocument?.button === 0}
        timeout="auto"
        id="myhr-dropzone-area"
      >
        <DropzoneArea
          classes={{
            root: classes.dropzoneRoot,
            icon: classes.muiSvgIcon,
            textContainer: classes.textContainer,
            text: classes.text,
          }}
          dropzoneText={t("DRAG_AND_DROP_FILE")}
          acceptedFiles={[
            ".pdf",
            ".jpeg",
            ".png",
            ".jpg",
            ".PDF",
            ".JPEG",
            ".PNG",
            ".JPG",
          ]}
          filesLimit={1}
          maxFileSize={10000000000}
          showPreviewsInDropzone={false}
          showFileNames={true}
          showAlerts={false}
          onChange={(files) => handleDropzoneChange(files[0])}
        />
      </Collapse>
      <Box height="32px" />
      {uploadProgress === 100 ? null : (
        <Collapse in={file ? true : false}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{
              width: "511px",
              backgroundColor: theme.palette.gray20.main,
              padding: "16px 20px",
              borderRadius: "8px",
              border: "1px solid #C6CACC",
              marginBottom: "32px",
            }}
          >
            <Box>
              <InlineIcon
                icon={fileDocument}
                width="24px"
                style={{ color: "#4d4d4d" }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                padding: "0px 16px",
              }}
            >
              <Typography variant="body1">{file?.name}</Typography>
              <Box padding={"16px 0px"}>
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  color="primary"
                  style={{ borderRadius: "8px" }}
                />
              </Box>
              <Typography variant="caption" style={{ color: "#919699" }}>
                {uploadProgress}% done
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">{filename}</Typography>
              <InlineIcon
                id="delete-icon"
                icon={deleteIcon}
                width="24px"
                style={{
                  color: "#4d4d4d",
                  cursor: "pointer",
                }}
                onClick={removeUpload}
              />
            </Box>
          </Box>
        </Collapse>
      )}
      <Collapse in={data.length > 0}>
        {data.map((item, index) => (
          <Box key={`${item}-${index}`} paddingBottom="36px">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              height={axiosError === true ? "78px" : "61px"}
              border={
                axiosError === true ? "1px solid red" : "1px solid #C6CACC"
              }
              style={{
                width: "530px",
                borderRadius: "8px",
                paddingLeft: "20px",
                backgroundColor: theme.palette.gray20.main,
              }}
            >
              <InlineIcon
                icon={fileDocument}
                width="24px"
                style={{ color: "#4d4d4d" }}
              />
              <Box width="14px" />
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Box>
                  <Typography variant="body1">
                    {removeAssetTag(item.filename)}
                  </Typography>
                </Box>
                {axiosError === true ? (
                  <Typography
                    variant="caption"
                    style={{
                      color: "#CC0033",
                      marginTop: "2px",
                    }}
                  >
                    {t("UPLOAD_FAILED")}
                  </Typography>
                ) : null}
              </Box>
              <Box paddingRight="16px">
                <InlineIcon
                  id="delete-icon"
                  icon={deleteIcon}
                  width="24px"
                  style={{
                    color: "#4d4d4d",
                    cursor: "pointer",
                  }}
                  onClick={() => removeUpload(item)}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Collapse>
      <Collapse in={newChangeFileDocument?.button === 0}>
        <Typography
          style={{
            fontSize: "12px",
            letterSpacing: "0.4px",
            lineHeight: "16px",
            marginBottom: "32px",
            color: "#5E6366",
          }}
        >
          A feltöltéssel hozzájárulok ahhoz, hogy az ügyintéző a feltöltött
          dokumentumban lévő adataimat megtekinthesse. A változott adat(ok)
          jóváhagyását követően a feltöltött dokumentum automatikusan törlésre
          kerül.
        </Typography>
      </Collapse>
    </>
  );
}
