import React from "react";
import { useTranslation } from "react-i18next";
import usePeriod from "my-time/hooks/use-period";
import { useTheme } from "@material-ui/styles";
import useAdmission from "my-time/hooks/use-admission";
import useRecordingPeriod from "my-time/hooks/use-recording-period";
import { Box } from "@material-ui/core";
import moment from "moment";
import { countDailyHours, getDateRangeArray } from "my-time/utils";
import MyTimeWorkingFrameBox from "my-time/components/my-time-working-frame-box";

const MyTimeOvertimeFrame = ({ contractCode, year, month, minHeight }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const weeklyData = "weekly";

  const { data, loading } = usePeriod({
    contractCode,
    year: year,
    month: month,
  });

  const recordingPeriod = useRecordingPeriod();

  const { data: admissionData } = useAdmission({
    contractCode,
    year,
    month,
    changeMyTimeEnabled: true,
    recordingPeriod,
  });

  let startPeriod = moment(data?.startDate).startOf("month").toDate();
  let endPeriod = moment(data?.endDate).endOf("month").toDate();

  const dates = getDateRangeArray(startPeriod, endPeriod);
  let rawOvertime = 0;
  let rawVoluntaryOvertime = 0;

  dates.forEach((date) => {
    const result = countDailyHours({
      date: new Date(date),
      list: admissionData,
    });
    rawOvertime += result?.rawOvertime;
    rawVoluntaryOvertime += result?.rawVoluntaryOvertime;
  });

  const overtimeData = {
    allLabel: t("OVERTIME"),
    startDate: data?.startDate,
    endDate: data?.endDate,
    hoursByLaw: weeklyData ? 8 : 32,
    hoursByFact: rawOvertime / 60,
  };

  const voluntaryOvertimeData = {
    allLabel: t("VOLUNTARY_OVERTIME"),
    startDate: data?.startDate,
    endDate: data?.endDate,
    hoursByLaw: 12,
    hoursByFact: rawVoluntaryOvertime / 60,
  };

  const formatValue = (value) => {
    if (value % 1 !== 0) {
      const formattedValue = value?.toFixed(3);
      return formattedValue?.endsWith('00') ? parseFloat(formattedValue)?.toFixed(1) : formattedValue;
    } else {
      return value?.toString();
    }
  };

  const overtimeValue = formatValue(overtimeData?.hoursByFact);

  return (
    <Box>
      <MyTimeWorkingFrameBox
        label={t("VOLUNTARY_OVERTIME")}
        value={formatValue(voluntaryOvertimeData?.hoursByFact)}
        color={theme.palette.primary.main}
        loading={loading}
        overtimeValue={overtimeValue}
        typeName="frameOvertime"
        minHeight={minHeight}
      />
    </Box>
  );
};

export default MyTimeOvertimeFrame;
