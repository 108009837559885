import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/styles";
import { InlineIcon } from "@iconify/react";
import PDFIcon from "@iconify/icons-mdi/file-pdf-box-outline";
import DownloadIcon from "@iconify/icons-mdi/download";
import Link from "@material-ui/core/Link";
import showIcon from "@iconify/icons-mdi/show";

export default function OcFileViewerAndDownloaderBox({ label, href, modul }) {
  const theme = useTheme();

  const handleShowFile = () => {
    window.open(href, "_blank");
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        style={{
          height: modul === "cafeteria" ? "56px" : "72px",
          backgroundColor: theme.palette.gray20.main,
          border: `1px solid ${theme.palette.gray40.main}`,
          borderRadius: "8px",
        }}
      >
        <Box width="16px" />
        <InlineIcon icon={PDFIcon} width="24px" />
        <Box width="16px" />
        <Box flexGrow={1}>
          {label}
        </Box>
        <InlineIcon
          id="showIcon"
          icon={showIcon}
          width="24px"
          onClick={handleShowFile}
          style={{
            cursor: "pointer",
          }}
        />
        <Box width="8px" />
        <Link
          id={label.replace(/ /g, "_")}
          href={href}
          download
        >
          <Box
            display="flex"
          >
            <InlineIcon
              icon={DownloadIcon}
              width="24px"
              style={{ color: theme.palette.owGray.main }}
            />
          </Box>
        </Link>
        <Box width="16px" />
      </Box>
    </>
  );
}
