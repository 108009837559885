import React, { useRef, useState } from "react";
import OcCard from "oc/components/oc-card";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { DropzoneArea } from "material-ui-dropzone";
import useStyles from "./my-hr-user-profile-image.style";
import { InlineIcon } from "@iconify/react";
import imageIcon from "@iconify/icons-mdi/image-size-select-actual";
import Collapse from "@material-ui/core/Collapse";
import axios from "axios";
import useOcAvatar from "hooks/use-oc-avatar";
import Avatar from "@material-ui/core/Avatar";
import AvatarEditor from "react-avatar-editor";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Slider from "@material-ui/core/Slider";
import useOcSnackbar from "hooks/use-oc-snackbar";

let apiUrl = "http://localhost:9000/api/upload";

if (process.env.NODE_ENV === "production") {
  apiUrl = "/api/upload";
}

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const b64str = window.atob(arr[1]);
  let n = b64str.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = b64str.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export default function MyHrUserProfileImage({
  loading,
  disabledNewRequest,
  employeeCode,
}) {
  const { t } = useTranslation();

  const { data, refetch } = useOcAvatar({ employee: employeeCode });

  const [showImageEdit, setshowImageEdit] = useState(false);
  const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1);
  const [transformedFile, setTransformedFile] = useState(null);

  const classes = useStyles();
  const transformComponentRef = useRef(null);
  const editorRef = useRef(null);

  const { setOpenMessage } = useOcSnackbar();

  const handleImageChange = () => {
    if (!transformedFile) {
      setTransformedFile && setTransformedFile(image);
    } else if (editorRef.current) {
      const newImage = editorRef.current.getImageScaledToCanvas().toDataURL();
      const newImagefile = dataURLtoFile(newImage, image?.name || "");
      if (newImagefile.size !== transformedFile?.size) {
        setTransformedFile && setTransformedFile(newImagefile);
      }
    }
  };

  const handleShowNewItemDialog = () => {
    if (showImageEdit) {
      setshowImageEdit(false);
      setScale(1);
    } else {
      setshowImageEdit(true);
    }
  };

  const handleUpload = async (e) => {
    handleImageChange();

    const formData = new FormData();
    formData.append("employeeCode", employeeCode);
    formData.append("type", "avatar");

    if (scale === 1) {
      formData.append("file", image);
    } else if (scale > 1 && transformedFile) {
      formData.append("file", transformedFile);
    }

    try {
      await axios({
        method: "post",
        url: apiUrl,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      refetch();
      setImage(null);
      setTransformedFile(null);
      setOpenMessage({
        type: "success",
        message: t("PROFILE_IMAGE_UPLOAD_SUCCEEDED"),
      });
      handleShowNewItemDialog();
    } catch (error) {
      setOpenMessage({
        type: "error",
        message: t("PROFILE_IMAGE_UPLOAD_FAILED"),
      });
      console.log(error);
    }
  };

  const updateScale = (e, newScaleValue) => {
    const targetScale = parseFloat(newScaleValue);
    const factor = Math.log(targetScale / scale);
    const { zoomIn, zoomOut } = transformComponentRef.current;

    if (targetScale > scale) {
      zoomIn(factor, 0);
    } else {
      zoomOut(-factor, 0);
    }

    setScale(targetScale);
  };

  const cancelUpload = () => {
    setImage(null);
    setScale(1);
    handleShowNewItemDialog();
  };

  const handleSelectImage = (files) => {
    const selectedFile = files[0];
    if (selectedFile?.size > 8000000) {
      setOpenMessage({
        type: "error",
        message: t("PROFILE_IMAGE_UPLOAD_SIZE_FAILED"),
      });
      return;
    }
    setImage(selectedFile);
  };

  return (
    <OcCard label={t("PROFILE_IMAGE")}>
      <>
        <Box height="49px" />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Avatar
            id="avatar-img"
            alt="avatar-image"
            src={data}
            style={{
              width: "252px",
              height: "252px",
            }}
          />
        </Box>
        <Box height="42px" />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="16px 0px"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleShowNewItemDialog}
            disableElevation
            disabled={loading || disabledNewRequest}
          >
            {t("PROFILE_IMAGE_EDIT")}
          </Button>
          <Dialog
            open={showImageEdit}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                return false;
              }
              handleShowNewItemDialog();
            }}
            disableEscapeKeyDown
            scroll="body"
            PaperProps={{
              style: {
                padding: "0px 24px 24px 24px",
                width: 600,
              },
            }}
          >
            <div
              style={{
                paddingTop: 36,
                paddingLeft: 0,
                paddingRight: 0,
                textAlign: "center",
                fontSize: 24,
                fontFamily: "Mulish",
              }}
            >
              {t("IMAGE_UPLOAD")}
            </div>
            <DialogContent>
              <Box display="flex" flexDirection="row" alignItems="center" paddingBottom="10px"></Box>
            </DialogContent>
            <DialogActions>
              <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <DropzoneArea
                  classes={{
                    root: classes.root,
                    icon: classes.muiSvgIcon,
                    textContainer: classes.textContainer,
                    text: classes.text,
                  }}
                  dropzoneText={t("DRAG_AND_DROP")}
                  acceptedFiles={["image/*"]}
                  filesLimit={1}
                  maxFileSize={100000000}
                  showPreviewsInDropzone={false}
                  showFileNames={true}
                  showAlerts={false}
                  onChange={handleSelectImage}
                />
                <Box height="36px" />
                <Collapse in={image?.name.length > 0}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingButton: "16px",
                      background: "#666666",
                      borderRadius: "8px",
                    }}
                  >
                    <TransformWrapper
                      ref={transformComponentRef}
                      centerOnInit={true}
                      doubleClick={{ disabled: true }}
                      panning={{ disabled: true }}
                      minScale={1}
                      maxScale={1}
                    >
                      <TransformComponent>
                        <Box
                          style={{
                            maxHeight: "383px",
                            marginTop: "-17px",
                            marginLeft: "-17px",
                            marginRight: "-17px",
                          }}
                        >
                          <Box
                            style={{
                              border: "6px solid white",
                              borderRadius: "50%",
                              height: "354px",
                              width: "354px",
                              marginTop: "17px",
                              marginLeft: "17px",
                              position: "absolute",
                              pointerEvents: "none",
                            }}
                          ></Box>
                          <AvatarEditor
                            ref={editorRef}
                            image={image}
                            width={500}
                            scale={scale}
                            height={500}
                            color={[102, 102, 102, 0.6]}
                            borderRadius={300}
                            rotate={0}
                            style={{ maxHeight: "400px", maxWidth: "400px" }}
                            onImageChange={handleImageChange}
                          />
                        </Box>
                      </TransformComponent>
                    </TransformWrapper>
                  </Box>
                  <Box height="54px" />
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <Box>
                      <InlineIcon
                        icon={imageIcon}
                        width="24px"
                        style={{ color: "#4d4d4d", width: "16px", height: "13px", marginRight: "16px" }}
                      />
                    </Box>
                    <Slider
                      className={classes.input}
                      defaultValue={1}
                      min={1}
                      max={2.5}
                      step={0.01}
                      onChange={updateScale}
                    />
                    <Box>
                      <InlineIcon
                        icon={imageIcon}
                        width="24px"
                        style={{ color: "#4d4d4d", width: "44px", height: "36px", marginLeft: "16px" }}
                      />
                    </Box>
                  </Box>
                  <Box height="54px" />
                </Collapse>

                <Button fullWidth variant="contained" color="primary" disableElevation onClick={handleUpload} disabled={!image}>
                  {t("UPLOAD")}
                </Button>
                <Box height="43px" />
                <Button text={t("Common.cancel")} fullWidth onClick={cancelUpload}>
                  {t("Common.cancel")}
                </Button>
                <Box height="11px" />
              </div>
            </DialogActions>
          </Dialog>
        </Box>
        <Box height="28px" />
      </>
    </OcCard>
  );
}
