import { makeStyles } from "@material-ui/core/styles";

export const useErrorStyles = makeStyles((theme) => ({
  root: ({ height, width }) => ({
    borderRadius: "4px",
    border: `1px solid ${theme.palette.primary.main}`,
    height: height,
    width: width,
  }),
}));

const useStyles = makeStyles((theme) => ({
  root: ({ height, width }) => ({
    borderRadius: "4px",
    border: "1px solid #E3E5E5",
    padding: "36px",
    height: `calc(${height})-2*36px`,
    width: width,
  }),
  periodTitle: {
    color: "#5E6366",
    fontFamily: "Mulish",
    fontSize: "16px",
    lineHeight: "25px",
    letterSpacing: "0.44px",
  },
  period: {
    fontFamily: "Mulish",
    fontSize: "34px",
    letterSpacing: "0.25px",
    color: "#5E6366",
    lineHeight: "42px",
  },
  divider: {
    padding: "16px 8px 0px 8px",
    borderBottom: "1px solid #E3E5E5",
    marginBottom: "32px",
  },
  skeleton: {
    marginTop: "-8px",
  },
}));

export default useStyles;
