import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import Collapse from "@material-ui/core/Collapse";
import { InlineIcon } from "@iconify/react";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import accountIcon from "@iconify/icons-mdi/account";
import myLeaveIcon from "@iconify/icons-mdi/bag-personal";
import myCafeteriaIcon from "@iconify/icons-mdi/coffee";
import RoleChip from "my-hr/components/my-hr-role-chip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import OcCompanyFilter from "../oc-company-filter";
import OcOrgUnitFilter from "oc/components/oc-org-unit-filter";
import OcWorkingPlaceFilter from "oc/components/oc-working-place-filter";
import OcJobClassGroupFilter from "oc/components/oc-job-class-group-filter";
import OcPayofficeFilter from "oc/components/oc-payoffice-filter";
import OcEmployeeFilter from "oc/components/oc-employee-filter";
import { useResetRecoilState } from "recoil";
import { roleFilteringState } from "states";
import MyLeaveFilterDivider from "my-leave/components/my-leave-filter-divider";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";

function getProductIcon(product) {
  let icon = null;
  switch (product) {
    case "MyLeave":
      icon = myLeaveIcon;
      break;
    case "MyCafeteria":
      icon = myCafeteriaIcon;
      break;
    default:
      icon = accountIcon;
      break;
  }
  return icon;
}

export default function OcRoleFiltering({
  showRoleFiltering,
  setShowRoleFiltering,
  onAddRole,
  product,
  role,
  roleFiltering,
  onAddRoleFiltering,
  onRemoveRoleFiltering,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [showCollapse, setShowCollapse] = useState(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  let isMyHRAdmin = product === "MyHR" && role === "admin";
  let isUser = role === "user";

  const resetRoleFiltering = useResetRecoilState(roleFilteringState);

  let showCollapseButton = true;

  if (role === "user") {
    showCollapseButton = false;
  }

  const handleExpandClick = () => {
    setShowCollapse(!showCollapse);
  };

  function handleCloseCollapse() {
    setShowRoleFiltering(false);
  }

  function handleCancel() {
    resetRoleFiltering();
    handleCloseCollapse();
  }

  function handleAddRole() {
    const isEmptyFilters = Object.values(roleFiltering).every(
      (filter) => Array.isArray(filter) && filter.length === 0
    );

    if (
      ['examiner', 'viewer', 'uploader'].includes(role) &&
      isEmptyFilters
    ) {
      setShowConfirmDialog(true);
      return;
    }
    onAddRole();
  }

  function handleDialogConfirm() {
    setShowConfirmDialog(false);
    onAddRole();
  }

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <Collapse in={showRoleFiltering} timeout="auto" unmountOnExit>
      <Box display="flex" flexDirection="column" alignItems="center" paddingTop="24px">
        <Box
          width="696px"
          style={{
            backgroundColor: theme.palette.gray20.main,
            borderRadius: "4px",
          }}
        >
          <Box
            height="48px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            padding="0px 24px"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <InlineIcon
                icon={getProductIcon(product)}
                height="32px"
                style={{
                  color: theme.palette.gray80.main,
                  marginRight: "24px",
                }}
              />
              <Typography variant="h5">{product}</Typography>
              <Box marginLeft="16px">
                <RoleChip role={role} />
              </Box>
            </Box>
            {showCollapseButton && (
              <IconButton
                id={role}
                onClick={handleExpandClick}
                aria-expanded={showCollapse}
                aria-label="show more"
                style={
                  showCollapse
                    ? {
                      padding: 0,
                      transform: "rotate(180deg)",
                    }
                    : { padding: 0 }
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
          </Box>
          {isUser || isMyHRAdmin ? (
            <Box padding="0px 16px">
              <Box
                height="88px"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{
                  borderTop: `2px solid ${theme.palette.gray40.main}`,
                  padding: "16px 0px",
                }}
              >
                <Button
                  id="oc-role-filtering-cancel-button"
                  color="primary"
                  onClick={handleCancel}
                >
                  {t("DELETE")}
                </Button>
                <Button
                  id="oc-role-filtering-save-button"
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{ marginLeft: 16 }}
                  onClick={handleAddRole}
                >
                  {t("SAVE")}
                </Button>
              </Box>
            </Box>
          ) : (
            <Collapse in={showCollapse} timeout="auto" unmountOnExit>
              <Box padding="0px 16px">
                <Box
                  style={{
                    borderTop: `2px solid ${theme.palette.gray40.main}`,
                    padding: "16px 0px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <OcCompanyFilter
                        roleFilters={roleFiltering}
                        onAddFilter={onAddRoleFiltering}
                        onRemoveFilter={onRemoveRoleFiltering}
                        product={role}
                        role={product}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <OcOrgUnitFilter
                        roleFilters={roleFiltering}
                        onAddFilter={onAddRoleFiltering}
                        onRemoveFilter={onRemoveRoleFiltering}
                        product={role}
                        role={product}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <OcWorkingPlaceFilter
                        roleFilters={roleFiltering}
                        onAddFilter={onAddRoleFiltering}
                        onRemoveFilter={onRemoveRoleFiltering}
                        product={role}
                        role={product}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <OcJobClassGroupFilter
                        roleFilters={roleFiltering}
                        onAddFilter={onAddRoleFiltering}
                        onRemoveFilter={onRemoveRoleFiltering}
                        product={role}
                        role={product}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <OcPayofficeFilter
                        roleFilters={roleFiltering}
                        onAddFilter={onAddRoleFiltering}
                        onRemoveFilter={onRemoveRoleFiltering}
                        product={role}
                        role={product}
                      />
                    </Grid>
                  </Grid>
                  <Box height="16px" />
                  <MyLeaveFilterDivider />
                  <Box height="16px" />
                  <Grid item xs={6} style={{ paddingRight: "8px" }}>
                    <OcEmployeeFilter
                      roleFilters={roleFiltering}
                      onAddFilter={onAddRoleFiltering}
                      onRemoveFilter={onRemoveRoleFiltering}
                      product={role}
                      role={product}
                    />
                  </Grid>
                  <Box
                    height="88px"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      id="oc-role-filtering-cancel-button"
                      color="primary"
                      onClick={handleCancel}
                    >
                      {t("DELETE")}
                    </Button>
                    <Button
                      id="oc-role-filtering-save-button"
                      variant="contained"
                      color="primary"
                      disableElevation
                      style={{ marginLeft: 16 }}
                      onClick={handleAddRole}
                    >
                      {t("SAVE")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Collapse>
          )}
        </Box>
      </Box>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleDialogConfirm}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("ADD_ALL_PERMISSIONS_DIALOG_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
    </Collapse>
  );
}
