import React, { createRef, useLayoutEffect, useState, useEffect } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom";
import StickyPersonalDataHeader from "my-hr/components/my-hr-sticky-personal-data-header";
import UserContractsCard from "my-hr/components/my-hr-user-contract-card";
import useContract from "hooks/use-myhr-contract";
import useEmployee from "hooks/use-myhr-employee";
import useClientState from "hooks/use-oc-client-state";
import Card from "@material-ui/core/Card";
import LeaveCardHeader from "./components/leave-card-header";
import useLeaveRequest from "hooks/use-my-leave-leave-request";
import useIsExaminerContract from "hooks/use-is-examiner-contract";
import { useTranslation } from "react-i18next";
import LeaveCardInitialMessageBox from "./components/leave-card-initial-message-box";
import LeaveCardMessageBox from "./components/leave-card-message-box";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { APPROVE_LEAVE_REQUEST, REJECT_LEAVE_REQUEST } from "graphql/mutations";
import moment from "moment";
import AllLeavesWithCoverage from "my-leave/components/my-leave-all-leaves-with-coverage-card";
import InfoDialog from "my-leave/components/my-leave-info-dialog";
import OcCard from "oc/components/oc-card";
import { GET_LEAVE_REQUESTS_BY_CONTRACT } from "graphql/queries";
import { LEAVE_REQUESTS_SUBSCRIPTION } from "graphql/subcriptions";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import useCurrentUserState from "hooks/use-current-user-state";
import Collapse from "@material-ui/core/Collapse";
import LeaveBaseCard from "my-leave/components/my-leave-leave-base-card";
import LeaveCovidplustenCard from "my-leave/components/my-leave-leave-covid-plus-ten-card";
import LeaveEszjtvCard from "my-leave/components/my-leave-leave-eszjtv-card";
import LeaveStudyCard from "my-leave/components/my-leave-leave-study-card";
import usePolling from "hooks/use-polling";
import LeaveEmergencyCard from "my-leave/components/my-leave-leave-emergency-card";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentDateState, scrollTriggerState, roleFiltersState } from "states";
import useLeaveEvents from "hooks/use-my-leave-events";
import useExaminersName from "hooks/use-examiners-name";
import { Typography } from "@material-ui/core";
import { Link } from "@material-ui/core";
import useOcSnackbar from "hooks/use-oc-snackbar";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";
import { useTheme } from "@material-ui/styles";

export default function UserRequest() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  let { product, role, contract, id } = useParams();

  let { contractCode } = useCurrentUserState();

  const { data: examinersData } = useExaminersName({ locale: i18n.language });

  let now = new Date();

  const { data, error, refetch } = useQuery(GET_LEAVE_REQUESTS_BY_CONTRACT, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    skip: !contract,
    variables: { contract: contract, year: now.getFullYear().toString() },
  });

  if (error) {
    console.log({
      component: "MyLeaveRequests",
      errorDescription:
        "Error occured when fetch data with GET_LEAVE_REQUESTS_BY_CONTRACT query",
      error: error,
    });
  }

  const { error: subscriptionError } = useSubscription(
    LEAVE_REQUESTS_SUBSCRIPTION,
    {
      onData: (data) => {
        refetch();
      },
    }
  );

  if (subscriptionError) {
    console.log({
      component: "MyLeaveRequests",
      errorDescription:
        "Error occured when subscribe data with LEAVE_REQUESTS_SUBSCRIPTION query",
      error: error,
    });
  }

  let resultData = data?.leaveRequestsByContract || [];
  let requests = resultData.filter(
    (item) => item.type === "leave" && item.state === "requested"
  );

  const [showInfoDialog, setShowInfoDialog] = useClientState(
    "showInfoDialog",
    false
  );

  const [currentDate, setCurrentDate] = useRecoilState(currentDateState);

  const [loading, setLoading] = useState(false);

  const { data: contractData } = useContract({
    contract,
  });

  let employeeId = contractData?.employee?.code;
  const { data: employeeData } = useEmployee({
    employeeId,
  });

  const { leaveRequest, leaveRequestLoading } = useLeaveRequest({
    _id: id,
  });

  /** jump to leave request start date on coverage calendar */
  useEffect(() => {
    if (currentDate && leaveRequest) {
      let startDateString = moment(leaveRequest?.startDate).format(
        "YYYY-MM-DD"
      );
      let currentDateString = moment(currentDate).format("YYYY-MM-DD");
      if (currentDateString !== startDateString) {
        setCurrentDate(new Date(startDateString));
      }
    }
    // eslint-disable-next-line
  }, []);

  const pageRef = createRef();
  const [scrollTrigger, setScrollTrigger] = useRecoilState(scrollTriggerState);

  function handleScroll() {
    let scrollTriggerPosition = 100;
    let scrollTop = document?.scrollingElement?.scrollTop;
    if (scrollTop >= scrollTriggerPosition) {
      setScrollTrigger("show");
    } else if (scrollTop < scrollTriggerPosition) {
      setScrollTrigger("hide");
    }
  }

  const [pageWidth, setPageWidth] = useClientState("pageWidth", 0);

  useLayoutEffect(() => {
    let width = pageRef?.current?.clientWidth;
    if (width && width !== pageWidth) {
      setPageWidth(width);
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line
  }, []);

  const [message, setMessage] = useClientState("newMessageText", "");

  const { setOpenMessage } = useOcSnackbar();

  const [approveLeaveRequest] = useMutation(APPROVE_LEAVE_REQUEST, {
    onCompleted: (data) => {
      let contract = leaveRequest?.contract;
      setLoading(false);
      setMessage("");
      setOpenMessage({
        type: "success",
        message: t("APPROVE_LEAVE_REQUEST_SUCCEEDED"),
      });
      let uri = `/my-leave/user-requests/${product}/${role}/${contract}`;
      if (requests.length === 0) {
        uri = "/my-leave/approve-requests";
      }
      history.push(uri);
    },
    onError: (error) => {
      setLoading(false);
      setOpenMessage({
        type: "error",
        message: error?.message
          ? error?.message
          : t("APPROVE_LEAVE_REQUEST_FAILED"),
      });
    },
  });

  const [rejectLeaveRequest] = useMutation(REJECT_LEAVE_REQUEST, {
    onCompleted: (data) => {
      setLoading(false);
      setMessage("");
      setOpenMessage({
        type: "success",
        message: t("REJECT_LEAVE_REQUEST_SUCCEEDED"),
      });
      let uri = `/my-leave/user-requests/${product}/${role}/${contract}`;
      if (requests.length === 0) {
        uri = "/my-leave/approve-requests";
      }
      history.push(uri);
    },
    onError: (error) => {
      setOpenMessage({
        type: "error",
        message: error?.message
          ? error?.message
          : t("REJECT_LEAVE_REQUEST_FAILED"),
      });
    },
  });

  function handleCancel() {
    let contract = leaveRequest?.contract;
    let uri = `/my-leave/user-requests/${product}/${role}/${contract}`;
    history.push(uri);
  }

  let locale = i18n.language;

  function handleReject() {
    if (!message) {
      setShowInfoDialog(true);
      return;
    }
    let _id = leaveRequest?._id;
    if (_id) {
      setLoading(true);
      rejectLeaveRequest({ variables: { _id, message, locale } });
    }
  }

  function handleApprove() {
    let _id = leaveRequest?._id;
    if (_id) {
      setLoading(true);
      approveLeaveRequest({ variables: { _id, message, locale } });
    }
  }

  let { data: isExaminerContract } = useIsExaminerContract({
    contract: leaveRequest?.contract,
  });

  let isExaminer = isExaminerContract || false;

  let disabled = false;

  const endDate = moment().subtract(1, "month").startOf("month").toDate();
  if (
    new Date(leaveRequest?.endDate) < endDate &&
    leaveRequest?.state === "requested"
  ) {
    disabled = true;
  }

  if (leaveRequest?._id?.startsWith("jd-")) {
    disabled = true;
  }

  if (loading) {
    disabled = true;
  }

  let currentMonth = new Date().getMonth();
  let startOfPeriod = moment().startOf("year");

  if (currentMonth === 0) {
    startOfPeriod = moment().startOf("year").subtract(1, "month");
  }

  if (moment(leaveRequest?.startDate).isBefore(startOfPeriod)) {
    disabled = true;
  }

  if (!isExaminer) {
    disabled = true;
  }

  if (leaveRequest?.state === "rejected") {
    disabled = true;
  }

  if (contractCode === leaveRequest?.contract && examinersData?.length !== 0) {
    disabled = true;
  }

  let initialMessage =
    leaveRequest?.messages?.length > 0 &&
      leaveRequest?.messages[0].createdBy === leaveRequest?.createdBy
      ? leaveRequest?.messages[0].message
      : "";

  function handleCurrentDateChange(date) {
    setCurrentDate(date);
  }

  const roleFilters = useRecoilValue(
    roleFiltersState(contract + product + role)
  );

  function handleEventSelected(event) {
    let { _id, contract } = event;
    let url = `/my-leave/user-request/${contract}/${_id}`;
    history.push(url);
  }

  function handleCloseInfoDialog() {
    setShowInfoDialog(false);
  }

  let currentYear = leaveRequest
    ? new Date(leaveRequest?.startDate).getFullYear().toString()
    : new Date().getFullYear().toString();

  let showNewMessageBox = leaveRequest?.state !== "recorded";

  let viewedByAdmin = role === "viewer" ? true : false;

  let leaveCardTitle = "";

  switch (leaveRequest?.leaveType) {
    case "S":
      leaveCardTitle = "Leave.leaveBase";
      break;
    case "T":
      leaveCardTitle = "Leave.leaveStudy";
      break;
    case "D":
      leaveCardTitle = "Leave.leaveEszjtv";
      break;
    case "Q":
      leaveCardTitle = "Leave.leaveCovidPlus10";
      break;
    default:
    // TODO implement default leave error message
  }

  const {
    loadEvents,
    data: leavesData,
    loading: leaveEventsLoading,
    eventsByDays,
  } = useLeaveEvents({
    roleFilters: roleFilters,
    currentDate: moment(currentDate).toDate(),
    viewedByAdmin: viewedByAdmin,
    contract: contract,
  });

  useEffect(() => {
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  if (!leaveRequest && !leaveRequestLoading) {
    return (
      <Box>
        <StickyPersonalDataHeader
          pageWidth={pageWidth}
          employee={employeeData}
          contract={contractData}
          trigger={scrollTrigger === "show" ? true : false}
        />
        <UserContractsCard employee={employeeData} contract={contractData} />
        <Box height="52px" />
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h4">
            {t("REQUEST_HAS_BEEN_WITHDRAWN")}
          </Typography>
          <Box height="32px" />
          <Link
            href="#/my-leave/approve-requests"
            rel="noreferrer"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <Typography variant="body1" style={{ color: "#CC0033" }}>
              {t("TO_APPLICATIONS")}
            </Typography>
          </Link>
        </Box>
      </Box>
    );
  }

  return (
    <OcPageTransition>
      <Box ref={pageRef}>
        <StickyPersonalDataHeader
          pageWidth={pageWidth}
          employee={employeeData}
          contract={contractData}
          trigger={scrollTrigger === "show" ? true : false}
        />
        <UserContractsCard employee={employeeData} contract={contractData} />
        <Box padding="16px">
          <Box display="flex" flexDirection="row">
            <Box width="600px">
              <Card
                elevation={0}
                style={{
                  borderRadius: "8px",
                  padding: "36px 24px"
                }}
              >
                <Box style={{
                  textAlign: "center",
                  fontSize: 34,
                  fontWeight: 400,
                  letterSpacing: 0.25,
                }}
                >
                  {t("REQUEST")}
                </Box>
                <Box height="24px" />
                <Box style={{
                  height: "1px",
                  backgroundColor: theme.palette.gray30.main,
                }} />
                <Box height="24px" />
                <LeaveCardHeader
                  employee={employeeData}
                  leaveRequest={leaveRequest}
                  loading={leaveRequestLoading && !leaveRequest}
                />
                {showNewMessageBox && initialMessage?.length > 0 && (
                  <LeaveCardInitialMessageBox
                    initialMessage={initialMessage}
                    triangle={true}
                  />
                )}
                <Box height="24px" />
                {showNewMessageBox && (
                  <LeaveCardMessageBox
                    leaveRequest={leaveRequest}
                    loading={leaveRequestLoading && !leaveRequest}
                    initialMessage={initialMessage}
                    disabled={disabled}
                  />
                )}
                <OcCardButtonBar
                  leftButtonText={t("REJECT")}
                  leftButtonColor={leaveRequest?.state !== "rejected" && "primary"}
                  leftButtonDisabled={disabled}
                  handleLeftButton={handleReject}
                  leftButtonId="myleave-user-request-page-leave-reject"
                  rightButtonText={t("CANCEL")}
                  handleRightButton={handleCancel}
                  rightButtonId="myleave-user-request-page-leave-cancel"
                  handlePrimaryButton={handleApprove}
                  primaryButtonText={leaveRequest?.state !== "approved" && t("APPROVE")}
                  primaryButtonDisabled={disabled}
                  primaryButtonId="myleave-user-request-page-leave-approve"
                />
              </Card>
            </Box>
            <Box width="16px" />
            {leaveRequest?.type === "leave" && (
              <Box width="600px">
                <OcCard label={t(leaveCardTitle)}>
                  <>
                    <Collapse
                      in={leaveRequest?.leaveType === "S"}
                      timeout="auto"
                      unmountOnExit
                    >
                      <LeaveBaseCard
                        selectedYear={currentYear}
                        contract={contract}
                      />
                    </Collapse>
                    <Collapse
                      in={leaveRequest?.leaveType === "Q"}
                      timeout="auto"
                      unmountOnExit
                    >
                      <LeaveCovidplustenCard
                        selectedYear={currentYear}
                        contract={contract}
                      />
                    </Collapse>
                    <Collapse
                      in={leaveRequest?.leaveType === "D"}
                      timeout="auto"
                      unmountOnExit
                    >
                      <LeaveEszjtvCard
                        selectedYear={currentYear}
                        contract={contract}
                      />
                    </Collapse>
                    <Collapse
                      in={leaveRequest?.leaveType === "T"}
                      timeout="auto"
                      unmountOnExit
                    >
                      <LeaveStudyCard
                        selectedYear={currentYear}
                        contract={contract}
                      />
                    </Collapse>
                    <Collapse
                      in={leaveRequest?.leaveType === "V"}
                      timeout="auto"
                      unmountOnExit
                    >
                      <LeaveEmergencyCard
                        selectedYear={currentYear}
                        contract={contract}
                      />
                    </Collapse>
                  </>
                </OcCard>
              </Box>
            )}
          </Box>
          <Box height="16px" />
          <AllLeavesWithCoverage
            cardTitle={t("LEAVES_AND_REMOTE_WORKS")}
            currentDate={currentDate}
            onCurrentDateChange={handleCurrentDateChange}
            monthFiltering={false}
            roleFilters={roleFilters}
            onEventSelected={handleEventSelected}
            viewedByAdmin={viewedByAdmin}
            leavesData={leavesData}
            loading={leaveEventsLoading}
            eventsByDays={eventsByDays}
          />
        </Box>
        <InfoDialog
          showDialog={showInfoDialog}
          closeDialog={handleCloseInfoDialog}
          title={
            leaveRequest?.type === "leave"
              ? t("REJECT_LEAVE_REQUEST")
              : t("REJECT_REMOTE_WORK_REQUEST")
          }
          children={t("REJECT_LEAVE_REQUEST_TEXT")}
          buttonLabel={t("ALL_RIGHT")}
        />
      </Box>
    </OcPageTransition>
  );
}
