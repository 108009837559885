import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./my-hr-menu-badges.style";
import useCurrentUserState from "hooks/use-current-user-state";
import useChangeRequestsBadges from "hooks/use-change-requests-badges";

function MyHrMenuBadges({ value, urgent }) {
  const classes = useStyles();
  if (!value) return null;
  return (
    <Box width="16px" height="16px" className={classes.root}>
      <Box
        id="myHrMenuBadge"
        fontSize="10px"
        fontFamily="Roboto"
        lineHeight="16px"
        color={urgent ? "#cc0033" : "#212121"}
        fontWeight="bold"
        textAlign="center"
        width={value > 99 ? "20px" : "unset"}
      >
        {value > 99 ? "99+" : value}
      </Box>
    </Box>
  );
}

export default function MenuBadges({ urgent }) {
  let { contractCode } = useCurrentUserState();
  const { data: badgeCount } = useChangeRequestsBadges(contractCode);
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <MyHrMenuBadges value={badgeCount} urgent={true} />
    </Box>
  );
}
