import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import useStyles from "./my-leave-state.style";

export const LeaveState = ({ label, state, loading, variant }) => {
  const theme = useTheme();
  const [borderColor, setBorderColor] = useState("transparent");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getColor(state) {
    switch (state) {
      case "requested":
        return theme.palette.yellowBase.main;
      case "approved":
        return theme.palette.greenBase.main;
      case "rejected":
        return theme.palette.primary.main;
      case "recorded":
        return theme.palette.greenBase.main;
      default:
        return theme.palette.white.main;
    }
  }

  useEffect(() => {
    if (state !== undefined) {
      setBorderColor(getColor(state));
    }
  }, [getColor, state, theme]);

  const classes = useStyles({ borderColor });

  return (
    <div className={classes.root}>
      <Typography
        className={loading ? classes.textLoading : classes.text}
        variant={variant}
      >
        {label}
      </Typography>
    </div>
  );
};

export default LeaveState;
