import React from "react";
import useMyLeaveAdminBadges from "hooks/use-my-leave-admin-badges";
import Box from "@material-ui/core/Box";
import useStyles from "./my-leave-menu-admin-badges.style";

function MyLeaveAdminMenuBadges({ value, urgent }) {
  const classes = useStyles();
  if (!value) return null;
  return (
    <Box width="16px" height="16px" className={classes.root}>
      <Box
        id="myLeaveAdminMenuBadge"
        fontSize="10px"
        fontFamily="Roboto"
        lineHeight="16px"
        color={urgent ? "#cc0033" : "#212121"}
        fontWeight="bold"
        textAlign="center"
      >
        {value}
      </Box>
    </Box>
  );
}

export default function MenuAdminBadges({ urgent }) {
  const { data } = useMyLeaveAdminBadges();
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <MyLeaveAdminMenuBadges value={data} urgent={true} />
    </Box>
  );
}
