import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_OPEN_REQUEST_LIST } from "my-time/graphql/queries";
import { MY_TIME_REQUESTS_SUBSCRIPTION } from "my-time/graphql/subscriptions";

const useMyTimeRequests = ({ startDate, endDate }) => {
  const { data, loading, error, refetch } = useQuery(GET_OPEN_REQUEST_LIST, {
    fetchPolicy: "cache-and-network",
    variables: {
      startDate,
      endDate,
    },
  });

  useSubscription(MY_TIME_REQUESTS_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  return {
    data: data?.getOpenRequestsList || [],
    loading,
    error,
    refetch
  };
};

export default useMyTimeRequests;
