import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Fade from "@material-ui/core/Fade";
import useStyles from "./oc-private-layout-sidebar-group.style";

export default function OcPrivateLayoutSidebarGroup({
  id,
  label,
  translation,
  narrowMenuOpened,
  narrowMenu,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  let menuLabel = t(translation) || label;

  return (
    <Box
      id={id}
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={"flex-start"}
      height="48px"
      paddingLeft={"18px"}
      position="relative"
    >
      <Fade in={!narrowMenu || narrowMenuOpened}>
        <Box className={classes.span}>{menuLabel}</Box>
      </Fade>
      <Fade in={narrowMenu && !narrowMenuOpened}>
        <Box
          style={{
            position: "absolute",
            width: "24px",
            height: "2px",
            backgroundColor: "#A6ADAF",
            top: 22,
            left: 16,
          }}
        />
      </Fade>
    </Box>
  );
}
