import React from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import useSelectedTaxDeclaration from "hooks/use-selected-tax-declaration";
import useTaxApplications from "hooks/use-tax-applications";
import { useTheme } from "@material-ui/styles";

export default function TaxCreditApplicationButtonBars({
  cancelData,
  handlePrint,
  taxApplicationSaveEnabled,
  saveData = () => { },
  editable,
  disabledButton,
  handleRemoveRequest,
  showModifiedButton = false,
  showRemoveButton = false,
  isModified,
  isModifiedDeclaration
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { resetTaxApplications } = useTaxApplications();

  const {
    selected,
    setSelected,
    reset: resetSelectedState,
  } = useSelectedTaxDeclaration();

  const handleModifiedButtonClick = () => {
    resetTaxApplications();
    const newState = { ...selected, editable: true, isModified: true };
    setSelected(newState);
  };

  const handleCancel = () => {
    resetTaxApplications();
    cancelData();
    resetSelectedState();
  };

  const handleSaveData = () => {
    resetTaxApplications();
    saveData();
  };

  return (
    <Box>
      <Box
        style={{
          backgroundColor: theme.palette.white.main,
          padding: "10px 16px",
          display: "flex",
          justifyContent: "space-between",
          border: `1px solid ${theme.palette.gray40.main}`,
          margin: "5px -24px -21px -24px "
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
        >
          {showRemoveButton && (
            <>
              <Button
                id="remove-button"
                onClick={handleRemoveRequest}
                disableElevation
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                {t("Leave.removeRequest")}
              </Button>
            </>
          )}
          {showModifiedButton && !editable && (
            <>
              <Button
                id="show-modified-button"
                onClick={handleModifiedButtonClick}
                disableElevation
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                {t("MODIFIED_DECLARATION")}
              </Button>
            </>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            id="cancel-button"
            //disabled={!editable || !selected?.editable}
            onClick={handleCancel}
          >
            {t("CANCEL")}
          </Button>
          <Box width="16px" />
          {taxApplicationSaveEnabled && !isModifiedDeclaration && !isModified && (
            <Button
              variant="contained"
              color="primary"
              id="save-button"
              onClick={handleSaveData}
              disabled={disabledButton}
            >
              {t("SAVE")}
            </Button>
          )}
          {(!editable || isModified || isModifiedDeclaration || !taxApplicationSaveEnabled) && (
            <Button
              id="print-button"
              onClick={handlePrint}
              variant="contained"
              color="primary"
              disableElevation
            >
              {t("PRINT")}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
