import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";
import usePasswordInput from "hooks/use-oc-password-input";
import OcPasswordInputField from "oc/components/oc-password-input-field";
import Loading from "oc/components/oc-loading";
import useProperty from "hooks/use-oc-property";
import useCurrentUserState from "hooks/use-current-user-state";
import { isTemporaryPasswordState } from "states";
import { useRecoilState } from "recoil";
import useEmployee from "hooks/use-myhr-employee";
import TextField from "@material-ui/core/TextField";
import useOcSnackbar from "hooks/use-oc-snackbar";

const CHANGE_PASSWORD = gql`
  mutation createNewPassword($email: String!, $password: String!) {
    createNewPassword(email: $email, password: $password) {
      status
      statusMessage
    }
  }
`;

export default function MyHrChangePasswordForm({ onLogout }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: passwordPolicy } = useProperty({
    resource: "system",
    key: "passwordPolicy",
  });

  const { setOpenMessage } = useOcSnackbar();

  const [loading, setLoading] = useState(false);

  let { employeeCode } = useCurrentUserState();
  const { data: employeeData } = useEmployee({ employeeId: employeeCode });

  const taxNumber = employeeData?.taxNumber.trim() || "";

  const [correctTaxNumber, setCorrectTaxNumber] = useState("");
  const [taxNumberError, setTaxNumberError] = useState(false);

  const {
    value: password,
    setValue: setPassword,
    isValid: isPasswordValid,
    validate: validatePassword,
    error: passwordError,
    setError: setPasswordError,
    helperText: passwordHelperText,
    setHelperText: setPasswordHelperText,
  } = usePasswordInput();

  const {
    value: passwordAgain,
    setValue: setPasswordAgain,
    isValid: isPasswordAgainValid,
    validate: validatePasswordAgain,
    error: passwordAgainError,
    setError: setPasswordAgainError,
    helperText: passwordAgainHelperText,
    setHelperText: setPasswordAgainHelperText,
  } = usePasswordInput();

  const [temporaryPasswordState, setIsTemporaryPassword] = useRecoilState(
    isTemporaryPasswordState
  );

  let { emailAddress } = useCurrentUserState();

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: (data) => {
      if (data?.createNewPassword?.status === "ok" && taxNumberError !== true) {
        setIsTemporaryPassword(false);
        setLoading(false);
        setOpenMessage({
          type: "success",
          message: t("PASSWORD_CHANGE_SUCCEEDED"),
        });
      }
      setLoading(false);
    },
    onError: (error) => {
      console.log(error);
      setLoading(false);
      setOpenMessage({
        type: "error",
        message: t("ERROR_MESSAGE_PASSWORD_IS_WRONG"),
      });
    },
  });

  useEffect(() => {
    if (temporaryPasswordState === false) {
      history.push("/");
    }
  });

  function handleSubmit(e) {
    e.preventDefault();

    if (!isPasswordValid({ checkPasswordPolicy: true })) {
      validatePassword({ checkPasswordPolicy: true });
    }

    if (!isPasswordAgainValid({ checkPasswordPolicy: true })) {
      validatePasswordAgain({ checkPasswordPolicy: true });
    }

    if (correctTaxNumber !== taxNumber) {
      setTaxNumberError(true);
      return;
    }

    if (password !== passwordAgain) {
      setPasswordError(true);
      setPasswordHelperText(t("ERROR_MESSAGE_TWO_PASSWORD_IS_DIFFERENT"));
      setPasswordAgainError(true);
      setPasswordAgainHelperText(t("ERROR_MESSAGE_TWO_PASSWORD_IS_DIFFERENT"));
      return;
    }
    if (
      !isPasswordValid({ checkPasswordPolicy: true }) ||
      !isPasswordAgainValid({ checkPasswordPolicy: true })
    )
      return;
    setLoading(true);
    changePassword({
      variables: {
        email: emailAddress,
        password,
      },
    });
  }

  function handleLogout() {
    onLogout();
  }

  return (
    <React.Fragment>
      <Loading show={loading} />
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Box
          paddingTop="32px"
          fontSize="12px"
          letterSpacing="0.4px"
          color="#4d4d4d"
        >
          {passwordPolicy === "strong"
            ? t("INFO_TEXT_STRONG_PASSWORD")
            : t("INFO_TEXT_NOT_STRONG_PASSWORD")}
        </Box>
        <Box paddingTop="36px" paddingBottom="14px">
          <OcPasswordInputField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordError}
            setError={setPasswordError}
            helperText={passwordHelperText}
            setHelperText={setPasswordHelperText}
            label={t("NEW_PASSWORD")}
          />
        </Box>
        <Box paddingTop="18px" paddingBottom="36px">
          <OcPasswordInputField
            value={passwordAgain}
            onChange={(e) => setPasswordAgain(e.target.value)}
            error={passwordAgainError}
            setError={setPasswordAgainError}
            helperText={passwordAgainHelperText}
            setHelperText={setPasswordAgainHelperText}
            label={t("CONFIRM_NEW_PASSWORD")}
          />
        </Box>
        <TextField
          id="correctTaxNumber"
          value={correctTaxNumber}
          variant="outlined"
          fullWidth
          label={t("TAX_NUMBER")}
          type="string"
          error={taxNumberError}
          helperText={
            taxNumberError && t("CHANGE_TEMPORARY_PASSWORD_TAX_NUMBER_ERROR")
          }
          onFocus={() => {
            setTaxNumberError(false);
          }}
          onChange={(e) => setCorrectTaxNumber(e.target.value)}
        />
        <Box height="36px" />
        <Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
          >
            {t("SAVE")}
          </Button>
        </Box>
        <Box paddingTop="36px">
          <Button
            variant="text"
            fullWidth
            disableElevation
            onClick={handleLogout}
          >
            {t("CANCEL")}
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
}
