import { useQuery } from "@apollo/react-hooks";
import { GET_MONTHLY_WORK_TIME_BALANCE } from "my-time/graphql/queries";

const useMonthlyWorkTimeBalance = ({ contractCode, year, month }) => {
  const { data, loading, error } = useQuery(GET_MONTHLY_WORK_TIME_BALANCE, {
    fetchPolicy: "cache-and-network",
    variables: {
      contractCode,
      year,
      month,
    },
  });

  return {
    data: data?.getMonthlyWorkTimeBalance || {},
    loading,
    error,
  };
};

export default useMonthlyWorkTimeBalance;
