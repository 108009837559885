import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { InlineIcon } from "@iconify/react";
import emailIcon from "@iconify/icons-mdi/email";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function OcEmailInputField({
  value,
  setValue,
  error,
  setError,
  helperText,
  setHelperText,
  label
}) {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setValue("");
      setError(false);
      setHelperText("");
    };
    // eslint-disable-next-line
  }, [setValue, setError, setHelperText]);

  function handleChange(e) {
    const pointer = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = pointer;
      element.selectionEnd = pointer;
    });
    setValue(e.target.value);
  }

  return (
    <TextField
      id="email-input-field"
      label={label ? label : t("EMAIL")}
      variant="outlined"
      fullWidth
      value={value}
      onChange={handleChange}
      error={error}
      helperText={error && helperText}
      onFocus={() => {
        setError(false);
        setHelperText("");
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" height="24px">
            <InlineIcon icon={emailIcon} height="24px" />
          </InputAdornment>
        ),
      }}
    />
  );
}
