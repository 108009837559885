import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import {
  mainMenu,
  settingsSubmenu,
  mainMenuChangeRequestsEnabled,
  mainMenuChangeRequestsDisabled,
  mainMenuMyDocumentsViewer,
  mainMenuMyDocumentsUploader,
  mainMenuMyDocumentsCollapse,
} from "./config";
import Selector from "oc/components/oc-private-layout-sidebar-selector";
import Slide from "@material-ui/core/Slide";
import { InlineIcon } from "@iconify/react";
import backburgerIcon from "@iconify/icons-mdi/backburger";
import { Transition } from "react-transition-group";
import PartnerLogo from "oc/components/oc-private-layout-sidebar-partner-logo";
import Fade from "@material-ui/core/Fade";
import { useRecoilState, useRecoilValue } from "recoil";
import { activeMenuItemState } from "states/active-menu-item-state";
import { activeMenuState } from "states/active-menu-state";
import useProperty from "hooks/use-oc-property";
import { customerLicencesState } from "states";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import useCurrentUserState from "hooks/use-current-user-state";

export default function OcSidebar({
  narrowMenu,
  narrowMenuOpened,
  onNarrowMenuChange,
  onNarrowMenuOpened,
  open,
  showDrawerTransition,
  variant,
}) {
  const theme = useTheme();
  const licences = useRecoilValue(customerLicencesState);
  const [menu, setMenu] = useState(mainMenu);

  let { contractCode } = useCurrentUserState();

  const { data: rolesData } = useUserRolesDataByContract({
    contract: contractCode,
  });

  const hasUploaderRole = rolesData.some(
    (role) => role.product === "MyDocuments" && role.role === "uploader"
  );
  const hasViewerRole = rolesData.some(
    (role) => role.product === "MyDocuments" && role.role === "viewer"
  );

  const { data: changeRequestsEnabled } = useProperty({
    resource: "system",
    key: "changeRequestsEnabled",
    fetchPolicy: "network-only",
  });

  const { data: myDocumentsMenuEnabled } = useProperty({
    resource: "myDocuments",
    key: "myDocumentsMenuEnabled",
    fetchPolicy: "network-only",
  });

  const myDocumentsEnabled = myDocumentsMenuEnabled === "false" ? false : true;

  const generateMenu = (changeRequestsState) => {
    const newMenu = [...mainMenu];

    if (changeRequestsState) {
      newMenu.splice(1, 0, mainMenuChangeRequestsEnabled);
    } else {
      newMenu.splice(1, 0, mainMenuChangeRequestsDisabled);
    }

    const insertIndex = newMenu.length - 1;

    if (myDocumentsEnabled) {
      if (hasViewerRole && !hasUploaderRole) {
        newMenu.splice(insertIndex, 0, mainMenuMyDocumentsViewer);
      } else if (hasUploaderRole && !hasViewerRole) {
        newMenu.splice(insertIndex, 0, mainMenuMyDocumentsUploader);
      } else if (hasViewerRole && hasUploaderRole) {
        newMenu.splice(insertIndex, 0, mainMenuMyDocumentsCollapse);
      }
    }
    return newMenu;
  };

  useEffect(() => {
    const newMenu = generateMenu(changeRequestsEnabled === "true");
    setMenu(newMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeRequestsEnabled, hasUploaderRole, hasViewerRole]);

  const [activeMenuItem, setActiveMenuItem] = useRecoilState(activeMenuItemState);
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);

  useEffect(() => {
    if (activeMenu === "settingsSubmenu") {
      setMenu(settingsSubmenu);
    } else if (activeMenu === "mainMenu") {
      const newMenu = generateMenu(changeRequestsEnabled === "true");
      setMenu(newMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenu, changeRequestsEnabled]);

  function handleMenuItemChange(id) {
    setActiveMenuItem(id);
  }

  function handleMenuChange(id) {
    setActiveMenu(id);
  }

  const duration = 250;
  let width = narrowMenu ? 52 : 310;

  const defaultStyle = showDrawerTransition
    ? {
      transition: `width ${duration}ms ease-in-out`,
      width: width,
    }
    : {};

  const transitionStyles = {
    entering: { width: 52 },
    entered: { width: 52 },
    exiting: { width: 310 },
    exited: { width: 310 },
  };

  let transition = narrowMenu;
  if (narrowMenuOpened) {
    transition = false;
  }

  return (
    <Transition in={transition} timeout={duration}>
      {(state) => (
        <Drawer
          variant={variant}
          anchor="left"
          open={open}
          id="leftDrawer"
          onMouseEnter={() => {
            if (narrowMenu) {
              onNarrowMenuOpened(true);
            }
          }}
          onMouseLeave={() => {
            if (narrowMenu) {
              onNarrowMenuOpened(false);
            }
          }}
        >
          <Box
            id="oc-sidebar-menu"
            style={{
              overflow: "hidden",
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <Box
              id="oc-sidebar-menu-top"
              height="64px"
              style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.white.main,
              }}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Fade in={!narrowMenu || narrowMenuOpened} unmountOnExit>
                <PartnerLogo />
              </Fade>
              <Fade in={narrowMenuOpened} unmountOnExit>
                <Box
                  id="oc-sidebar-narrow-menu-icon"
                  height="64px"
                  width="52px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => onNarrowMenuChange(!narrowMenu)}
                  style={narrowMenu ? { transform: "rotate(180deg)" } : null}
                >
                  <InlineIcon icon={backburgerIcon} height="26px" />
                </Box>
              </Fade>
              <Fade in={!narrowMenu && !narrowMenuOpened} unmountOnExit>
                <Box
                  id="oc-sidebar-narrow-menu-icon"
                  height="64px"
                  width="52px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => onNarrowMenuChange(!narrowMenu)}
                  style={narrowMenu ? { transform: "rotate(180deg)" } : null}
                >
                  <InlineIcon icon={backburgerIcon} height="26px" />
                </Box>
              </Fade>
              <Fade in={narrowMenu && !narrowMenuOpened} unmountOnExit>
                <Box
                  height="64px"
                  width="52px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => onNarrowMenuChange(!narrowMenu)}
                >
                  <img
                    src="/img/owIconLogoTransparent.png"
                    alt="ow-logo"
                    width="32"
                  ></img>
                </Box>
              </Fade>
            </Box>
            {activeMenu === "mainMenu" && (
              <Slide in={activeMenu === "mainMenu"} direction="right">
                <List style={{ padding: "0px" }}>
                  {menu.map((item, index) => {
                    if (!licences[item?.licence]) {
                      return null;
                    }
                    return (
                      <Selector
                        key={`${index}_${item.id}`}
                        item={item}
                        index={index}
                        onMenuChange={handleMenuChange}
                        onMenuItemChange={handleMenuItemChange}
                        activeMenuItem={activeMenuItem}
                        narrowMenu={narrowMenu}
                        onNarrowMenuChange={onNarrowMenuChange}
                        narrowMenuOpened={narrowMenuOpened}
                      />
                    );
                  })}
                </List>
              </Slide>
            )}
            {activeMenu !== "mainMenu" && (
              <Slide in={activeMenu !== "mainMenu"} direction="left">
                <List>
                  {menu.map((item, index) => {
                    if (!licences[item?.licence]) {
                      return null;
                    }

                    return (
                      <Selector
                        key={`${index}_${item.id}`}
                        item={item}
                        index={index}
                        onMenuChange={handleMenuChange}
                        onMenuItemChange={handleMenuItemChange}
                        activeMenuItem={activeMenuItem}
                        narrowMenu={narrowMenu}
                        onNarrowMenuChange={onNarrowMenuChange}
                        narrowMenuOpened={narrowMenuOpened}
                      />
                    );
                  })}
                </List>
              </Slide>
            )}
          </Box>
        </Drawer>
      )}
    </Transition>
  );
}
