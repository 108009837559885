import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  topBorder: {
    borderTop: `2px solid ${theme.palette.gray40.main}`,
  },
  separatorBorder: {
    borderTop: `1px solid ${theme.palette.gray40.main}`,
    marginLeft: "16px",
    marginRight: "16px",
  },
  backgroundColor: {
    backgroundColor: `${theme.palette.gray20.main}`,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    paddingBottom: "16px",
  },
  attachmentBackgroundColor: {
    backgroundColor: `${theme.palette.white.main}`,
    borderRadius: "4px",
  },
  attachmentBackgroundColorOnCollapse: {
    backgroundColor: `${theme.palette.white.main}`,
    borderRadius: "unset",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  messageBackgroundColor: {
    backgroundColor: `${theme.palette.gray30.main}`,
    borderRadius: "4px",
  },
  messageBackgroundColorOnCollapse: {
    backgroundColor: `${theme.palette.gray30.main}`,
    borderRadius: "unset",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
}));

export default useStyles;
