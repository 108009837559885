import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import MyDocumentsInfoCardItem from "my-documents/components/my-documents-info-card-item/my-documents-info-card-item";
import MyDocumentDocumentVisibility from "my-documents/components/my-documents-document-visibility/my-documents-document-visibility";
import { InlineIcon } from "@iconify/react";
import deleteIcon from "@iconify/icons-mdi/delete";
import pencilIcon from "@iconify/icons-mdi/pencil";
import { useTheme } from "@material-ui/core/styles";
import parsePipedName from "oc/utils/get-parse-pipedName";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";

export default function MyDocumentsCollapse({ row, role, removeUpload, onEditDocument }) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={() => { removeUpload(row); }}
        onCancel={handleDialogCancel}
        title={t("MYDOCUMENTS_DELETE_CONFIRM")}
        text={t("MYDOCUMENTS_DELETE_CONFIRM_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <Box padding="16px">
        <Box>
          <MyDocumentsInfoCardItem title={t("SIZE")} value={formatBytes(row?.size)} />
          <Box height="10px" />
          <MyDocumentsInfoCardItem title={t("UPLOADER")} value={parsePipedName(row?.user, i18n)} />
          {role &&
            <Box>
              <Box height="18px" />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <MyDocumentDocumentVisibility visibility={row?.userView} />
                <Box display="flex" alignItems="center">
                  <InlineIcon
                    id="deleteIcon"
                    icon={deleteIcon}
                    width="24px"
                    style={{
                      color: theme.palette.owGray.main,
                      cursor: "pointer",
                    }}
                    onClick={() => setShowConfirmDialog(true)}
                  />
                  <Box width="8px" />
                  <InlineIcon
                    id="pencilIcon"
                    icon={pencilIcon}
                    width="24px"
                    style={{
                      color: theme.palette.owGray.main,
                      cursor: "pointer",
                    }}
                    onClick={() => onEditDocument(row)}
                  />
                </Box>
              </Box>
            </Box>
          }
        </Box>
      </Box>
    </>
  );
}
