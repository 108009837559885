import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";

export default function MyDocumentsInfoCardItem({ title, value }) {
  const theme = useTheme();

  return (
    <Box style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    }}>
      <Box style={{
        color: theme.palette.owGray.main,
        fontSize: "12px",
        letterSpacing: "0.4px",
        width: "128px",
        textAlign: "right",
        paddingRight: "16px",
      }}>
        {title}
      </Box>
      <Box>{value}</Box>
    </Box>
  );
}
