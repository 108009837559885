import { useMutation, useQuery } from "@apollo/react-hooks";
import { GET_MY_DOCUMENTS_LIST } from "graphql/queries";
import { DELETE_MY_DOCUMENT } from "graphql/mutations/delete-my-document";
import { UPDATE_MY_DOCUMENT } from "graphql/mutations/update-my-document";
import useOcSnackbar from "hooks/use-oc-snackbar";
import { useTranslation } from "react-i18next";

const useMyDocumentsList = ({ user }) => {
  const { data, loading, error, refetch } = useQuery(GET_MY_DOCUMENTS_LIST, {
    fetchPolicy: "cache-and-network",
    variables: {
      user
    },
  });

  const { setOpenMessage } = useOcSnackbar();
  const { t } = useTranslation();

  const [deleteMyDocument] = useMutation(DELETE_MY_DOCUMENT, {
    onCompleted: (data) => {
      refetch();
      setOpenMessage({
        type: "success",
        message: t("DOCUMENT_SUCCESSFUL_DELETION"),
      });
    },
    onError: (error) => {
      console.log({ error });
      setOpenMessage({
        type: "error",
        message: t("DOCUMENT_ERROR_DELETION"),
      });
    },
  });

  const [updateMyDocument] = useMutation(UPDATE_MY_DOCUMENT, {
    onCompleted: (data) => {
      refetch();
      setOpenMessage({
        type: "success",
        message: t("USER_UPDATED"),
      });
    },
    onError: (error) => {
      console.log({ error });
      setOpenMessage({
        type: "error",
        message: t("APPROVE_LEAVE_REQUEST_FAILED"),
      });
    },
  });

  return {
    data: data?.listMyDocuments || [],
    loading,
    error,
    refetch,
    deleteMyDocument,
    updateMyDocument
  };
};

export default useMyDocumentsList;
