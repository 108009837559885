import gql from "graphql-tag";

export const GET_CONTRACT = gql`
  query contract($contract: String!) {
    contract(contract: $contract) {
      _id
      code
      employee {
        code
      }
      company {
        _id
        code
        name
        companyLogo
      }
      orgUnit {
        code
        name
      }
      jobClass {
        code
        name
      }
      jobClassGroup {
        code
        name
      }
      payOffice {
        code
        name
      }
      workingPlace {
        code
        name
      }
      typeOfContract {
        code
        name
      }
      shiftType {
        code
        name
      }
      employmentType {
        code
        name
      }
      contractTerminationType {
        code
        name
      }
      maxOvertimeInHour
      roles
      validFrom
      validTo
      startOfContract
      endOfContract
      position
    }
  }
`;
