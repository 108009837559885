import { useQuery } from "@apollo/react-hooks";
import { GET_MY_DOCUMENTS_UPLOADERS } from "my-documents/queries/get-my-documents-uploaders";

const useMyDocumetsUploaders = ({ contractCode, locale }) => {
  const { data, loading, error } = useQuery(GET_MY_DOCUMENTS_UPLOADERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      contractCode,
      locale,
    },
  });

  return {
    data: data?.getMyDocumentsUploaders || [],
    loading,
    error,
  };
};

export default useMyDocumetsUploaders;
