import gql from "graphql-tag";

export const GET_MY_TIME_REQUEST = gql`
  query getMyTimeRequest($_id: String!) {
    getMyTimeRequest(_id: $_id) {
      _id
      myTimeType
      startTime
      endTime
      hours
      state
      timeDataTypeCode
      timeDataTypeName
      createdAt
      createdBy
      approvedAt
      approvedBy
      rejectedAt
      rejectedBy
    }
  }
`;
