import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { Box, TextField, Collapse, InputAdornment } from "@material-ui/core";
import getPhoneFragment from "oc/utils/get-phone-fragment";

const OcFormEditorTextInput = ({
  name,
  label,
  value,
  onChange,
  error,
  inputAdornment,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (inputAdornment && value?.trim().startsWith("+36")) {
    value = value?.trim().replace("+36", "");
  }

  const handleChange = (event) => {
    let newValue = event.target.value;

    switch (name) {
      case "district":
        if (/^\d*$/.test(newValue) && newValue.length <= 2) {
          onChange(event);
        }
        break;
      case "privatePhone":
      case "workMobilePhone":
        if (/^\d*$/.test(newValue.replace(/\s/g, ''))) {
          getPhoneFragment(event, onChange, name, 9, [2, 6]);
        }
        break;
      case "workPhone":
        if (/^\d*$/.test(newValue.replace(/\s/g, ''))) {
          const format = newValue[0] === "1" ? [1, 5] : [2, 6];
          const maxLength = newValue[0] === "1" ? 8 : 9;
          getPhoneFragment(event, onChange, name, maxLength, format);
        }
        break;
      default:
        onChange(event);
        break;
    }
  };

  const inputProps = {};
  if (["privatePhone", "workMobilePhone", "workPhone"].includes(name)) {
    inputProps.inputMode = "numeric";
    inputProps.pattern = "\\d*";
  }

  return (
    <Box key={name} paddingTop="36px" height="60px">
      <TextField
        id={name}
        name={name}
        label={t(label)}
        variant="outlined"
        fullWidth
        value={value}
        onChange={handleChange}
        error={!!error}
        helperText={name === "district" && !error && t("ACCEPTABLE_DISTRICT_FORMAT")}
        InputProps={
          inputAdornment
            ? {
              startAdornment: (
                <InputAdornment position="start">{inputAdornment}</InputAdornment>
              ),
              ...inputProps,
            }
            : inputProps
        }
      />
      <Collapse
        in={!error}
        style={{
          color: theme.palette.primary.main,
          margin: "0",
          fontSize: "12px",
          marginTop: "3px",
          textAlign: "left",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.4px",
          paddingLeft: "14px",
          marginBottom: "-15px",
        }}
      >
        <Box>{t(error)}</Box>
      </Collapse>
    </Box>
  );
};

export default OcFormEditorTextInput;
