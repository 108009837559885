import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import useMyDocumentsList from "my-documents/hooks/use-my-documents-list";
import MyDocumentsEditFile from "my-documents/components/my-documents-edit-file/my-documents-edit-file";
import OcPageTransition from "oc/components/oc-page-transition";
import { myDocumentsDocumentList } from "my-documents/configs/tableDefs";
import Table from "oc/components/oc-data-table";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useResetRecoilState, useRecoilValue } from "recoil";
import { myDocumentsIsDocument } from "my-documents/states/is-document";

export default function MyDocumentsInfoCard({ isExaminer, contractCode, editDocument, onCloseEdit, onEditDocument }) {
  const { t } = useTranslation();
  const { data: list, loading, deleteMyDocument, updateMyDocument } = useMyDocumentsList({ user: contractCode });

  function removeUpload(item) {
    const { _id } = item;
    deleteMyDocument({ variables: { _id } });
  }

  const resetAddPermissionEmailState = useResetRecoilState(myDocumentsIsDocument);
  let tableId = "myDocumentsUploaderTableData";
  initialTableData.sortField = "name";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const isDocument = useRecoilValue(myDocumentsIsDocument);

  useEffect(() => {
    if (isDocument) {
      setSelectedRowId("");
      resetAddPermissionEmailState();
    }
  }, [isDocument, resetAddPermissionEmailState]);

  function handleTableDataChange(state) {
    setTableData(state);
  }

  function handleRowSelection(row) {
    let selectedId = selectedRowId === row?._id ? null : row?._id;
    setSelectedRowId(selectedId);
  }

  const documentList = {
    rows: isExaminer ? list : list?.filter(item => item.userView === true),
    total: list?.length,
  };

  if (editDocument) {
    return (
      <Box>
        <MyDocumentsEditFile
          document={editDocument}
          removeUpload={removeUpload}
          updateUpload={updateMyDocument}
          onClose={onCloseEdit}
        />
      </Box>
    );
  }

  return (
    <OcPageTransition>
      <Box display="flex" flexDirection="column">
        {!loading && list?.length === 0 ? (
          <Box style={{ textAlign: "center" }}>
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center" }}>
              <img alt="orgware-icon" src="/img/nodocument.svg" style={{ width: 500 }} />
            </Box>
            <Box height="36px" />
            <Typography variant="body1">{t("DOCUMENT_NOT_FOUND")}</Typography>
          </Box>
        ) : (
          <Table
            tableDef={myDocumentsDocumentList}
            tableId={tableId}
            tableData={tableData}
            setTableData={handleTableDataChange}
            data={documentList}
            loading={loading}
            selectedRowId={selectedRowId}
            onSelectRow={handleRowSelection}
            pagination={true}
            myDocuments={true}
            role={isExaminer}
            removeUpload={removeUpload}
            onEditDocument={onEditDocument}
            localPaging={true}
          />
        )}
      </Box>
    </OcPageTransition>
  );
}
