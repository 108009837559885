// MyHR pages
import MyPersonalData from "my-hr/pages/my-hr-user-home-page";
import MyProfile from "my-hr/pages/my-hr-user-profile-page";
import SystemSettings from "my-hr/pages/my-hr-admin-system-settings-page";
import CreateNewUser from "my-hr/pages/my-hr-create-new-user-page";
import MyHrAdminUserPage from "my-hr/pages/my-hr-admin-user-page";
import Users from "my-hr/pages/my-hr-admin-users-page";
import NotFound from "my-hr/pages/my-hr-user-not-found-page";
import ForgotPassword from "my-hr/pages/my-hr-user-forgot-password-page";
import Login from "my-hr/pages/my-hr-user-login-page";
import UserGuides from "my-hr/components/my-hr-user-guides";
import MyHrViewUserDataEditRequests from "my-hr/pages/my-hr-user-requests-page";
import MyHrUserDataEditRequests from "my-hr/pages/my-hr-user-data-edit-page";
import MyHRAdminAddGroupRolesPage from "my-hr/pages/my-hr-admin-add-group-roles-page";
import MyHRAdminInactivateGroupPage from "my-hr/pages/my-hr-admin-inactivate-group-page";
import MyHRErrorPage from "my-hr/pages/my-hr-error-page/my-hr-error-page";
import MyHROwadminLoginPage from "my-hr/pages/my-hr-owadmin-login-page/my-hr-owadmin-login-page";
import MyHROwadminAddPermitPage from "my-hr/pages/my-hr-owadmin-add-permit-page/my-hr-owadmin-add-permit-page";
import MyHRAdminRemoveGroupPage from "my-hr/pages/my-hr-admin-remove-group-page";
import MyHRAdminSendGroupPasswordsPage from "my-hr/pages/my-hr-admin-send-group-passwords-page";
import MyHrAdminSettingsPage from "my-hr/pages/my-hr-admin-settings-page";

// Tax Applications
import FirstMarriageTaxCreditInformation from "my-hr/components/my-hr-applications-data-panel/components/first-marriage-tax-credit-information";
import UnderThirtyMothersTaxCreditInformation from "my-hr/components/my-hr-applications-data-panel/components/under-thirty-mothers-tax-credit-information";
import CostTaxCreditInformation from "my-hr/components/my-hr-applications-data-panel/components/cost-tax-credit-information";
import FamilyTaxCreditInformation from "my-hr/components/my-hr-applications-data-panel/components/family-tax-credit-information";
import MyPersonalTaxCreditInformation from "my-hr/components/my-hr-applications-data-panel/components/my-personal-tax-credit-information";
import FourChildrenTaxCreditInformation from "my-hr/components/my-hr-applications-data-panel/components/four-children-tax-credit-information";
import UnderTwentyFiveTaxCreditInformation from "my-hr/components/my-hr-applications-data-panel/components/under-twenty-five-tax-credit-information";

// MyLeave pages
import MyLeaveRequests from "my-leave/pages/my-leave-my-leave-requests-page";
import MyYearlyLeaves from "my-leave/pages/my-leave-my-yearly-leaves-page";
import ApproveLeaveRequests from "my-leave/components/my-leave-approve-leave-requests";
import ViewLeaveRequests from "my-leave/components/my-leave-view-leave-requests";
import HandleLeaveRequests from "my-leave/components/my-leave-handle-leave-requests";
import UserRequests from "my-leave/pages/my-leave-user-requests-page";
import UserRequest from "my-leave/pages/my-leave-user-request-page";
import MyLeaveRequest from "my-leave/pages/my-leave-my-leave-request-page";
import MyLeaveAdminSettingsPage from "my-leave/pages/my-leave-admin-settings-page";
import MyTimeAdminSettingsPage from "my-leave/pages/my-time-admin-settings-page";
import MyLeaveExaminerDashboardPage from "my-leave/pages/my-leave-examiner-dashboard-page/my-leave-examiner-dashboard-page";

// MyCafeteria pages
import MyCafeteriaPage from "my-cafeteria/pages/my-cafeteria-page";
import MyCafeteriaVbkApplicationPage from "my-cafeteria/pages/my-cafeteria-vbk-application-page";
import MyCafeteriaSzepApplicationPage from "my-cafeteria/pages/my-cafeteria-szep-application-page";
import MyCafeteriaAdminSettingsPage from "my-cafeteria/pages/my-cafeteria-admin-settings-page";

// MyTime
import MyTimeApproveRequestsPage from "my-time/pages/my-time-approve-requests-page";
import MyTimeClosingCurrentPeriodPage from "my-time/pages/my-time-closing-current-period-page";
import MyTimeEmployeeRequestPage from "my-time/pages/my-time-employee-request-page";
import MyTimeEmployeeRequestsPage from "my-time/pages/my-time-employee-requests-page";
import MyTimeEmployeeSchedulePage from "my-time/pages/my-time-employee-schedule-page/my-time-employee-schedule-page";
import MyTimeShowRequestPage from "my-time/pages/my-time-show-request-page";

// MyDocuments pages
import MyDocumentsUploadDocumentsPage from "my-documents/pages/my-documents-upload-documents-page/my-documents-upload-documents-page";
import MyDocumentsViewDocumentsPage from "my-documents/pages/my-documents-view-documents-page/my-documents-view-documents-page";
import MyDocumentsUserFiles from "my-documents/components/my-documents-user-files/my-documents-user-files";
import MyDocumentsSettingsPage from "my-documents/pages/my-documents-settings-page/my-documents-settings-page";


export const routeDefs = [
  {
    id: "personalDataPage",
    path: "/",
    exact: true,
    private: true,
    component: MyPersonalData,
    roles: [],
  },
  {
    id: "myLeaveExaminerDashboard",
    path: "/my-leave/dashboard",
    exact: true,
    private: true,
    component: MyLeaveExaminerDashboardPage,
    roles: ["MyLeave.examiner", "MyLeave.viewer"],
  },
  {
    id: "myLeaveRequests",
    path: "/my-leave/my-requests",
    exact: true,
    private: true,
    component: MyLeaveRequests,
    roles: ["MyLeave.user"],
  },
  {
    id: "myLeaveRequest",
    path: "/my-leave/my-request/:id",
    exact: true,
    private: true,
    component: MyLeaveRequest,
    roles: ["MyLeave.user"],
  },
  {
    id: "myYearlyLeaves",
    path: "/my-leave/my-yearly-leaves/:year",
    exact: true,
    private: true,
    component: MyYearlyLeaves,
    roles: ["MyLeave.user"],
  },
  {
    id: "approveLeaveRequests",
    path: "/my-leave/approve-requests",
    exact: true,
    private: true,
    component: ApproveLeaveRequests,
    roles: ["MyLeave.examiner"],
  },
  {
    id: "viewLeaveRequests",
    path: "/my-leave/view-requests",
    exact: true,
    private: true,
    component: ViewLeaveRequests,
    roles: ["MyLeave.viewer"],
  },
  {
    id: "handleLeaveRequests",
    path: "/my-leave/handle-requests",
    exact: true,
    private: true,
    component: HandleLeaveRequests,
    roles: ["MyLeave.admin"],
  },
  {
    id: "userLeaveRequests",
    path: "/my-leave/user-requests/:product/:role/:contract",
    exact: true,
    private: true,
    component: UserRequests,
    roles: ["MyLeave.examiner", "MyLeave.viewer"],
  },
  {
    id: "userLeaveRequest",
    path: "/my-leave/user-request/:product/:role/:contract/:id",
    exact: true,
    private: true,
    component: UserRequest,
    roles: ["MyLeave.examiner", "MyLeave.viewer"],
  },
  {
    id: "myProfile",
    path: "/my-hr/my-profile",
    exact: true,
    private: true,
    component: MyProfile,
    roles: [],
  },
  {
    id: "myUserGuides",
    path: "/my-hr/user-guides",
    exact: true,
    private: true,
    component: UserGuides,
    roles: [],
  },
  {
    id: "myDataEdit",
    path: "/my-hr/my-requests",
    exact: true,
    private: true,
    component: MyHrUserDataEditRequests,
    roles: [],
  },
  {
    id: "userDataEditRequests",
    path: "/my-hr/view-requests",
    exact: true,
    private: true,
    component: MyHrViewUserDataEditRequests,
    roles: [],
  },
  {
    id: "userDataEditRequest",
    path: "/my-hr/view-request/:employee",
    exact: true,
    private: true,
    component: MyHrUserDataEditRequests,
    roles: [],
  },
  {
    id: "createNewUser",
    path: "/my-hr/create-new-user",
    exact: true,
    private: true,
    component: CreateNewUser,
    roles: ["MyHR.admin"],
  },
  {
    id: "systemSettingsd",
    path: "/my-hr/system-settings",
    exact: true,
    private: true,
    component: SystemSettings,
    roles: ["MyHR.admin"],
  },
  {
    id: "myHrSettings",
    path: "/my-hr/admin/settings",
    exact: true,
    private: true,
    component: MyHrAdminSettingsPage,
    roles: ["MyHR.admin"],
  },
  {
    id: "userProfile",
    path: "/my-hr/user-profile/:employee",
    exact: true,
    private: true,
    component: MyHrAdminUserPage,
    roles: ["MyHR.admin"],
  },
  {
    id: "users",
    path: "/my-hr/users",
    exact: true,
    private: true,
    component: Users,
    roles: ["MyHR.admin"],
  },
  {
    id: "myLeaveAdminSettings",
    path: "/my-leave/admin/settings",
    exact: true,
    private: true,
    component: MyLeaveAdminSettingsPage,
    roles: ["MyLeave.admin"],
  },
  {
    id: "404",
    path: "/404",
    exact: true,
    private: true,
    component: NotFound,
    roles: [],
  },
  {
    id: "loginPage",
    path: "/login",
    exact: true,
    private: false,
    component: Login,
  },
  {
    id: "forgotPasswordPage",
    path: "/forgot-password",
    exact: true,
    private: false,
    component: ForgotPassword,
  },
  {
    id: "myCafeteriaPage",
    path: "/my-cafeteria",
    exact: true,
    private: true,
    component: MyCafeteriaPage,
    roles: ["MyCafeteria.user"],
  },
  {
    id: "myCafeteriaVbkApplicationPage",
    path: "/my-cafeteria/vbk-application",
    exact: true,
    private: true,
    component: MyCafeteriaVbkApplicationPage,
    roles: ["MyCafeteria.user"],
  },
  {
    id: "myCafeteriaSzepApplicationPage",
    path: "/my-cafeteria/szep-card-application",
    exact: true,
    private: true,
    component: MyCafeteriaSzepApplicationPage,
    roles: ["MyCafeteria.user"],
  },
  {
    id: "familyTaxCreditInformation",
    path: "/my-hr/family-tax-credit-information",
    exact: true,
    private: false,
    component: FamilyTaxCreditInformation,
    roles: [],
  },
  {
    id: "fourChildrenTaxCreditInformation",
    path: "/my-hr/four-children-tax-credit-information",
    exact: true,
    private: false,
    component: FourChildrenTaxCreditInformation,
    roles: [],
  },
  {
    id: "myPersonalTaxCreditInformation",
    path: "/my-hr/my-personal-tax-credit-information",
    exact: true,
    private: false,
    component: MyPersonalTaxCreditInformation,
    roles: [],
  },
  {
    id: "underTwentyFiveTaxCreditInformation",
    path: "/my-hr/under-twenty-five-tax-credit-information",
    exact: true,
    private: false,
    component: UnderTwentyFiveTaxCreditInformation,
    roles: [],
  },
  {
    id: "firstMarriageTaxCreditInformation",
    path: "/my-hr/first-marriage-tax-credit-information",
    exact: true,
    private: false,
    component: FirstMarriageTaxCreditInformation,
    roles: [],
  },
  {
    id: "underThirtyMothersTaxCreditInformation",
    path: "/my-hr/under-thirty-mothers-tax-credit-information",
    exact: true,
    private: false,
    component: UnderThirtyMothersTaxCreditInformation,
    roles: [],
  },
  {
    id: "costTaxCreditInformation",
    path: "/my-hr/cost-tax-credit-information",
    exact: true,
    private: false,
    component: CostTaxCreditInformation,
    roles: [],
  },
  {
    id: "myCafeteriaAdminSettings",
    path: "/my-cafeteria/admin/settings",
    exact: true,
    private: true,
    component: MyCafeteriaAdminSettingsPage,
    roles: ["MyCafeteria.admin"],
  },
  {
    id: "addGroupRolesPage",
    path: "/my-hr/users/add-group-roles",
    exact: true,
    private: true,
    component: MyHRAdminAddGroupRolesPage,
    roles: [],
  },
  {
    id: "inactivateGroupPage",
    path: "/my-hr/users/inactivate-group",
    exact: true,
    private: true,
    component: MyHRAdminInactivateGroupPage,
    roles: [],
  },
  {
    id: "removeGroupPage",
    path: "/my-hr/users/remove-group",
    exact: true,
    private: true,
    component: MyHRAdminRemoveGroupPage,
    roles: [],
  },
  {
    id: "sendGroupPasswordsPage",
    path: "/my-hr/users/send-group-passwords",
    exact: true,
    private: true,
    component: MyHRAdminSendGroupPasswordsPage,
    roles: [],
  },
  {
    id: "myHRErrorPage",
    path: "/my-hr/connection-error",
    exact: true,
    private: false,
    component: MyHRErrorPage,
    roles: [],
  },
  {
    id: "myHROwadminLoginPage",
    path: "/ow-admin-login",
    exact: true,
    private: false,
    component: MyHROwadminLoginPage,
    roles: [],
  },
  {
    id: "myHROwadminAddPermitPage",
    path: "/ow-admin-add-permit",
    exact: true,
    private: false,
    component: MyHROwadminAddPermitPage,
    roles: [],
  },
  {
    id: "myTimeEmployeeSchedule",
    path: "/my-time/employee-schedule",
    exact: true,
    private: true,
    component: MyTimeEmployeeSchedulePage,
    roles: ["MyTime.user"],
  },
  {
    id: "myTimeApproveRequests",
    path: "/my-time/approve-requests",
    exact: true,
    private: true,
    component: MyTimeApproveRequestsPage,
    roles: ["MyTime.examiner"],
  },
  {
    id: "myTimeEmployeeRequests",
    path: "/my-time/employee-requests/:employee/:contract",
    exact: true,
    private: true,
    component: MyTimeEmployeeRequestsPage,
    roles: ["MyTime.examiner"],
  },
  {
    id: "myTimeEmployeeRequest",
    path: "/my-time/employee-request/:employee/:contract/:id",
    exact: true,
    private: true,
    component: MyTimeEmployeeRequestPage,
    roles: ["MyTime.examiner"],
  },
  {
    id: "myTimeClosingPeriod",
    path: "/my-time/closing-current-period",
    exact: true,
    private: true,
    component: MyTimeClosingCurrentPeriodPage,
    roles: ["MyTime.admin"],
  },
  {
    id: "myTimeShowRequest",
    path: "/my-time/show-request/:employee/:contract/:id",
    exact: true,
    private: true,
    component: MyTimeShowRequestPage,
    roles: ["MyTime.user", "MyTime.examiner", "MyTime.admin"],
  },
  {
    id: "myTimeAdminSettings",
    path: "/my-time/admin/settings",
    exact: true,
    private: true,
    component: MyTimeAdminSettingsPage,
    roles: ["MyTime.admin"],
  },
  {
    id: "myDocumentsUploaderMenu",
    path: "/my-documents/upload-documents",
    exact: true,
    private: true,
    component: MyDocumentsUploadDocumentsPage,
    roles: ["MyDocuments.uploader"],
  },
  {
    id: "myDocumentsViewerMenu",
    path: "/my-documents/view-documents",
    exact: true,
    private: true,
    component: MyDocumentsViewDocumentsPage,
    roles: ["MyDocuments.viewer"],
  },
  {
    id: "myDocumentsUserFiles",
    path: "/my-documents/user-files/:employee/:contract",
    exact: true,
    private: true,
    component: MyDocumentsUserFiles,
    roles: [],
  },
  {
    id: "myDocumentsSettings",
    path: "/my-documents/settings",
    exact: true,
    private: true,
    component: MyDocumentsSettingsPage,
    roles: ["MyHR.admin"],
  },
];
