import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import huLocale from "date-fns/locale/hu";
import "date-fns";
import { useTranslation } from "react-i18next";
import OcDatePickerCancelButton from "oc/components/oc-date-picker-cancel-button";
import OcDatePickerOkButton from "oc/components/oc-date-picker-ok-button";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useTheme } from "@material-ui/core/styles";

export default function OcTimePicker({
  error,
  helperText,
  id,
  label,
  minDate,
  minDateMessage,
  onChange,
  value,
  onFocus,
}) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  let lang =
    i18n && i18n.language ? i18n.language.substr(0, 2).toLowerCase() : "hu";

  const getLocaleMap = () => {
    const localeMap = {
      en: enLocale,
      hu: huLocale,
    };

    return localeMap[lang] ? localeMap[lang] : huLocale;
  };

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={getLocaleMap()}
      style={{ marginTop: 0 }}
    >
      <KeyboardTimePicker
        ampm={false}
        minDate={minDate}
        minDateMessage={minDateMessage}
        style={{ width: "204px" }}
        inputVariant="outlined"
        margin="normal"
        id={id}
        name={id}
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        KeyboardButtonProps={{
          "aria-label": "change date",
          style: { backgroundColor: theme.palette.gray20.main },
        }}
        okLabel={<OcDatePickerOkButton btText={t("SAVE")} />}
        cancelLabel={<OcDatePickerCancelButton btText={t("Common.cancel")} />}
        InputLabelProps={{
          required: false,
        }}
        onFocus={onFocus}
        keyboardIcon={<AccessTimeIcon />}
      />
    </MuiPickersUtilsProvider>
  );
}
