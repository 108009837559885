import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  separatorBorder: {
    borderTop: `1px solid ${theme.palette.gray40.main}`,
  },
  messageBackgroundColor: {
    backgroundColor: theme.palette.gray30.main,
    borderRadius: "4px",
  },
  messageBackgroundColorOnCollapse: {
    backgroundColor: theme.palette.gray30.main,
    borderRadius: "unset",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  messageCard: {
    backgroundColor: theme.palette.gray30.main,
    padding: "0px 16px 24px 16px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  messageItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 16,
  },
  messageItemInnerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  myMessageItemInnerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  myMessageItem: {
    backgroundColor: theme.palette.gray60.main,
    color: "white",
    padding: "10px 15px 10px 15px",
    borderRadius: 4,
    border: `1px solid ${theme.palette.gray60.main}`,
    textAlign: "right",
  },
  messageItem: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray80.main,
    padding: "10px 15px 10px 15px",
    borderRadius: 4,
    border: `1px solid ${theme.palette.gray40.main}`,
    textAlign: "left",
  },
  messageOrganizer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  avatar: {
    width: "40px",
    height: "40px",
    marginTop: 12,
    marginRight: 10,
  },
  smallText: {
    color: theme.palette.gray60.main,
    textTransform: "capitalize",
  },
}));

export default useStyles;
