import { InlineIcon } from "@iconify/react";
import { useLazyQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import emailIcon from "@iconify/icons-mdi/email";
import gql from "graphql-tag";
import InputAdornment from "@material-ui/core/InputAdornment";
import keyIcon from "@iconify/icons-mdi/key";
import Loading from "oc/components/oc-loading";
import React, { useEffect, Fragment, useState, useLayoutEffect } from "react";
import TextField from "@material-ui/core/TextField";
import useClientState from "hooks/use-oc-client-state";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import useOcProperty from "hooks/use-oc-property";
import { useTheme } from "@material-ui/core/styles";

import Validator from "fastest-validator";
const validator = new Validator();

const LOGIN = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      sessionPeriod
      accessTokenPeriod
      contractCode
      currentUser {
        _id
        email
        phone
        ldapUser
        employee
        lastPasswordChange
        validFrom
        validTo
      }
      isTemporaryPassword
    }
  }
`;

export default function MyHrLoginForm({ onSubmit, buttonLabel }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const schema = {
    email: {
      type: "email",
      messages: {
        email: t("ERROR_MESSAGE_WRONG_EMAIL_FORMAT"),
        emailEmpty: t("ERROR_MESSAGE_EMAIL_IS_REQUIRED"),
      },
    },
    password: {
      type: "string",
      min: 1,
      messages: {
        string: "Nem megfelelő jelszó!",
        stringMin: t("ERROR_MESSAGE_PASSWORD_IS_REQUIRED"),
      },
    },
  };
  const check = validator.compile(schema);

  const [email, setEmail] = useClientState("loginEmail", "");
  const [emailError, setEmailError] = useClientState("loginEmailError", false);
  const [emailErrorText, setEmailErrorText] = useClientState(
    "loginEmailErrorText",
    ""
  );
  const [password, setPassword] = useClientState("loginPassword", "");
  const [showPassword, setShowPassword] = useState(false);

  const [passwordError, setPasswordError] = useClientState(
    "loginPasswordError",
    false
  );
  const [passwordErrorText, setPasswordErrorText] = useClientState(
    "loginPasswordErrorText",
    ""
  );

  const { data: passwordVisibilityData } = useOcProperty({
    resource: "system",
    key: "passwordVisibility",
  });


  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const [login, { loading }] = useLazyQuery(LOGIN, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.login) {
        let loginResult = data?.login;
        onSubmit(loginResult);
      }
    },
    onError: (error) => {
      let errors = error?.graphQLErrors;
      errors.map((item) => {
        if (item.message === "No user found!") {
          setEmailErrorText(t("ERROR_MESSAGE_NO_USER_FOUND"));
          setEmailError(true);
        }
        if (item.message === "Wrong password!") {
          setPasswordErrorText(t("ERROR_MESSAGE_WRONG_PASSWORD"));
          setPasswordError(true);
        }
        if (item.message === "User account has expired!") {
          setEmailErrorText(t("ERROR_MESSAGE_ACCOUNT_HAS_EXPIRED"));
          setEmailError(true);
        }
        if (item.message === "No valid legal contract found!") {
          setEmailErrorText(t("ERROR_MESSAGE_NO_CONTRACT_FOUND"));
          setEmailError(true);
        }
        return item;
      });
    },
  });

  function handleLogin(e) {
    e.preventDefault();

    const emailParsed = email || document.getElementById("email")?.value;
    const passwordParsed =
      password || document.getElementById("password")?.value;

    let isValidated = check({ email: emailParsed, password: passwordParsed });

    if (isValidated !== true) {
      isValidated.forEach((item) => {
        if (item.field === "email") {
          setEmailErrorText(item.message);
          setEmailError(true);
        }
        if (item.field === "password") {
          setPasswordErrorText(item.message);
          setPasswordError(true);
        }
      });
      return;
    }

    login({ variables: { email: emailParsed, password: passwordParsed } });
  }
  useEffect(() => {
    function handleClear() {
      setEmail("");
      setEmailError(false);
      setEmailErrorText("");
      setPassword("");
      setPasswordError(false);
      setPasswordErrorText("");
    }
    return function cleanup() {
      handleClear();
    };
    // eslint-disable-next-line
  }, []);

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  useLayoutEffect(() => {
    function handleHiddenState() {
      if (document.hidden) {
        // Az oldal láthatatlan
      } else {
        setEmail(document.getElementById("email")?.value);
        setPassword(document.getElementById("password")?.value);
      }
    }
    window.addEventListener("visibilitychange", handleHiddenState);
    return () =>
      window.removeEventListener("visibilitychange", handleHiddenState);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <OcErrorBoundary>
        <Loading show={loading} />
        <form noValidate autoComplete="off" onSubmit={handleLogin}>
          <Box paddingTop="18px" paddingBottom="14px">
            <TextField
              id="email"
              defaultValue={email}
              onChange={handleEmailChange}
              label={t("EMAIL")}
              variant="outlined"
              fullWidth
              error={emailError}
              onFocus={() => {
                setEmailErrorText("");
                setEmailError(false);
              }}
              helperText={emailError && emailErrorText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InlineIcon height="24px" icon={emailIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box paddingTop="18px" paddingBottom="14px">
            <TextField
              id="password"
              defaultValue={password}
              onChange={(e) => setPassword(e.target.value)}
              label={t("PASSWORD")}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              fullWidth
              helperText={passwordErrorText}
              error={passwordError}
              onFocus={() => {
                setPasswordErrorText("");
                setPasswordError(false);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InlineIcon height="24px" icon={keyIcon} />
                    <Box width="8px" />
                    {passwordVisibilityData === "true" &&
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        style={{ backgroundColor: theme.palette.gray20.main }}
                      >
                        {showPassword ? (
                          <VisibilityOff
                            style={{ height: "24px", width: "24px" }}
                          />
                        ) : (
                          <Visibility style={{ height: "24px", width: "24px" }} />
                        )}
                      </IconButton>
                    }
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box paddingTop="18px">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disableElevation
            >
              {buttonLabel}
            </Button>
          </Box>
        </form>
      </OcErrorBoundary>
    </Fragment>
  );
}
