import gql from "graphql-tag";

export const GET_CUSTOMER_LICENCES = gql`
  query getCustomerLicences {
    getCustomerLicences {
      userAzon
      ocLicences {
        company
        myHR
        myHRChangeData
        myHRSaveTaxApplications
        myLeave
        myLeaveEmployeeRequests
        myCafeteria
        myTime
        myTimeEmployeeRequests
        myDocuments
        mySheet
        myNewsFeed
      }
    }
  }
`;
