import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { InlineIcon } from "@iconify/react";
import eyeOutline from "@iconify/icons-mdi/eye-outline";
import eyeOffOutline from "@iconify/icons-mdi/eye-off-outline";

export default function MyDocumentDocumentVisibility({ visibility }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <InlineIcon
        id="eyeOutline"
        icon={visibility ? eyeOutline : eyeOffOutline}
        width="24px"
        style={{
          color: theme.palette.darkBase.main,
        }}
      />
      <Box width="8px" />
      <Typography variant="caption">{visibility ? t("EMPLOYEE_VISIBILITY") : t("EMPLOYEE_UNVISIBILITY")}</Typography>
    </Box>
  );
}
