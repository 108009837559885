import { useMutation } from "@apollo/react-hooks";
import { MY_TIME_USERS_WITH_REQUEST } from "my-time/graphql/mutations/my-time-users-with-request";
import { useSubscription } from "@apollo/react-hooks";
import { MY_TIME_REQUESTS_SUBSCRIPTION } from "my-time/graphql/subscriptions";

export default function useMyTimeUsersWithRequests({
  requestsOnly,
  showOvertime,
  showWorkingTimeChange,
  showStandbyCall,
  roleFilters,
  tableData,
  startDate,
  endDate,
  onCompleted,
}) {
  delete roleFilters.__typename;

  let queryParams = {
    requestsOnly,
    showOvertime,
    showWorkingTimeChange,
    showStandbyCall,
    roleFilters,
    sortField: tableData?.sortField,
    sortDirection: tableData?.sortDirection,
    startDate,
    endDate,
    page: tableData?.page,
    pageSize: tableData?.pageSize,
  };

  if (tableData?.searchField && tableData?.searchText) {
    queryParams.searchField = tableData?.searchField;
    queryParams.searchText = tableData?.searchText;
  }
  let requestsCount = 0;
  let usersCount = 0;
  let allUserCount = 0;


  const [loadQuery, { data, loading, error, refetch }] = useMutation(
    MY_TIME_USERS_WITH_REQUEST,
    {
      variables: queryParams,
      onCompleted: (data) => {
        onCompleted(data?.myTimeUsersWithRequest || []);
      },
    }
  );

  useSubscription(MY_TIME_REQUESTS_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  return {
    data: data?.myTimeUsersWithRequest || [],
    loading,
    error,
    usersCount: usersCount,
    allUserCount: allUserCount,
    requestsCount,
    loadQuery,
  };
}
