export default function getPhoneFragment(event, onChange, name, maxLength, format) {
  if (!event || !onChange || !name) return;

  let newValue = event.target.value;
  newValue = formatPhoneNumber(newValue, format);

  if (newValue.replace(/ /g, "").length <= maxLength) {
    onChange({ target: { name, value: newValue } });
  }
}

function formatPhoneNumber(number, format) {
  if (!number || !format) return number;

  let formattedNumber = number;
  format.forEach((index) => {
    if (formattedNumber.length > index && formattedNumber[index] !== " ") {
      formattedNumber = formattedNumber.slice(0, index) + " " + formattedNumber.slice(index);
    }
  });

  return formattedNumber;
}
