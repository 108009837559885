import React from "react";
import Box from "@material-ui/core/Box";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Skeleton from "@material-ui/lab/Skeleton";

export default function LoadingRows({ rows, columns }) {

  function selectType(item) {
    switch (item) {
      case "avatar":
        return (
          <Box
            display="flex"
            justifyContent="center"
            paddingRight="10px"
          >
            <Skeleton
              animation="wave"
              variant="circle"
              height={34}
              width={34}
            />
          </Box>
        );
      case "radio":
        return (
          <Box
            id="avatar"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            padding="4px 0px 4px 0px"
          >
            <Skeleton
              animation="wave"
              variant="circle"
              height={24}
              width={24}
            />
          </Box>
        );

      case "checkbox":
        return (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            paddingLeft="8px"
          >
            <Skeleton variant="rect" width={18} height={18} />
          </Box>
        );
      default:
        return (
          <Skeleton variant="text" animation="wave" height={30} width="auto" />
        );
    }
  }

  function generateRow() {
    return (
      <TableRow key={Math.random().toString()}>
        {columns.map((item, index) => (
          <TableCell
            key={Math.random().toString()}
            style={{ padding: "2px 8px 2px 8px", lineHeight: "22px" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              {selectType(item)}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    );
  }
  let result = [];
  for (let index = 0; index < rows; index++) {
    result.push(generateRow());
  }
  return result;
}
