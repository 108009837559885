import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { InlineIcon } from "@iconify/react";
import keyIcon from "@iconify/icons-mdi/key";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import useOcProperty from "hooks/use-oc-property";
import { useTheme } from "@material-ui/core/styles";

export default function OcPasswordInputField({
  value,
  onChange,
  error,
  setError,
  helperText,
  setHelperText,
  label,
}) {
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const { data: passwordVisibilityData } = useOcProperty({
    resource: "system",
    key: "passwordVisibility",
  });

  return (
    <TextField
      label={label}
      variant="outlined"
      type={showPassword ? "text" : "password"}
      fullWidth
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      autoComplete={label}
      onFocus={() => {
        setError(false);
        setHelperText("");
      }}

      InputProps={{
        endAdornment: (
          <InputAdornment position="end" height="24px">
            <InlineIcon icon={keyIcon} height="24px" />
            <Box width="8px" />
            {passwordVisibilityData === "true" && (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                style={{ backgroundColor: theme.palette.gray20.main }}
              >
                {showPassword ? (
                  <VisibilityOff style={{ height: "24px", width: "24px" }} />
                ) : (
                  <Visibility style={{ height: "24px", width: "24px" }} />
                )}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}
