import { useMutation } from "@apollo/react-hooks";
import { USERS_TO_MY_DOCUMENTS_PAGES } from "graphql/mutations";

export default function useUsersToMyDocumentsPages({
  roleFilters,
  tableData,
  onCompleted,
  role,
}) {

  delete roleFilters.__typename;

  let queryParams = {
    roleFilters: roleFilters,
    sortField: tableData?.sortField,
    sortDirection: tableData?.sortDirection,
    role,
    page: tableData?.page,
    pageSize: tableData?.pageSize,
  };

  if (tableData?.searchField && tableData?.searchText) {
    queryParams.searchField = tableData?.searchField;
    queryParams.searchText = tableData?.searchText;
  }

  const [loadQuery, { data, loading, error }] = useMutation(USERS_TO_MY_DOCUMENTS_PAGES, {
    variables: queryParams,
    onCompleted: (data) => {
      let result = data?.usersToMyDocumentsPages;
      onCompleted(result ? result : []);
    },
  });

  let result = data?.usersToMyDocumentsPages;

  return {
    data: result ? result : [],
    loading,
    error,
    loadQuery,
  };
}
