import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import MyTimeWorkingFrameBox from "my-time/components/my-time-working-frame-box";
import useFramePeriod from "my-time/hooks/use-frame-period";

const MyTimeRemainsFrame = ({ contractCode, year, month, minHeight }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { data, loading } = useFramePeriod({
    contractCode,
    year: year,
    month: month,
  });

  const formatValue = (value) => {
    if (value % 1 !== 0) {
      const formattedValue = value?.toFixed(3);
      return formattedValue?.endsWith('00') ? parseFloat(formattedValue)?.toFixed(1) : formattedValue;
    } else {
      return value?.toString();
    }
  };

  const remainderTimeFrame = data?.hoursByLaw - data?.hoursByFact;

  const effectiveRemainderTimeFrame =
    remainderTimeFrame < 0 ? Math.abs(remainderTimeFrame) : remainderTimeFrame;

  const remainderTimeFramLabel =
    remainderTimeFrame < 0 ? t("EXTRA_WORK") : t("REMAINDER_TIME_FRAME");

  return (
    <Box>
      <MyTimeWorkingFrameBox
        label={remainderTimeFramLabel}
        value={formatValue(effectiveRemainderTimeFrame)}
        color={theme.palette.yellowBase.main}
        loading={loading}
        minHeight={minHeight}
      />
    </Box>
  );
};

export default MyTimeRemainsFrame;
