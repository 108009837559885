import gql from "graphql-tag";

export const GET_MY_TIME_MESSAGES_LIST = gql`
  query getMyTimeMessagesList($request: String!) {
    getMyTimeMessagesList(request: $request) {
      _id
      request
      message
      createdAt
      createdBy
      employeeName
    }
  }
`;
