import React from "react";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";

export default function OcPrivateLayoutSidebarChevron({ icon, color }) {
  const iconSize = "24px";
  return (
    <Box minWidth={iconSize} maxWidth={iconSize} paddingTop="4px">
      <InlineIcon icon={icon} width={iconSize} color={color} />
    </Box>
  );
}
