import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import deleteIcon from "@iconify/icons-mdi/delete";
import { InlineIcon } from "@iconify/react";
import { useTheme } from "@material-ui/core/styles";
import textBoxIcon from "@iconify/icons-mdi/text-box";
import OcSwitch from "oc/components/oc-form-switch";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";
import OcCard from "oc/components/oc-card";
import OcPageTransition from "oc/components/oc-page-transition";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";

export default function MyDocumentsEditFile({ document, removeUpload, updateUpload, onClose }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [description, setDescription] = useState(document?.description || '');
  const [userView, setUserView] = useState(document?.userView);

  const [prevDescription, setPrevDescription] = useState(document?.description || '');
  const [prevUserView, setPrevUserView] = useState(document?.userView);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

  const handleRemoveUpload = () => {
    setShowConfirmDeleteDialog(true);
  };

  const handleConfirmDelete = () => {
    removeUpload(document);
    onClose();
  };

  const handleSave = () => {
    const updatedDescription = description !== prevDescription ? description : prevDescription;
    const updatedUserView = userView !== prevUserView ? (userView === 1 ? true : false) : prevUserView;
    updateUpload({ variables: { _id: document._id, description: updatedDescription, userView: updatedUserView } });
    setPrevDescription(updatedDescription);
    setPrevUserView(updatedUserView);
    onClose();
  };

  const handleCancel = () => {
    setDescription(prevDescription);
    setUserView(prevUserView);
    onClose();
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleUserViewChange = (e) => {
    setUserView(e.target.checked ? 1 : 0);
  };

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={() => {
          handleSave();
        }}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <OcConfirmationDialog
        showDialog={showConfirmDeleteDialog}
        onClose={handleConfirmDelete}
        onCancel={() => setShowConfirmDeleteDialog(false)}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <OcPageTransition>
        <OcCard label={t("EDIT_DOCUMENT")}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              key={document?._id}
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{
                width: "459px",
                backgroundColor: theme.palette.gray20.main,
                padding: "16px 20px",
                borderRadius: "8px",
                border: "1px solid #C6CACC",
              }}
            >
              <Box>
                <InlineIcon
                  icon={textBoxIcon}
                  width="24px"
                  style={{ color: "#4d4d4d" }}
                />
              </Box>
              <Box sx={{ width: "100%", padding: "0px 16px" }}>
                <Typography variant="body1">{document?.filename}</Typography>
              </Box>
              <Box>
                <InlineIcon
                  id="delete-icon"
                  icon={deleteIcon}
                  width="24px"
                  style={{
                    color: "#4d4d4d",
                    cursor: "pointer",
                  }}
                  onClick={handleRemoveUpload}
                />
              </Box>
              <Box height="32px" />
            </Box>
            <Box height="32px" />
            <TextField
              id="designation"
              value={description}
              variant="outlined"
              label={t("DESIGNATION")}
              type="string"
              onChange={handleDescriptionChange}
              style={{ width: "500px" }}
            />
            <Box height="32px" />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box display="flex" alignItems="center" width="500px">
              <OcSwitch
                id="employeeVisibility"
                checked={userView}
                inputProps={{ "aria-label": "controlled" }}
                onChange={handleUserViewChange}
                disabled={false}
              />
              <Box width="8px" />
              <Typography>{t("EMPLOYEE_VISIBILITY")}</Typography>
            </Box>
          </Box>
          <OcCardButtonBar
            handleRightButton={handleCancel}
            rightButtonText={t("CANCEL")}
            handlePrimaryButton={() => setShowConfirmDialog(true)}
            primaryButtonText={t("SAVE")}
            primaryButtonDisabled={description === prevDescription && userView === prevUserView}
          />
        </OcCard >
      </OcPageTransition>
    </>
  );
}
