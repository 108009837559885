import React, { useState } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import Button from "@material-ui/core/Button";
import useOcClientState from "hooks/use-oc-client-state-2";
import Collapse from "@material-ui/core/Collapse";
import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/styles";
import { InlineIcon } from "@iconify/react";
import imageIcon from "@iconify/icons-mdi/image-size-select-actual";
import fileIcon from "@iconify/icons-mdi/file-document";
import pdfIcon from "@iconify/icons-mdi/file-pdf-box-outline";
import { Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import deleteIcon from "@iconify/icons-mdi/delete";
import axios from "axios";
import useCafeteriaRegulation from "hooks/use-cafeteria-regulation";
import { DELETE_ASSET } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import useCurrentUserState from "hooks/use-current-user-state";
import useOcSnackbar from "hooks/use-oc-snackbar";

let apiUrl = "http://localhost:9000/api/upload";

if (process.env.NODE_ENV === "production") {
  apiUrl = "/api/upload";
}

// const controller = new AbortController();

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "unset",
    height: 152,
    borderRadius: 8,
    color: theme.palette.gray60.main,
  },
  muiSvgIcon: {
    marginTop: 24,
    color: theme.palette.gray60.main,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
  },
  text: {
    fontSize: 16,
    marginTop: 16,
    marginBottom: 24,
    color: theme.palette.gray60.main,
    "&:after": {
      content: "'ide'",
      color: "red",
      transform: "translateX(-100%)",
      position: "absolute",
    },
  },
  input: {
    width: "360px",
    height: "4px",
  },
}));

const menuDef = [
  {
    id: "uploads",
    label: "UPLOADS",
  },
];

function PageSideMenu() {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useOcClientState(
    "myCafeteriaSettingsPageMenuActiveItem",
    menuDef[0].id
  );

  function handleSelect(itemId) {
    setSelectedItem(itemId);
  }

  return (
    <Box>
      {menuDef.map((item) => (
        <Box
          key={item.id}
          width="240px"
          height="40px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="40px"
          onClick={() => handleSelect(item.id)}
          style={
            selectedItem === item.id
              ? {
                backgroundColor: "white",
                borderLeft: "4px solid #cc0033",
              }
              : { cursor: "pointer" }
          }
        >
          <Box style={{ fontSize: "14px", letterSpacing: "0.25px" }}>
            {t(item.label)}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default function MyCafeteriaAdminSettingsPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  let { employeeCode } = useCurrentUserState();

  const [selectedItem] = useOcClientState(
    "myCafeteriaSettingsPageMenuActiveItem",
    menuDef[0].id
  );

  const { data } = useCafeteriaRegulation();
  const [showCollapse, setShowCollapse] = useState(false);
  const [file, setFile] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  let dataArray = data?.filename?.split("/") || [];
  const filename = dataArray[dataArray?.length - 1] || "";
  const createdAt = new Date(data?.createdAt).toLocaleDateString();

  const { setOpenMessage } = useOcSnackbar();

  const [deleteAsset] = useMutation(DELETE_ASSET, {
    onCompleted: (data) => {
      setOpenMessage({
        type: "success",
        message: t("VBK_DELETE_SUCCEEDED"),
      });
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  function showUpload() {
    setShowCollapse(true);
  }

  async function handleUpload() {
    setIsUpload(true);
    const formData = new FormData();
    formData.append("employeeCode", "");
    formData.append("type", "vbkRegulations");
    formData.append("owner", employeeCode);
    formData.append("file", file);
    try {
      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      await axios.post(apiUrl, formData, config);
      setIsUpload(false);
      setFile(undefined);
      setShowCollapse(false);
      setUploadProgress(0);
      setOpenMessage({
        type: "success",
        message: t("VBK_UPLOAD_SUCCEEDED"),
      });
    } catch (error) {
      console.log(error);
    }
  }

  function handleSelectFile(files) {
    const selectedFile = files[0];
    if (selectedFile?.size > 10000000) {
      setOpenMessage({
        type: "error",
        message: "FILE_UPLOAD_SIZE_FAILED",
      });
      return;
    }
    setFile(files[0]);
  }

  if (file?.size > 10000000) {
    setOpenMessage({
      type: "error",
      message: t("hello"),
    });
  }

  function abortUpload() {
    setIsUpload(false);
  }

  function cancelUpload() {
    setIsUpload(false);
    setShowCollapse(false);
    setFile(null);
  }

  function removeVBKRegulation() {
    deleteAsset({ variables: { _id: data?._id } });
  }

  return (
    <OcPageTransition>
      <Box>
        <Box
          id="my-hr-admin-system-settings-title"
          height="80px"
          style={{ backgroundColor: "#cc0033" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="14px"
        >
          <Box style={{ color: "white", fontSize: "24px" }}>MyCafeteria</Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <PageSideMenu />
          <Box id="my-hr-admin-system-settings" flexGrow={1} padding="16px">
            {selectedItem === "uploads" && (
              <OcCard label={t("VBK_REGULATION")}>
                <>
                  <Collapse
                    in={data?.filename ? true : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box
                      height="78px"
                      display="flex"
                      flexDirection={"row"}
                      alignItems="center"
                      style={{
                        backgroundColor: "#F1F3F4",
                        border: "1px solid #C6CACC",
                        borderRadius: "8px",
                      }}
                    >
                      <Box width="14px" />
                      <InlineIcon
                        icon={pdfIcon}
                        width="24px"
                        style={{ color: "#4d4d4d" }}
                      />
                      <Box width="14px" />

                      <Box display="flex" flexGrow={1}>
                        <Box>
                          <Typography variant="body1">{filename}</Typography>
                          <Typography
                            variant="caption"
                            style={{
                              color: "#919699",
                            }}
                          >
                            {createdAt}
                          </Typography>
                        </Box>
                      </Box>
                      <Box paddingRight={"16px"}>
                        <InlineIcon
                          id="remove-VBK-regulation-button"
                          icon={deleteIcon}
                          width="24px"
                          style={{
                            color: "#4d4d4d",
                            cursor: "pointer",
                          }}
                          onClick={removeVBKRegulation}
                        />
                      </Box>
                    </Box>
                    <Box height="16px"></Box>
                  </Collapse>
                  <Collapse in={showCollapse} timeout="auto" unmountOnExit>
                    <Box id="dropzonearea">
                      <DropzoneArea
                        classes={{
                          root: classes.root,
                          icon: classes.muiSvgIcon,
                          textContainer: classes.textContainer,
                          text: classes.text,
                        }}
                        dropzoneText={t("DRAG_AND_DROP_FILE")}
                        acceptedFiles={[".pdf"]}
                        filesLimit={1}
                        maxFileSize={1000000000}
                        showPreviewsInDropzone={false}
                        showFileNames={true}
                        showAlerts={false}
                        onChange={handleSelectFile}
                      />
                      <Collapse in={file && !isUpload}>
                        <Box height="32px" />
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems={"center"}
                          style={{
                            height: "61px",
                            border: "1px solid #C6CACC",
                            borderRadius: "8px",
                            paddingLeft: "20px",
                          }}
                        >
                          <InlineIcon
                            icon={fileIcon}
                            width="24px"
                            style={{
                              color: "#4d4d4d",
                            }}
                          />
                          <Box width="14px" />
                          <Typography variant="body1">{file?.name}</Typography>
                        </Box>
                      </Collapse>
                      <Collapse in={file && isUpload}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          style={{
                            height: "102px",
                            backgroundColor: "#C6CACC",
                            padding: "16px",
                            borderRadius: "8px",
                            marginBottom: "32px",
                          }}
                        >
                          <Box>
                            <InlineIcon
                              icon={imageIcon}
                              width="24px"
                              style={{
                                color: "#4d4d4d",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              padding: "0px 16px",
                            }}
                          >
                            <Typography variant="body1">
                              {file?.name || "nincs kiválasztva"}
                            </Typography>
                            <Box padding={"16px 0px"}>
                              <LinearProgress
                                variant="determinate"
                                value={uploadProgress}
                                color="primary"
                              />
                            </Box>
                            <Typography
                              variant="caption"
                              style={{
                                color: "#919699",
                              }}
                            >
                              {uploadProgress}% done
                            </Typography>
                          </Box>
                          <Box>
                            <InlineIcon
                              icon={deleteIcon}
                              width="24px"
                              style={{
                                color: "#4d4d4d",
                              }}
                              onClick={abortUpload}
                            />
                          </Box>
                        </Box>
                      </Collapse>
                      <Box height="32px" />
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                      >
                        <Button
                          id="new-regulation-cancel-button"
                          text={t("Common.cancel")}
                          onClick={cancelUpload}
                        >
                          {t("Common.cancel")}
                        </Button>
                        <Box width="16px" />
                        <Button
                          id="new-regulation-handle-upload-button"
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={handleUpload}
                          disabled={!file}
                        >
                          {t("SAVE")}
                        </Button>
                      </Box>
                    </Box>
                  </Collapse>
                  <Collapse in={!showCollapse} timeout="auto" unmountOnExit>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-end"
                      padding="16px 0px"
                    >
                      <Button
                        id="new-regulation-show-upload-button"
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={showUpload}
                      >
                        {t("NEW_REGULATION")}
                      </Button>
                    </Box>
                  </Collapse>
                </>
              </OcCard>
            )}
          </Box>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
