import React, { useEffect, useState } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import AllLeavesCard from "my-leave/components/my-leave-all-leaves-card";
import AllLeavesWithCoverage from "my-leave/components/my-leave-all-leaves-with-coverage-card";
import useClientState from "hooks/use-oc-client-state";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useUsersWithRequests from "hooks/use-my-leave-users-with-requests";
import { useTranslation } from "react-i18next";
import { currentDateState } from "states";
import { useRecoilState } from "recoil";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { InlineIcon } from "@iconify/react";
import alertIcon from "@iconify/icons-mdi/alert-circle-outline";
import useLeaveEvents from "hooks/use-my-leave-events";
import useCurrentUserState from "hooks/use-current-user-state";
import useRoleFilters from "hooks/use-role-filters.js";

export default function ApproveLeaveRequests() {
  const { t } = useTranslation();
  const history = useHistory();
  const [currentDate, setCurrentDate] = useRecoilState(currentDateState);
  const [showDialog, setShowDialog] = useState(false);

  let { contractCode: contract } = useCurrentUserState();

  const {
    roleFilters,
    prevRoleFilters,
    resetRoleFilters,
    setPrevRoleFilters,
    handleAddFilter,
    handleRemoveFilter,
  } = useRoleFilters({
    contract,
    product: "MyLeave",
    role: "examiner",
  });

  const [onlyRequested, setOnlyRequested] = useClientState(
    "showOnlyRequestedLeaves",
    true
  );

  const [prevOnlyRequested, setPrevOnlyRequested] = useClientState(
    "prevShowOnlyRequestedLeaves",
    true
  );

  const [filterable, setFilterable] = useClientState("allLeavesFilter", false);

  const [filterItemErrors, setFilterItemErrors] = useState([]);

  let tableId = "allLeavesTableData";
  initialTableData.sortField = "name";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);
  const [prevTableData, setPrevTableData] = useClientState(
    `${tableId}Prev`,
    initialTableData
  );

  const [showRemoteWorks, setShowRemoteWorks] = useClientState(
    "showRemoteWorks",
    true
  );

  const [prevShowRemoteWorks, setPrevShowRemoteWorks] = useClientState(
    "prevShowRemoteWorks",
    true
  );

  const handleCompleted = (data) => {
    handleChangeFilterable(false);
  };

  const {
    loadQuery: loadUsers,
    data,
    loading,
  } = useUsersWithRequests({
    onlyRequested,
    roleFilters,
    currentDate,
    filterable,
    tableData,
    showRemoteWorks,
    role: "examiner",
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("year").add(1, "year").toDate(),
    onCompleted: handleCompleted,
    contract,
  });

  const {
    loadEvents,
    data: leavesData,
    loading: leaveEventsLoading,
    eventsByDays,
  } = useLeaveEvents({
    roleFilters,
    currentDate: moment(currentDate).toDate(),
    viewedByAdmin: false,
    contract: contract,
  });

  const MAX_ITEM_COUNT = 1000;
  const generateRoleFilterError = () => {
    const newState = [...filterItemErrors];
    if (roleFilters?.company?.length > MAX_ITEM_COUNT) {
      newState.push(t("COMPANY"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.orgUnit?.length > MAX_ITEM_COUNT) {
      newState.push(t("ORGUNIT"));
      setFilterItemErrors(newState);
    }

    if (roleFilters?.workingPlace?.length > MAX_ITEM_COUNT) {
      newState.push(t("WORKINGPLACE"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.jobClassGroup?.length > MAX_ITEM_COUNT) {
      newState.push(t("JOBCLASSGROUP"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.payOffice?.length > MAX_ITEM_COUNT) {
      newState.push(t("PAYOFFICE"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.contract?.length > MAX_ITEM_COUNT) {
      newState.push(t("GET_EMPLOYEE"));
      setFilterItemErrors(newState);
    }

    return newState?.length > 0 ? newState : false;
  };

  const handleLoadQuery = () => {
    const isItemError = generateRoleFilterError();
    if (isItemError) {
      setShowDialog(true);
      handleChangeFilterable(false);
      return;
    }
    loadUsers();
    loadEvents();
    setPrevRoleFilters(roleFilters);
    setPrevShowRemoteWorks(showRemoteWorks);
    setPrevOnlyRequested(onlyRequested);
  };

  const buttonDisable =
    _.isEqual(roleFilters, prevRoleFilters) &&
    showRemoteWorks === prevShowRemoteWorks &&
    onlyRequested === prevOnlyRequested;

  function handleChangeFilterable(state) {
    setFilterable(state);
  }

  function onSelectRow(o) {
    history.push(`/my-leave/user-requests/MyLeave/examiner/${o.contract}`);
  }

  function handleOnlyReqestedChange(state) {
    setPrevOnlyRequested(onlyRequested);
    setOnlyRequested(state);
  }

  function handleCurrentDateChange(date) {
    setCurrentDate(date);
  }

  function handleTableDataChange(state) {
    const keys = Object.keys(state).toString();
    setTableData(state);
    if (
      keys === "searchField" ||
      (keys === "searchText" && state?.searchText?.length < 3)
    ) {
      return;
    }
    loadUsers();
    loadEvents();
  }

  function handleShowRemoteWorksChange(event) {
    setShowRemoteWorks(event.target.checked);
  }

  const handleClearFilters = () => {
    resetRoleFilters();
    handleChangeFilterable(false);
    setFilterItemErrors([]);
  };

  function handleEventSelected(event) {
    let { _id, contract } = event;
    let url = `/my-leave/user-request/MyLeave/examiner/${contract}/${_id}`;
    history.push(url);
  }

  const isTabledataChanged = !_.isEqual(tableData, prevTableData);
  useEffect(() => {
    if (isTabledataChanged) {
      handleLoadQuery();
      setPrevTableData(tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabledataChanged]);

  const handleDialogClose = () => {
    setShowDialog(false);
    setFilterItemErrors([]);
  };

  useEffect(() => {
    loadUsers();
    loadEvents();
    setPrevRoleFilters(roleFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  return (
    <OcPageTransition>
      <Box>
        <Dialog aria-labelledby="simple-dialog-title" open={showDialog}>
          <Box width="476px" padding="16px">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              paddingBottom="16px"
            >
              <Box paddingRight="20px">
                <InlineIcon icon={alertIcon} width="30px" color="#cc0033" />
              </Box>
              <Box fontSize="16px" letterSpacing="0.44px" color="#5E6366">
                {t("TOO_MANY_HITS")}
              </Box>
            </Box>
            {filterItemErrors?.length > 0 && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                paddingBottom="16px"
              >
                <Box fontSize="16px" letterSpacing="0.44px" color="#5E6366">
                  ({filterItemErrors?.toString()})
                </Box>
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              onClick={handleDialogClose}
            >
              OK
            </Button>
          </Box>
        </Dialog>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Box padding="16px">
            <AllLeavesCard
              buttonDisable={buttonDisable}
              contract={contract}
              currentDate={currentDate}
              data={data}
              examinerDasboard={false}
              filterable={filterable}
              label={t("LEAVE_AND_ABSENCE_REQUESTS")}
              loading={loading}
              monthFiltering={false}
              onAddFilter={handleAddFilter}
              onClearFilters={handleClearFilters}
              onCurrentDateChange={handleCurrentDateChange}
              onFilterableChange={handleChangeFilterable}
              onLoadQuery={handleLoadQuery}
              onlyRequested={onlyRequested}
              onOnlyRequestedChange={handleOnlyReqestedChange}
              onRemoveFilter={handleRemoveFilter}
              onSelectRow={onSelectRow}
              onShowRemoteWorksChange={handleShowRemoteWorksChange}
              onTableDataChange={handleTableDataChange}
              product={"MyLeave"}
              role={"examiner"}
              roleFilters={roleFilters}
              showRemoteWorks={showRemoteWorks}
              tableData={tableData}
              tableId={tableId}
            />
            <Box height="16px" />
            <AllLeavesWithCoverage
              cardTitle={t("LEAVES_AND_REMOTE_WORKS")}
              currentDate={currentDate}
              onCurrentDateChange={handleCurrentDateChange}
              roleFilters={roleFilters}
              onEventSelected={handleEventSelected}
              showRemoteWorks={showRemoteWorks}
              viewedByAdmin={false}
              leavesData={leavesData}
              eventsByDays={eventsByDays}
              loading={leaveEventsLoading}
            />
          </Box>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
