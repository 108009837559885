import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./my-time-menu-badges.style";
import useMyTimeRequestCount from "my-time/hooks/use-my-time-request-count";
const { useRecoilValue } = require("recoil");
const { customerLicencesState } = require("states");

function MyTimeMenuBadges({ value, urgent }) {
  const classes = useStyles();
  if (!value) return null;

  return (
    <Box
      width={value > 100 ? "unset" : "16px"}
      height="16px"
      display={value < 100 ? "flex" : "unset"}
      className={classes.root}
    >
      <Box
        id="myTimeMenuBadge"
        fontSize="10px"
        fontFamily="Roboto"
        lineHeight="16px"
        color={urgent ? "#cc0033" : "#212121"}
        fontWeight="bold"
        textAlign="center"
        width="20px"
      >
        {value > 99 ? "99+" : value}
      </Box>
    </Box>
  );
}

export default function MenuBadges({ urgent }) {
  const { data } = useMyTimeRequestCount();
  const licences = useRecoilValue(customerLicencesState);
  if (licences && licences?.myTime === false) return null;
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <MyTimeMenuBadges value={data} urgent={true} />
    </Box>
  );
}
