import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

const OcCardButtonBar = ({
  handleLeftButton,
  leftButtonColor,
  leftButtonText,
  leftButtonDisabled,
  leftButtonId = "handleLeftButton",
  handleRightButton,
  rightButtonColor,
  rightButtonText,
  rightButtonDisabled,
  rightButtonId = "handleRightButton",
  handlePrimaryButton,
  primaryButtonText,
  primaryButtonDisabled,
  primaryButtonId = "primaryButtonText",
}) => {

  return (
    <Box>
      <Box paddingTop="32px" style={{ borderBottom: "1px solid #C6CACC" }} />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        paddingTop="16px"
      >
        {leftButtonColor ? (
          <Button
            id={leftButtonId}
            variant="text"
            color={leftButtonColor}
            disabled={leftButtonDisabled}
            onClick={handleLeftButton}
          >
            {leftButtonText}
          </Button>
        ) : (
          <Box />
        )}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Button
            id={rightButtonId}
            variant="text"
            color={rightButtonColor}
            disabled={rightButtonDisabled}
            onClick={handleRightButton}
          >
            {rightButtonText}
          </Button>
          {primaryButtonText &&
            <>
              <Box width="16px" />
              <Button
                id={primaryButtonId}
                variant="contained"
                color="primary"
                disableElevation
                disabled={primaryButtonDisabled}
                onClick={handlePrimaryButton}
              >
                {primaryButtonText}
              </Button>
            </>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default OcCardButtonBar;
