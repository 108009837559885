import React from "react";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import _ from "lodash";
import useClientState from "hooks/use-oc-client-state";
import Table from "oc/components/oc-data-table";
import getSortedList from "oc/utils/get-sorted-list";
import { myFulfilledLeaves as tableDef } from "my-leave/configs/my-leave-table-defs";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import getFilterValues from "oc/utils/get-filter-values";
import { GET_LEAVES_BY_CONTRACT } from "graphql/queries";
import { LEAVES_BY_CONTRACT_SUBSCRIPTION } from "graphql/subcriptions";
import useCurrentUserState from "hooks/use-current-user-state";
import usePolling from "hooks/use-polling";

const MyFulfilledLeavesContainer = ({ selectedYear }) => {
  let { contractCode } = useCurrentUserState();

  const { data, loading, refetch } = useQuery(GET_LEAVES_BY_CONTRACT, {
    skip: !contractCode || !selectedYear,
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    variables: { contract: contractCode, year: selectedYear },
  });

  useSubscription(LEAVES_BY_CONTRACT_SUBSCRIPTION, {
    skip: !contractCode || !selectedYear,
    variables: { contract: contractCode, year: selectedYear },
    onData: () => {
      refetch();
    },
  });

  let myFulfilledLeaves = [];

  if (data && data.leavesByContract) {
    myFulfilledLeaves = _.cloneDeep(data.leavesByContract);
    myFulfilledLeaves.map((item) => {
      //item.type = t(`Leave.${item.type}`);
      return item;
    });
  }

  let tableId = "myFulfilledLeaves";

  initialTableData.sortField = "startDate";
  initialTableData.pageSize = 5;
  initialTableData.filterField = "state";
  initialTableData.filterSelected = [];
  initialTableData.filterValues = [];

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  myFulfilledLeaves.sort(
    getSortedList(
      tableData?.sortDirection === "desc"
        ? tableData?.sortField
        : `-${tableData?.sortField}`
    )
  );

  let list = {};
  list.total = myFulfilledLeaves?.length || 0;
  list.page = tableData?.page;
  list.pageSize = tableData?.pageSize || initialTableData.pageSize;
  list.rows = myFulfilledLeaves || [];

  if (tableData?.filterField) {
    let filterValues = getFilterValues(tableData?.filterField, list.rows);
    if (!_.isEqual(filterValues && tableData?.filterValues)) {
      setTableData({
        filterValues,
      });
    }
  }

  if (tableData?.filterSelected.length > 0) {
    list.rows = list.rows.filter(function (item) {
      let key = item[tableData?.filterField];
      return tableData?.filterSelected.includes(key);
    });
  }

  return (
    <Table
      data={list}
      loading={loading && data?.rows?.length === 0 ? true : false}
      tableDef={tableDef}
      tableData={tableData}
      setTableData={setTableData}
      pagination={true}
      localPaging={true}
    />
  );
};

export default MyFulfilledLeavesContainer;
