import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import usePermissionForm from "hooks/use-myhr-permission-form";
import TextField from "@material-ui/core/TextField";
import OcDatePicker from "oc/components/oc-date-picker";
import { InlineIcon } from "@iconify/react";
import emailIcon from "@iconify/icons-mdi/email";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Typography } from "@material-ui/core";
import getDateFormatByLocale from "oc/utils/get-date-format-by-locale";

export default function MyHrAddPermissionForm({
  user,
  employee,
  setUser,
  dateError,
}) {
  const {
    email,
    onChangeEmail,
    onChangeValidFrom,
    onClearError,
    validFrom,
    validTo,
    onChangeValidTo,
  } = usePermissionForm();

  const { t, i18n } = useTranslation();

  if (email?.value !== user?.email) {
    setUser({ email: email.value });
  }

  if (validFrom?.value !== user?.validFrom) {
    setUser({ validFrom: validFrom?.value });
  }

  if (validTo?.value !== user?.validTo) {
    setUser({ validTo: validTo?.value });
  }

  const [goBack, setGoBack] = React.useState(true);

  const workEmail = employee?.emails?.find((item) => item.type === "work");

  if (email.value === "" && workEmail?.address && goBack) {
    onChangeEmail(workEmail.address);
    setGoBack(false);
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box width="432px" paddingTop="12px">
        <form noValidate autoComplete="off">
          <Box marginBottom="36px">
            <TextField
              id="outlined-basic"
              label={t("EMAIL")}
              variant="outlined"
              value={email?.value || ""}
              onChange={(e) => onChangeEmail(e.target.value)}
              fullWidth
              error={email.error}
              helperText={email.error ? email.errorText : ""}
              onFocus={onClearError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InlineIcon height="24px" icon={emailIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box marginBottom="36px">
            <OcDatePicker
              error={validFrom.error}
              helperText={validFrom.error && t("Leave.notSelectable")}
              id="validFrom"
              label={t("VALID_FROM")}
              minDate={new Date()}
              minDateMessage={t("Leave.notSelectable")}
              onChange={onChangeValidFrom}
              value={validFrom.value || null}
              format={getDateFormatByLocale(i18n.language)}
            />
          </Box>
          <Box marginBottom="12px">
            <OcDatePicker
              error={validTo.error}
              helperText={
                validTo.error ? t("Leave.notSelectable") : t("OPTIONAL")
              }
              id="validTo"
              label={t("VALID_TO")}
              minDate={validFrom.value}
              minDateMessage={t("Leave.notSelectable")}
              onChange={onChangeValidTo}
              value={validTo.value || null}
              format={getDateFormatByLocale(i18n.language)}
            />
          </Box>
          <Box height="20px" />
          {dateError && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              paddingTop="24px"
              paddingBottom="12px"
            >
              <Typography variant="body1" color="primary">
                {t("DATE_RANGE_ERROR")}
              </Typography>
            </Box>
          )}
        </form>
      </Box>
    </Box>
  );
}
