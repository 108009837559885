import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Avatar from "oc/components/oc-avatar";
import Typography from "@material-ui/core/Typography";
import getFullname from "oc/utils/get-fullname";
import cityIcon from "@iconify/icons-mdi/city";
import siteMapIcon from "@iconify/icons-mdi/sitemap";
import creditCardIcon from "@iconify/icons-mdi/credit-card-marker-outline";
import fingerprintIcon from "@iconify/icons-mdi/fingerprint";
import fileIcon from "@iconify/icons-mdi/file-account";
import accountPlusIcon from "@iconify/icons-mdi/account-plus";
import accountClockIcon from "@iconify/icons-mdi/account-clock";
import deskphoneIcon from "@iconify/icons-mdi/deskphone";
import cellphoneIcon from "@iconify/icons-mdi/cellphone-iphone";
import atIcon from "@iconify/icons-mdi/at";
import homeAccountIcon from "@iconify/icons-mdi/home-account";
import useClientState from "hooks/use-oc-client-state";
import ContractCardItem from "my-hr/components/my-hr-contract-card-item";
import OcErrorBoundary from "oc/components/oc-error-boundary";

function PhoneCardItem({ item }) {
  if (item.type === "work") {
    return <ContractCardItem icon={deskphoneIcon} label={item?.number} />;
  } else if (item.type === "workMobile") {
    return <ContractCardItem icon={cellphoneIcon} label={item?.number} />;
  }
  return null;
}

export default function MyHrUserContractCard({ employee, contract }) {
  const [textDivHeight, setTextDivHeight] = useClientState(
    "textDivHeight",
    185
  );
  const TextRef = React.createRef();

  useEffect(() => {
    let height = TextRef?.current?.clientHeight;
    if (height && textDivHeight !== height) setTextDivHeight(height);
  });

  let jobClassName =
    contract?.jobClass?.name.charAt(0).toUpperCase() +
    contract?.jobClass?.name.slice(1) || "";

  let position =
    contract?.position.charAt(0).toUpperCase() +
    contract?.position.slice(1) || "";


  //let showStartOfContract =contract?.employmentType?.code === "2" ? false : true;

  return (
    <React.Fragment>
      <OcErrorBoundary>
        <Box padding="16px" id="my-hr-user-contract-card-data">
          <Box
            display="flex"
            flexDirection="row"
            style={{ width: "100%" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              width="300px"
              marginRight="26px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                employee={employee?.code}
                style={{
                  width: textDivHeight,
                  height: textDivHeight,
                }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              ref={TextRef}
            >
              <Box display="flex" flexDirection="row" flexGrow={1}>
                <Box
                  display="flex"
                  id="bump"
                  flexGrow={1}
                  style={{
                    //width: "auto",
                    borderBottom: "1px solid #5E6366",
                    paddingBottom: "9px",
                  }}
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box textAlign="center" style={{}}>
                    <Typography variant="h4">
                      {getFullname(employee)}
                    </Typography>
                    <Typography variant="h5">{jobClassName}</Typography>
                    <Typography variant="h5">{position}</Typography>
                  </Box>
                </Box>
                <Box
                  id="bamppp"
                  display="flex"
                  width="300px"
                  style={{
                    borderBottom: "1px solid #5E6366",
                  }}
                >
                  <Box>
                    {employee?.phones?.map((item, index) => {
                      return (
                        <PhoneCardItem
                          key={`${item.type} ${index}`}
                          item={item}
                        />
                      );
                    })}
                    {employee?.emails?.map((item, index) => {
                      if (item.type !== "work") return null;
                      return (
                        <ContractCardItem
                          key={`${item.type} ${index}`}
                          icon={atIcon}
                          label={item?.address}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <Box id="ddd" display="flex" flexDirection="row" flexGrow="1">
                <Box id="bopp" display="flex" flexGrow={1}>
                  <Box paddingTop="6px">
                    <ContractCardItem
                      icon={cityIcon}
                      label={contract?.company?.name}
                    />
                    <ContractCardItem
                      icon={homeAccountIcon}
                      label={contract?.workingPlace?.name}
                    />
                    <ContractCardItem
                      icon={siteMapIcon}
                      label={contract?.orgUnit?.name}
                    />
                    <ContractCardItem
                      icon={creditCardIcon}
                      label={contract?.payOffice?.name}
                    />
                  </Box>
                </Box>
                <Box width="300px">
                  <Box paddingTop="6px">
                    <ContractCardItem
                      icon={fingerprintIcon}
                      label={employee?._id}
                    />
                    <ContractCardItem icon={fileIcon} label={contract?._id} />
                    <ContractCardItem
                      icon={accountPlusIcon}
                      label={
                        contract?.startOfContract
                          ? new Date(
                            contract?.startOfContract
                          ).toLocaleDateString()
                          : ""
                      }
                    />
                    <ContractCardItem
                      icon={accountClockIcon}
                      label={contract?.employmentType?.name}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </OcErrorBoundary>
    </React.Fragment>
  );
}
