import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import MyTimePageHeader from "my-time/components/my-time-page-header";
import OcPageTransition from "oc/components/oc-page-transition";
import useActualDate from "my-time/hooks/use-actual-date";
import OcCard from "oc/components/oc-card/oc-card";
import useOpenRequests from "my-time/hooks/use-open-requests";
import { useTranslation } from "react-i18next";
import OcDataTable from "oc/components/oc-data-table/oc-data-table";
import { employeeMyTimeClosingPeriodRequest, employeeWithoutMyTimeExaminerRequest } from "my-time/configs/tableDefs";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import moment from "moment";
import useRecordingPeriod from "my-time/hooks/use-recording-period";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import { useMutation } from "@apollo/client";
import { CLOSE_RECORDING_PERIOD } from "my-time/graphql/mutations";
import { useSetRecoilState } from "recoil";
import { myTimeMessageState } from "my-time/states/my-time-message";
import useOcSnackbar from "hooks/use-oc-snackbar";
import useMyTimeRequestsWithoutExaminer from "my-time/hooks/use-open-requests-without-examiner";
import { useTheme } from "@material-ui/styles";

const MyTimeClosingCurrentPeriodPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false);

  const { date, formattedDate, handleDateChange } = useActualDate();

  const { data, loading, error, refetch } = useOpenRequests({
    startDate: moment(date).startOf("month").toDate(),
    endDate: moment(date).endOf("month").toDate(),
  });

  const { data: withoutExaminerData, loading: withoutExaminerLoading, error: withoutExaminerError, refetch: withoutExaminerRefetch } = useMyTimeRequestsWithoutExaminer({
    startDate: moment(date).startOf("month").toDate(),
    endDate: moment(date).endOf("month").toDate(),
  });

  const openRequestsTableId = "employeeMyTimeClosingPeriodRequestData";
  const withoutExaminerTableId = "employeeWithoutMyTimeExaminerRequestData";

  initialTableData.sortField = "title";
  initialTableData.pageSize = 5;

  const [openRequestsTableData, setOpenRequestsTableData] = useClientState(openRequestsTableId, initialTableData);
  const [withoutExaminerTableData, setWithoutExaminerTableData] = useClientState(withoutExaminerTableId, initialTableData);

  const { setOpenMessage } = useOcSnackbar();
  const clearMessageState = useSetRecoilState(myTimeMessageState);

  const [closeRecordingPeriod] = useMutation(CLOSE_RECORDING_PERIOD, {
    onCompleted: (data) => {
      const res = data?.closeRecordingPeriod;
      if (res.result) {
        setOpenMessage({
          type: "success",
          message: t("CLOSE_RECORDING_PERIOD_SUCCEEDED"),
        });
        clearMessageState("");
        refetch();
      } else {
        setOpenMessage({
          type: "error",
          message: res.message || t("CLOSE_RECORDING_PERIOD_FAILED"),
        });
        clearMessageState("");
      }
    },
    onError: () => {
      setOpenMessage({
        type: "error",
        message: t("CLOSE_RECORDING_PERIOD_FAILED"),
      });
    },
  });

  const handleCloseMonth = () => {
    setShowConfirmationDialog(true);
  };

  function handleOpenRequestsTableDataChange(state) {
    setOpenRequestsTableData(state);
    if (
      state.searchField || (state.searchText && state.searchText.length >= 3)
    ) {
      refetch();
    }
  }

  function handleWithoutExaminerTableDataChange(state) {
    setWithoutExaminerTableData(state);
    if (
      state.searchField || (state.searchText && state.searchText.length >= 3)
    ) {
      withoutExaminerRefetch();
    }
  }

  const filteredWithoutExaminerData = withoutExaminerData?.filter((item) => {
    if (!withoutExaminerTableData.searchText || withoutExaminerTableData.searchText.length < 3) return true;
    const searchValue = withoutExaminerTableData.searchText.toLowerCase();
    return Object.keys(item).some((key) =>
      item[key]?.toString().toLowerCase().includes(searchValue)
    );
  });

  const filteredOpenRequestsData = data?.filter((item) => {
    if (!openRequestsTableData.searchText || openRequestsTableData.searchText.length < 3) return true;
    const searchValue = openRequestsTableData.searchText.toLowerCase();
    return Object.keys(item).some((key) =>
      item[key]?.toString().toLowerCase().includes(searchValue)
    );
  });

  const withoutExaminerList = {
    rows: filteredWithoutExaminerData,
    total: filteredWithoutExaminerData?.length,
  };

  const openRequestsList = {
    rows: filteredOpenRequestsData,
    total: filteredOpenRequestsData?.length,
  };

  const { data: recordingPeriod } = useRecordingPeriod();

  let disabled = false;

  const currentMonth = moment().format("YYYY. MMMM");

  const currentDate = new Date().toLocaleDateString('hu-HU', { year: 'numeric', month: 'long' });

  if (
    data?.length > 0 ||
    withoutExaminerData?.length > 0 ||
    recordingPeriod === moment(currentDate).format("YYYY. MMMM") ||
    moment(recordingPeriod, "YYYY. MMMM").isAfter(moment(currentMonth, "YYYY. MMMM"))
  ) {
    disabled = true;
  }

  if (loading || error || withoutExaminerError) {
    disabled = true;
  }

  const handleCloseConfirmationDialog = () => {
    setShowConfirmationDialog(false);
    closeRecordingPeriod();
    refetch();
  };

  const handleCancelConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  return (
    <OcPageTransition>
      <Box padding="16px">
        <OcConfirmationDialog
          showDialog={showConfirmationDialog}
          onClose={handleCloseConfirmationDialog}
          onCancel={handleCancelConfirmationDialog}
          title={t("CONFIRM_CLOSE_MONTH_TITLE")}
          text={t("CONFIRM_CLOSE_MONTH_TEXT")}
          okButtonText={t("CLOSE_MONTH")}
          cancelButtonText={t("CANCEL")}
        />

        <MyTimePageHeader
          formattedDate={formattedDate}
          date={date}
          onDateChange={handleDateChange}
          closingperiodPage={true}
        />
        <Box height="16px" />
        <Box>
          <OcCard label={t("ACTIVE_REQUESTS")} loading={loading} error={error}>
            {(data?.length === 0 && !loading) && (withoutExaminerData?.length === 0 && !withoutExaminerLoading) && (
              <Box
                style={{
                  textAlign: "center",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <img
                    alt="orgware-icon"
                    src="/img/OK.svg"
                    style={{ width: 500 }}
                  />
                </Box>
                <Box height="36px" />
                <Typography variant="body1">
                  {t("REQUEST_NOT_FOUND")}
                </Typography>
              </Box>
            )}
            {data?.length > 0 && (
              <Box>
                <OcDataTable
                  tableDef={employeeMyTimeClosingPeriodRequest}
                  tableId={openRequestsTableId}
                  tableData={openRequestsTableData}
                  setTableData={handleOpenRequestsTableDataChange}
                  data={openRequestsList}
                  loading={loading}
                  pagination={true}
                  localPaging={true}
                />
              </Box>
            )}
            {withoutExaminerData?.length > 0 &&
              <Box>
                <Box height="16px" />
                <Typography
                  variant="h6"
                  style={{
                    paddingLeft: "16px",
                    color: theme.palette.gray80.main,
                  }}
                >{t("UNEXAMINED_REQUESTS")}
                </Typography>
                <Box height="16px" />
                <Box>
                  <OcDataTable
                    tableDef={employeeWithoutMyTimeExaminerRequest}
                    tableId={withoutExaminerTableId}
                    tableData={withoutExaminerTableData}
                    setTableData={handleWithoutExaminerTableDataChange}
                    data={withoutExaminerList}
                    loading={withoutExaminerLoading}
                    pagination={true}
                    localPaging={true}
                  />
                </Box>
              </Box>
            }
            <Box height="36px" />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                id="closing-period-button"
                variant="contained"
                color="primary"
                disabled={disabled}
                onClick={handleCloseMonth}
              >
                {t("CLOSING_PERIOD")}
              </Button>
            </Box>
            <Box height="20px" />
          </OcCard>
        </Box>
      </Box>
    </OcPageTransition>
  );
};

export default MyTimeClosingCurrentPeriodPage;
